<template>
  <div v-if="selected" class="fs-details">
    <div class="details-info">
      <div class="type">{{ typeTitle(selected.type) }} <span class="ext">{{ selected.ext }}</span></div>
      <div class="details-title">{{ selected.fullTitle || selected.title }}</div>
      <div class="item">
        <div class="title">Дата создания: </div>
        <div class="content">{{ $app.date.format(selected.createdAt) }}</div>
      </div>
      <div class="item">
        <div class="title">Дата изменения: </div>
        <div class="content">{{ $app.date.format(selected.updatedAt) }}</div>
      </div>
    </div>
    <div class="details-content">
      <n-tabs :data="tabs" :active.sync="active" name="detail" />
      <div class="content">
        <n-loader :loading="loading" />
        <template v-if="active === 'attributes'">
          <modal-detail-attributes :selected="selected" :type="type" :loading.sync="loading" />
        </template>
        <template v-if="active === 'versions'">
          <modal-detail-versions :selected="selected" :loading.sync="loading" />
        </template>
        <template v-if="active === 'permits'">
          <modal-detail-accesses :selected="selected" :type="type" :loading.sync="loading" />
        </template>
        <template v-if="active === 'history'">
          <modal-detail-history :selected="selected" :loading.sync="loading" />
        </template>
        <template v-if="active === 'tasks'">
          Отображение заданий в разработке
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDetailAccesses from './Accesses.vue'
import ModalDetailVersions from './Versions.vue'
import ModalDetailAttributes from './Attributes.vue'
import ModalDetailHistory from './History.vue'

export default {
  name: 'FsDetails',
  components: { ModalDetailAccesses, ModalDetailVersions, ModalDetailAttributes, ModalDetailHistory, },
  props: {
    selected: { type: Object, default: null, },
  },
  data() {
    return {
      active: 'attributes',
      loading: false,
    }
  },
  computed: {
    type() {
      return this.selected.model
    },
    tabs() {
      const tabs = [
        { name: 'attributes', title: 'Свойства', },
      ]
      tabs.push({ name: 'permits', title: 'Доступы', })
      if (this.type === 'document') {
        tabs.push({ name: 'versions', title: 'Версии', })
        tabs.push({ name: 'history', title: 'История', })
      }
      // tabs.push({ name: 'tasks', title: 'Задания', })

      return tabs
    },
  },
  watch: {
    selected() {
      this.active = 'attributes'
    },
  },
  created() {
  },
  methods: {
    typeTitle() {
      if (!this.selected.type) {
        return 'Документ'
      }
      const typeTitles = {
        project: 'Проект',
        folder: 'Папка',
        document: 'Документ',
        category: 'Категория',
      }
      return typeTitles[this.selected.type] || typeTitles['category']
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-details {
  border: 1px solid var(--fs-border-color);
  display: flex;

  .details-info {
    width: 400px;
    padding: 20px;
    border-right: 1px solid var(--fs-border-color);
    .type {
      font-size: .9em;
      color: var(--primary-t-2);
      .ext {
        text-transform: uppercase;
      }
    }
    .details-title {
      font-size: 1.2em;
      margin-bottom: 15px;
    }
    .item {
      margin-bottom: 10px;
      .title {
        font-size: .8em;
        opacity: .8;
        display: inline-block;
      }
      .content {

      }
    }
  }

  .details-content {
    flex-grow: 1;

    &::v-deep .n-tabs {
      border-bottom: 1px solid var(--fs-border-color);
      background: #fff;
      .n-tab {
        padding: 8px 15px;
        font-size: .9em;
      }
    }

    .content {
      padding: 20px;
      position: relative;
    }
  }

}
</style>
