function getLocalStorage(item) {
  const result = localStorage.getItem('files-' + item)
  if (result) return JSON.parse(result)
}

export default {
  namespaced: true,
  state: {
    activeView: 1,
    activeTab1: localStorage.getItem('files-active-tab-1') || 0,
    activeTab2: localStorage.getItem('files-active-tab-2') || 0,
    tabs1: getLocalStorage('tabs-1') || [],
    tabs2: getLocalStorage('tabs-2') || [],
    tree: {},
    treeLoading: true,
  },

  mutations: {
    SET_ACTIVE_VIEW(state, payload) {
      state.activeView = payload
    },
    SET_ACTIVE_TAB_1(state, payload) {
      localStorage.setItem('files-active-tab-1', payload)
      state.activeTab1 = payload
    },
    SET_ACTIVE_TAB_2(state, payload) {
      localStorage.setItem('files-active-tab-2', payload)
      state.activeTab2 = payload
    },
    SET_TABS_1(state, payload) {
      localStorage.setItem('files-tabs-1', JSON.stringify(payload))
      state.tabs1 = payload
    },
    SET_TABS_2(state, payload) {
      localStorage.setItem('files-tabs-2', JSON.stringify(payload))
      state.tabs2 = payload
    },
    SET_TREE(state, payload) {
      state.tree = payload
    },
    SET_TREE_LOADING(state, payload) {
      state.treeLoading = payload
    },
  },

  getters: {
    getViewsCount: (state) => {
      let result = 0
      if (state.tabs1.length > 0) result++
      if (state.tabs2.length > 0) result++
      return result
    },
    getBreadCrumbs: (state) => (id) => {
      const { tree, } = state
      if (!tree) return []

      const result = []
      result.push(tree[id])
      while (result[0].folderId) {
        result.unshift(tree[result[0].folderId])
      }

      return result
    },
  },

  actions: {
    async loadTree({ commit, }) {
      commit('SET_TREE', await this._vm.$apiOld.getPlug('initial-tree'))
      // commit('SET_TREE', await $api.fs.folders.get().sort('createdAt,desc').then((response)=> {
      //   console.log(response.data.data)
      // }))
    },
  },
}
