<template>
  <div class="tab3">
    <n-loader :loading="$var('load')" />

    <n-items>
      <n-input title="Название задачи" v-bind="$form.input('title')" />
      <n-textarea title="Описание" v-bind="$form.input('content')" />
      <div class="form-columns" style="width: 500px">
        <n-datepicker title="Дата начала" v-bind="$form.input('startedAt')" />
        <n-datepicker title="Дата окончания" v-bind="$form.input('endedAt')" />
      </div>
      <n-upload title="Сопроводительные документы" multi v-bind="$form.input('newFiles')" />
    </n-items>

    <div class="footer-buttons">
      <n-button @click="$emit('prev')">Назад</n-button>
      <div>
        <n-button color="primary" flat @click="save('next')">Процесс задачи</n-button>
        <n-button color="primary" @click="save('submit')">Создать задачу</n-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab3',
  props: {
    template: { type: Object, required: true, },
    task: { type: Object, default: null, },
  },
  data() {
    return {
    }
  },
  watch: {
    task(value) {
      this.$form.init({
        newFiles: [],
        ...value,
      })
    },
  },
  created() {
    this.$form.init(this.task)
  },
  beforeDestroy() {
    this.$emit('update:task', this.$form.get())
  },
  methods: {
    save(type = 'submit') {
      if (this.$form.check()) {
        this.$emit('update:task', this.$form.get())
        this.$emit(type)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tab3 {
  position: relative;
  padding-top: 10px;

}
</style>
