<template>
  <div class="layout-auth">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutAuth',
  created() {
    $app.auth.needAuth(false)
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.layout-auth {
  .container {
    margin: 0 auto;
    padding: 50px 0;
    max-width: 500px;
  }
}
</style>
