<template>
  <div class="projects-templates">
    <n-loader :loading="$var('load')" />
    <h2>Шаблоны</h2>
    <n-table :data="templates" :columns="columns">
      <template #createdAt="{item}">
        {{ $app.date.format(item.createdAt) }}
      </template>
      <template #tools="{item}">
        <n-link :to="{ name: 'projects.detail', params: { id: item.id, }, }"><n-button icon="pen" /></n-link>
      </template>
    </n-table>
    <div v-if="!templates.length" class="empty-message">
      Шаблонов нет
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsTemplates',
  data() {
    return {
      templates: [],
      columns: [
        { name: 'title', title: 'Название', },
        { name: 'createdAt', title: 'Дата создания', },
        { name: 'tools', title: '', },
      ],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.fs.projects.get().filter({ isTemplate: true, }).then((response) => {
        this.templates = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.projects-templates {
  position: relative;
  h2 {
    margin-top: 0;
    padding-top: 20px;
  }
}
</style>
