<template>
  <div class="n-chart-pie">
    <canvas :id="name"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import size from 'lodash/size'
import reduce from 'lodash/reduce'

export default {
  name: 'NChartPie',
  props: {
    data: { type: Array, required: true, },
    column: { type: Object, required: true, }, // { name: '', title: '', }

    hole: { type: Boolean, default: false, },
    polar: { type: Boolean, default: false, },
    name: { type: String, default: () => 'n-chart-'+Math.random(), },
    titleField: { type: String, default: 'title', },
    colors: { type: Array, default: () => [
      'rgba(255, 99, 132, 0.7)',
      'rgba(54, 162, 235, 0.7)',
      'rgba(255, 206, 86, 0.7)',
      'rgba(75, 192, 192, 0.7)',
      'rgba(153, 102, 255, 0.7)',
      'rgba(255, 159, 64, 0.7)',
    ], },
    colorsBg: { type: Array, default: () => [
      'rgba(255, 99, 132, 0.3)',
      'rgba(54, 162, 235, 0.3)',
      'rgba(255, 206, 86, 0.3)',
      'rgba(75, 192, 192, 0.3)',
      'rgba(153, 102, 255, 0.3)',
      'rgba(255, 159, 64, 0.3)',
    ], },
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    titles() {
      return reduce(this.data, (result, item) => {
        result.push(item[this.titleField])
        return result
      }, [])
    },
    values() {
      return reduce(this.data, (result, item) => {
        result.push(item[this.column.name])
        return result
      }, [])
    },
  },
  watch: {
    data() {
      this.initChart()
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      if (size(this.data)) {
        if (this.chart) {
          this.chart.destroy()
        }
        this.chart = new Chart(document.getElementById(this.name), {
          type: this.hole ? 'doughnut' : (this.polar ? 'polarArea' : 'pie'),
          data: {
            labels: this.titles,
            datasets: [ { data: this.values, backgroundColor: this.polar ? this.colorsBg : this.colors, }, ],
          },
          options: {
            plugins: {
              subtitle: {
                display: Boolean(this.column.title),
                text: this.column.title,
              },
            },
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.n-chart-pie {
  max-width: 600px;
}
</style>
