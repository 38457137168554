<template>
  <div class="modal-detail-attributes">
    <file-accesses v-if="s_type === 'document'" :model="model" :editable="$app.auth.can('folder_users', s_id)" @reload="load" />
    <c-accesses v-else :model="model" :type="s_type" :editable="$app.auth.can('folder_users', s_id)" @reload="load" />
  </div>
</template>

<script>
import FileAccesses from './FileAccesses'

export default {
  name: 'ModalDetailAccesses',
  components: { FileAccesses, },
  props: {
    selected: { type: Object, required: true, },
    type: { type: String, required: true, }, // document or folder
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      model: {},
    }
  },
  computed: {
    s_type() {
      return this.type === 'project' ? 'folder' : this.type
    },
    s_id() {
      return this.type === 'folder' ? this.model.id : this.model.folderId
    },
  },
  watch: {
    selected() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$emit('update:loading', true)
      const api = $api.fs[this.type+'s'].get(this.selected.id)
      if (this.type === 'folder') {
        api.with('isCancelAccess')
      }
      api.then((response) => {
        this.model = response.data.content
      }).finally(() => {
        this.$emit('update:loading', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-detail-attributes {
  --n-modal-width: 500px;
  .n-modal {
    font-size: .9em;
  }

  .inherit {
    font-size: .9em;
    opacity: .9;
  }
  .top-button {
    margin-bottom: 10px;
  }
}
</style>
