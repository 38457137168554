<template>
  <header>
    <c-breadcrumbs />
    <div class="tools">
      <div style="padding: 0 30px">
        <n-force-auth v-if="$config('auth.method') === 'jwt'" />
      </div>
      <n-link class="profile" :to="{ name: 'profile', }">
        <n-icon icon="user"></n-icon>
        <span>{{ $app.auth.user().fullName }}</span>
      </n-link>
    </div>
  </header>
</template>

<script>
import CBreadcrumbs from './Breadcrumbs'

export default {
  name: 'MainHeader',
  components: { CBreadcrumbs, },
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: #333;
  color: #eee;

  .tools {
    display: flex;
    align-items: center;
  }
  .profile {
    color: #eee;
    .n-icon {
      margin-right: 7px;
    }
  }

  .n-force-auth {
    font-size: .7em;
    margin: -10px 0;
  }
}
</style>
