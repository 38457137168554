<template>
  <n-card class="document-code" :loading="!project || $var('load')">
    <h3>Коды документов</h3>

    <div class="code-names">
      <span v-for="(name, i) in $form.get('codeNames')" :key="i" class="item">
        <span class="name" @click="remove(i)">[{{ name }}]</span>
        <span v-if="$n.size($form.get('codeNames')) >= i+2" class="separator">{{ $form.get('codeSeparator') }}</span>
      </span>
    </div>

    <n-items>
      <div class="attribute-form">
        <n-select title="Добавить атрибут" :data="attributes" v-bind="$form.input('attribute', 'select', 'attribute')"
                  item-value="name" @update:value="add" />
      </div>
      <n-input class="separator-input" title="Разделитель" v-bind="$form.input('codeSeparator')" />
    </n-items>
    
    <n-divide v-if="$n.size($form.diff())">
      <div><n-button @click="save">Сохранить</n-button></div>
      <div><n-button @click="() => $form.reset()">Отмена</n-button></div>
    </n-divide>
  </n-card>
</template>

<script>
export default {
  name: 'DocumentCode',
  props: {
    project: { type: Object, default: () => ({}), },
  },
  data() {
    return {
      attributes: [],
    }
  },
  watch: {
    project() {
      this.$form.init({
        codeNames: this.project.codeNames,
        codeSeparator: this.project.codeSeparator,
      })
    },
  },
  created() {
    this.$form.init({
      attribute: undefined,
    }, true, 'attribute')
    this.$form.init({
      codeNames: this.project.codeNames,
      codeSeparator: this.project.codeSeparator,
    })
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      const apis = [
        $api.fs.attributes.get(),
      ]
      Promise.all(apis).then((responses) => {
        this.attributes = responses[0].data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    add(value) {
      this.$form.set('codeNames', [
        ...this.$form.get('codeNames'),
        value.name,
      ])
      this.$form.set('attribute', value, 'attribute')
      this.$nextTick(() => {
        this.$form.set('attribute', undefined, 'attribute')
      })
    },
    remove(index) {
      const codeNames = [ ...this.$form.get('codeNames'), ]
      codeNames.splice(index, 1)
      this.$form.set('codeNames', codeNames)
    },
    save() {
      this.$var('load', true)
      $api.fs.projects.edit(this.project.id, this.$form.get()).then((response) => {
        const project = response.data.content
        this.$form.init({
          codeNames: project.codeNames,
          codeSeparator: project.codeSeparator,
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.document-code {
  .attribute-form {
    display: flex;
    align-items: flex-end;
    .n-select {

    }
    .n-button {
      margin-left: 10px;
    }
  }
  .separator-input {
    margin-bottom: 10px;
  }
  .code-names {
    padding: 20px 0 50px;
    .item {
      .name, .separator {
        display: inline-block;
        padding: 5px;
        margin: 0 3px;
        background: #fafafa;
        border: 1px solid #f0f0f0;
      }
      .name {
        cursor: pointer;
      }
    }
  }
}
</style>
