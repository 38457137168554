import BaseApi from 'nast-api'
import BaseRequestBuilder from './RequestBuilder'

/**
 *
 */
export default class NastApi extends BaseApi {
  /**
   * @param {Object} params
   * @param {Boolean} withQM
   * @return {string}
   */
  serialize(params, withQM = true) {
    return RequestBuilder.serialize(params, withQM)
  }

  /**
   * @param {Object} string
   * @return {object}
   */
  deserialize(string) {
    return RequestBuilder.deserialize(string)
  }
}

/**
 *
 */
export class RequestBuilder extends BaseRequestBuilder {

}
