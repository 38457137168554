import Vue from 'vue'

const models = {
  task: (model) => ({ name: 'tasks.list', params: { projectId: model.projectId, }, query: { task: model.id, }, }),
}

export default {
  namespaced: true,
  state: {
    unreadCount: 0,
    interval: null,
    _save: [ 'unreadCount', ],
  },
  getters: {
    messages(state) {
      return state.messages
    },
  },
  mutations: {
    unreadCount(state, unreadCount) {
      state.unreadCount = unreadCount
    },
    interval(state, interval) {
      state.interval = interval
    },
  },
  actions: {
    start({ commit, dispatch, }) {
      commit('interval', setInterval(() => {
        // dispatch('load')
      }, 10*1000))
      dispatch('load')
    },
    stop({ state, commit, }) {
      if (state.interval) {
        clearInterval(state.interval)
        commit('interval', null)
      }
    },
    load({ state, commit, dispatch, }) {
      if (state.interval) {
        $api.fs.notifies.info().with('model').then((response) => {
          const unreadCount = response.headers['unread-count']
          const messages = response.data.content
          commit('unreadCount', unreadCount)
          $n.each(messages, (m) => {
            Vue.$toast.open({
              type: m.type === 'critical' ? 'info' : 'success',
              message: m.message,
              position: 'bottom-left',
              duration: 5 * 1000,
              onClick: () => {
                $app.router.push(models[m.modelType](m.model))
              },
            })
          })
        }).catch((error) => {
          if (error.response?.status === 401) {
            if ($config('auth.method') === 'jwt') {
              dispatch('stop')
            }
          }
        })
      }
    },
  },
}
