<template>
  <div class="fs-breadcrumbs">
    <div class="structure">
      <div v-for="(item, key) in breadcrumbs" :key="item.id" class="item" :class="[ {opened: item.id === opened.id }, ]">
        <span class="title" @click="click(item)">{{ item.title }}</span>
        <span v-if="!last(key)" class="separator">
          <slot name="separator"> / </slot>
        </span>
      </div>
    </div>

    <div class="tools">
      <n-button flat @click="$emit('update:favorite', !favorite)">
        <n-icon v-if="favorite" icon="star" />
        <n-icon v-else type="fa-regular" icon="star" />
      </n-button>
      <!--          <n-button class="search-button" icon="search" @click="searchModal = ! searchModal " />-->
      <n-button :icon="groupsIcon" flat @click="toggleWindow" />
      <n-button :icon="mode" flat @click="toggleMode" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FsBreadcrumbs',
  props: {
    folders: { type: Object, required: true, },
    opened: { type: Object, default: null, },
    favorite: { type: Boolean, default: false, },
  },
  computed: {
    breadcrumbs() {
      if (!this.opened || !$n.size(this.folders)) {
        return []
      }

      const breadcrumbs = $n.map(this.opened.parentIds || [], (parentId) => {
        return this.folders[parentId]
      })
      breadcrumbs.push(this.opened)

      return breadcrumbs
    },
    groupsIcon() {
      return $n.size(this.windows) > 1 ? 'window-close' : 'window-restore'
    },
    mode: {
      get() {
        return $app.store.getter('filesystem.mode')
      },
      set(value) {
        $app.store.mutation('filesystem.mode', value)
      },
    },
    windows() {
      return $app.store.getter('filesystem.windows')
    },
  },
  methods: {
    click(item) {
      if (item.id !== this.opened.id) {
        this.$emit('update:opened', item)
      }
    },
    last(key) {
      return key + 1 === this.breadcrumbs.length
    },
    toggleMode() {
      this.mode = this.mode === 'table' ? 'list' : 'table'
    },
    toggleWindow() {
      $n.size(this.windows) > 1 ? this.$emit('removeWindow') : this.$emit('createWindow')
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-breadcrumbs {
  padding: 4px 15px 4px;
  background: #fff;
  border: 1px solid var(--fs-border-color);
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .8em;

  .structure {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .tools {
    margin-right: -10px;
    margin-left: 10px;
  }

  .item {
    display: flex;
    align-items: center;

    .n-link {
      color: var(--link-color);
      &:hover { text-decoration: none; }
    }
    .n-icon {
      margin-right: 4px;
    }
    .separator {
      padding: 0 1em;
    }

    &.opened {
      color: inherit;
      .n-link {
        color: inherit;
      }
    }
    &:not(.opened) {
      .title {
        color: var(--link-color);
        cursor: pointer;
      }
    }
  }
}
</style>
