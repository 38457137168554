
export default {
  namespaced: true,
  state: {
    windows: [
      {
        tabs: [],
        active: 0,
      },
    ],
    favorites: [],
    drag: {
      item: null,
      table: null,
    },
    mode: 'table', // or list
    _save: [ 'windows', 'favorites', 'mode', ],
  },
  getters: {
    windows(state) {
      return state.windows
    },
    mode(state) {
      return state.mode
    },
    drag(state) {
      return state.drag
    },
  },
  mutations: {
    window(state, { index, window, }) {
      const windows = $n.clone(state.windows)
      windows[index] = window
      state.windows = windows
    },
    createWindow(state, folder) {
      const windows = $n.clone(state.windows)
      windows[1] = {
        tabs: [ folder, ],
        active: 0,
      }
      state.windows = windows
    },
    removeWindow(state, index) {
      const windows = $n.clone(state.windows)
      windows.splice(index, 1)
      state.windows = windows
    },
    favorites(state, favorites) {
      state.favorites = favorites
    },
    mode(state, mode) {
      state.mode = mode
    },
    drag(state, { item, group, }) {
      state.drag = { item, group, }
    },
  },
}
