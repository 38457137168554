<template>
  <n-modal class="modal-save-version" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-input title="Название версии" v-bind="$form.input('version')" />
        <n-button type="submit">Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalSaveVersion',
  props: {
    id: { type: [ Number, String, ], required: true, },
  },
  created() {
    this.$form.init({
      version: '',
    })
  },
  methods: {
    submit() {
      this.$var('load', true)
      $api.fs.documents.versions.edit(this.id, this.$form.get()).then(() => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-save-version {

}
</style>
