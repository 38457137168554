<template>
  <div class="page-projects">
    <n-loader :loading="$var('load')" />

    <div class="button-wrap">
      <n-button class="create-task-button" @click="$var('create', true)">Создать проект</n-button>
    </div>

    <n-table :data="projects" :columns="columns">
      <template #createdAt="{item}">
        {{ $app.date.format(item.createdAt) }}
      </template>
      <template #tools="{item}">
        <n-link :to="{ name: 'projects.detail', params: { id: item.id, }, }"><n-button icon="pen" /></n-link>
      </template>
    </n-table>

<!--    <div class="projects-list">-->
<!--      <ProjectItem v-for="item in projects" :key="item.id" :project="item" />-->
<!--    </div>-->


    <n-modal v-if="$var('create')" :loading="$var('loadingCreate')" @close="$var('create', false)">
      <n-form @submit="submitCreate">
        <n-items>
          <n-input title="Название" v-bind="$form.input('title')" />
          <n-select title="Шаблон" :data="templates" v-bind="$form.input('template', 'select')" item-value="id" />
          <n-button type="submit">Создать</n-button>
        </n-items>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
import ProjectItem from './ProjectItem'

export default {
  components: {
    ProjectItem,
  },
  data: () => ({
    projects: [],
    templates: [],
    columns: [
      { name: 'title', title: 'Название', },
      { name: 'createdAt', title: 'Дата создания', },
      { name: 'tools', title: '', },
    ],
  }),
  created() {
    this.$form.init({
      title: '',
      template: null,
    })
    this.load()
  },
  methods: {
    load() {
      const apis = [
        $api.fs.projects.get().filter({ isTemplate: false, revisionProjectId: 'null', }),
        $api.fs.projects.get().filter({ isTemplate: true, }),
      ]
      this.$var('load', true)
      Promise.all(apis).then((responses)=>{
        this.projects = responses[0].data.content
        this.templates = responses[1].data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submitCreate() {
      this.$var('loadingCreate', true)
      const data = {
        title: this.$form.get('title'),
        templateId: this.$form.get('template')?.id,
      }
      $api.fs.projects.create(data).then((response) => {
        this.$var('create', false)
        this.$router.push({ name: 'files.index', params: { category: 'projects', }, })
      }).finally(() => {
        this.$var('loadingCreate', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-projects {
  position: relative;

  .button-wrap {
    display: flex;
    margin: 20px 0;
  }

  .projects-list {
    display: flex;
    flex-wrap: wrap;
    margin: calc(-35px / 2);
    padding: 38px 0 80px 0;
  }

}
</style>
