export default {
  word: {
    icon: "file-word",
    title: "Документ Word",
  },

  acrobat: {
    icon: "file-pdf",
    title: "Adobe Acrobat",
  },
  file: {
    icon: "folder",
    title: "Папка",
  },
  autocad: {
    icon: "drafting-compass",
    title: "AutoCad",
  },
  image: {
    icon: "image",
    title: "Изображение",
  },
};
