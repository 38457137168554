<template>
  <n-modal class="modal-edit-parent" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Родительская задача" :data="data" v-bind="$form.input('task', 'select')" />
        <n-button type="submit" color="success" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalEditParent',
  props: {
    projectId: { type: Number, required: true, },
    task: { type: Object, default: null, },
  },
  data() {
    return {
      data: [],
      noTask: { id: 0, title: 'Нет родителя', },
    }
  },
  created() {
    this.$form.init({
      task: this.task || this.noTask,
    })
    this.$form.rules({
      task: [ 'required', ],
    })

    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.fs.tasks.get().filter('projectId', this.projectId).view('all').sort('createdAt').then((response) => {
        this.data = [
          this.noTask,
          ...response.data.content,
        ]
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        const task = this.$form.get('task')
        this.$emit('submit', task.id ? task : null)
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-edit-parent {
  --n-modal-width: 600px;
}
</style>
