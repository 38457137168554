<template>
  <div class="page-files-details">
    <n-card :loading="loading" @close="close">
      <n-tabs :data="tabs" name="detail" state />
      <div class="content">
        <n-tabs-content name="detail">
          <template #attributes>
            <modal-detail-attributes :id="itemId" :type="type" :loading.sync="loading" />
          </template>
          <template #versions>
            <modal-detail-versions :id="itemId" :loading.sync="loading" />
          </template>
          <template #permits>
            <modal-detail-accesses :id="itemId" :type="type" :loading.sync="loading" />
          </template>
          <template #history>
            <modal-detail-history :id="itemId" :loading.sync="loading" />
          </template>
          <template #tasks>
            Отображение заданий в разработке
          </template>
        </n-tabs-content>
      </div>
    </n-card>
  </div>
</template>

<script>
import ModalDetailAttributes from './detail/Attributes'
import ModalDetailVersions from './detail/Versions'
import ModalDetailHistory from './detail/History'
import ModalDetailAccesses from './detail/Accesses'

export default {
  name: 'PageFilesDetails',
  components: { ModalDetailAccesses, ModalDetailVersions, ModalDetailAttributes, ModalDetailHistory, },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    folderId() {
      return this.$route.params.id
    },
    itemId() {
      return this.$route.params.itemId
    },
    type() {
      return this.$route.params.type
    },
    tabs() {
      const tabs = [
        { name: 'attributes', title: 'Свойства', },
      ]
      if (this.type === 'document') {
        tabs.push({ name: 'versions', title: 'Версии', })
      }
      tabs.push({ name: 'permits', title: 'Доступы', })
      tabs.push({ name: 'history', title: 'История', })
      // tabs.push({ name: 'tasks', title: 'Задания', })

      return tabs
    },
  },
  created() {
  },
  methods: {
    close() {
      this.$router.push({ name: 'files.index', params: { id: this.folderId, category: this.$route.params.category, }, })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-files-details {
  --n-modal-width: 800px;
  margin-top: 40px;

  .n-card {
    margin: 0;
    box-shadow: none;
    border: 1px solid #f0f0f0;
    .content {
      min-height: 365px;
    }
  }

  .content {
    margin: 20px 0;
  }

  &::v-deep .n-tabs {
    justify-items: flex-start;
    .n-tab {

      justify-content: center;
    }
  }
}
</style>
