<template>
  <div class="task-actions">
    <n-button v-for="status in pushStatuses" :key="status.id" @click="push(status.id)">{{ status.title }}</n-button>
    <n-button v-if="can('appoint')" @click="$var('appoint', true)">Назначить исполнителя</n-button>
    <n-button v-if="can('approve')" @click="$var('approve', true)">Согласовать</n-button>
    <n-button v-if="can('approve')" @click="$var('refuse', true)">Отказать</n-button>

    <modal-approve v-if="$var('approve')" :task="task" @reload="$emit('reload')" @close="$var('approve', false)" />
    <modal-refuse v-if="$var('refuse')" :task="task" @reload="$emit('reload')" @close="$var('refuse', false)" />
    <modal-appoint v-if="$var('appoint')" :actors="task.actions.appoint" :task="task" @reload="$emit('reload')" @close="$var('appoint', false)" />
  </div>
</template>

<script>
import ModalAppoint from './ModalAppoint.vue'
import ModalApprove from './ModalApprove.vue'
import ModalRefuse from './ModalRefuse.vue'

export default {
  name: 'TaskActions',
  components: { ModalRefuse, ModalApprove, ModalAppoint, },
  props: {
    task: { type: Object, required: true, },
    statuses: { type: Object, required: true, },
    can: { type: Function, required: true, },
  },
  computed: {
    pushStatuses() {
      return $n.map(this.task.actions.push, (statusId) => {
        return this.statuses[statusId]
      })
    },
  },
  methods: {
    push(statusId) {
      this.$emit('loading', true)
      $api.fs.tasks.status(this.task.id, statusId).then((response) => {
        this.reload()
      }).catch(() => {
        this.$emit('loading', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.task-actions {
  font-size: .9em;
  margin-bottom: 10px;
  &>* {
    margin-right: 10px;
  }
}
</style>
