<template>
  <div class="report-form">
    <n-loader :loading="$var('load')" />
    <n-form v-if="ui" @submit="save">
      <n-items>
        <n-input title="Название отчета" v-bind="$form.input('title')" />
        <n-select title="Доступность" :data="sharedOptions" v-bind="$form.input('isShared', 'select')" />
        <n-select title="Данные" :data="$n.values(ui._general.models)" v-bind="$form.input('model', 'select')" :update:value="changeModel" />

        <template v-if="type === 'table'">
          <n-form-item title="Колонки">
            <c-fields :params.sync="params" :columns.sync="columns" :ui="ui" :model="$form.get('model.id')" />
          </n-form-item>
        </template>

        <template v-if="type === 'time' && $form.get('model.id')">
          <n-form-item title="Срезы по времени">
            <c-time :params.sync="params" :ui="ui" :model="$form.get('model.id')" />
          </n-form-item>
        </template>

        <template v-if="type === 'line' && $form.get('model.id')">
          <n-form-item title="Колонки">
            <c-fields :params.sync="params" :columns.sync="columns" :ui="ui" :model="$form.get('model.id')" columns-type="numbers" />
          </n-form-item>
        </template>

        <template v-if="type === 'pie' && $form.get('model.id')">
          <n-form-item title="Колонки">
            <c-fields :params.sync="params" :columns.sync="columns" :ui="ui" :model="$form.get('model.id')" columns-type="numbers" />
          </n-form-item>
        </template>

        <template v-if="type === 'bar' && $form.get('model.id')">
          <n-form-item title="Колонки">
            <c-fields :params.sync="params" :columns.sync="columns" :ui="ui" :model="$form.get('model.id')" columns-type="numbers" />
          </n-form-item>
        </template>

        <template v-if="type === 'radar' && $form.get('model.id')">
          <n-form-item title="Колонки">
            <c-fields :params.sync="params" :columns.sync="columns" :ui="ui" :model="$form.get('model.id')" columns-type="numbers" />
          </n-form-item>
        </template>

        <n-form-item title="Фильтры">
          <c-filters :params.sync="params" :ui="ui" :model="$form.get('model.id')" />
        </n-form-item>

        <n-form-item title="Сортировка">
          <c-sorts :params.sync="params" :columns="columns" :ui="ui" :model="$form.get('model.id')" />
        </n-form-item>

        <n-button type="submit" color="success">
          {{ report ? 'Сохранить' : 'Создать отчет' }}
        </n-button>
      </n-items>
    </n-form>
  </div>
</template>

<script>
import { RequestBuilder, } from '_libs/api'
import CFields from './_components/fields/Index'
import CFilters from './_components/filters/Index'
import CSorts from './_components/sorts/Index'
import CTime from './_components/time/Index'

export default {
  name: 'CReportForm',
  components: { CTime, CSorts, CFilters, CFields, },
  props: {
    type: { type: String, required: true, },
    report: { type: Object, default: () => ({}), },
  },
  data() {
    return {
      params: {},
      ui: null,
      columns: this.report.columns || [],
      sharedOptions: [
        { id: false, title: 'Доступен только мне', },
        { id: true, title: 'Доступен всем', },
      ],
      startedAt: null,
      endedAt: null,
    }
  },
  created() {
    this.$form.init({
      title: this.report.title || '',
      model: null,
      isShared: $n.find(this.sharedOptions, [ 'id', this.report.isShared || false, ]),
      slice: [],
    })

    this.load()
    this.initParams(this.report.query)
  },
  methods: {
    changeModel(value) {
      this.$form.set('model', value)
      this.columns = []
      this.initParams()
    },
    initParams(query = '') {
      const builder = new RequestBuilder()
      const view = [ 'line', 'pie', 'bar', 'radar', ].includes(this.type) ? 'table' : this.type
      builder.params(RequestBuilder.deserialize(query)).view(view)
      this.params = builder.params()
    },
    load() {
      this.$var('load', true)
      $api.reports.titles().then((response) => {
        this.ui = response.data.content
        this.$form.set('model', $n.get(this.ui._general.models, this.report.model || 'projects'))
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      const data = {
        type: this.type,
        title: this.$form.get('title'),
        columns: this.columns.length ? this.columns : undefined,
        model: this.$form.get('model.id'),
        isShared: this.$form.get('isShared.id'),
        query: RequestBuilder.serialize(this.params, false),
      }
      this.$emit('submit', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-form {
  .dates {
    &::v-deep .n-datepicker {
      --n-input-width: 150px;
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
</style>
