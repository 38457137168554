<template>
  <n-modal class="modal-time-slice" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Группировка по" :data="Object.values(ui[model].fields)" v-bind="$form.input('slice', 'select')" />
        <n-button type="submit" color="success" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
import { getBuilder, } from '../../utils'

export default {
  name: 'ModalTimeSlice',
  props: {
    params: { type: Object, required: true, },
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    'update:params': { type: Function, default: (params) => {}, },
  },
  created() {
    const slice = this.params.slice ?
      this.params.slice.split(',').map((item) => this.ui[this.model].fields[item]) :
      []
    this.$form.init({
      slice,
    })
    this.$form.rules({
      slice: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        const builder = getBuilder(this.params)
        const slice = this.$form.get('slice').map((item) => item.id)
        builder.slice(slice)
        this.$emit('update:params', builder.params())
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-time-slice {
}
</style>
