<template>
  <div class="file-accesses">
    <n-loader :loading="$var('load')" />
    <n-form @submit="submit">
      <n-items>
        <n-select v-if="editable" title="Доступ" :data="accesses" v-bind="$form.input('access', 'select')" />
        <n-form-item v-else title="Доступ">{{ $form.get('access.title') }}<br /></n-form-item>
        <n-button v-if="editable" type="submit">Сохранить</n-button>
      </n-items>
    </n-form>
  </div>
</template>

<script>
export default {
  name: 'FileAccesses',
  props: {
    model: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      accesses: [
        { id: 'hidden', title: 'Недоступен для просмотра', },
        { id: 'read', title: 'Только чтение', },
        { id: 'edit', title: 'Редактирование', },
        { id: 'manage', title: 'Полный доступ', },
      ],
    }
  },
  computed: {
    access() {
      return $n.find(this.accesses, [ 'id', this.model.access, ])
    },
  },
  watch: {
    model() {
      this.$form.init({
        access: this.access,
      }, this.editable)
      this.$var('load', false)
    },
  },
  created() {
    this.$var('load', true)
  },
  methods: {
    submit() {
      this.$var('load', true)
      $api.fs.documents.edit(this.model.id, { access: this.$form.get('access.id'), }).then((response) => {
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.file-accesses {

}
</style>
