<template>
  <div class="tab4">
    <n-loader :loading="$var('load')" />
    
    <c-process :process.sync="s_process" :variants="variants" title="Процесс задачи" />
    <c-process v-if="s_subprocess" :process.sync="s_subprocess" :variants="variants" title="Процесс подзадач" />

    <div class="footer-buttons">
      <n-button @click="$emit('prev')">Назад</n-button>
      <n-button color="primary" @click="$emit('submit')">Создать задачу</n-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab4',
  props: {
    process: { type: Object, required: true, },
    subprocess: { type: Object, default: null, },
  },
  data() {
    return {
      variants: {
        actors: [],
        actions: [],
        events: [],
      },
    }
  },
  computed: {
    s_process: {
      get() {
        return this.process
      },
      set(v) {
        this.$emit('update:process', v)
      },
    },
    s_subprocess: {
      get() {
        return this.subprocess
      },
      set(v) {
        this.$emit('update:subprocess', v)
      },
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.process.variants('task').then((response) => {
        this.variants = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab4 {
  position: relative;
  padding-top: 10px;

}
</style>
