<template>
  <n-modal class="modal-add-department" :loading="$var('load')" @close="$emit('close')">
    <h3 v-if="model.id">Редактировать департамент</h3>
    <h3 v-else>Создать департамент</h3>
    <n-form @submit="submit">
      <n-items>
        <n-input title="Название" v-bind="$form.input('title')" />
        <n-select title="Группа начальника департамента" :data="groups" v-bind="$form.input('head', 'select')" />
        <n-select title="Группа сотрудников департамента" :data="groups" v-bind="$form.input('stuff', 'select')" />
        <n-button color="success" type="submit" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalAddDepartment',
  props: {
    model: { type: Object, default: () => ({}), },
  },
  data() {
    return {
      groups: [],
    }
  },
  mounted() {
    if (this.model.id) {
      this.$form.init(this.model)
    } else {
      this.$form.init({
        title: '',
        head: null,
        stuff: null,
      })
    }
    this.$form.rules({
      title: [ 'required', ],
      head: [ 'required', ],
      stuff: [ 'required', ],
    })
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.auth.groups.get().then((response) => {
        this.groups = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          title: this.$form.get('title'),
          headGroupId: this.$form.get('head.id'),
          stuffGroupId: this.$form.get('stuff.id'),
        }
        const api = this.model.id ?
          $api.company.departments.edit(this.model.id, data) :
          $api.company.departments.post(data)
        api.then((response) => {
          this.$emit('submit')
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-add-department {

}
</style>
