<template>
  <div class="modal-detail-attributes">
    <n-loader :loading="loading" />

    <table v-if="$n.size(model.attributes)" class="attributes">
      <tr v-for="item in model.attributes" :key="item.id" :class="[ {inherit: item.inherit},]">
        <td>
          <div class="title">{{ item.title }}</div>
          <span>:</span>
        </td>
        <td>
          <div class="value">{{ item.value }}</div>
          <span class="inherit">
            {{ item.isInherit ? '[ Унаследован ]' : item.isLocal ? '[ Локальный атрибут ]' : '' }}
          </span>
          <div class="tools">
            <div v-if="item.isInherit"></div>
            <div v-else>
              <n-button icon="pen" flat round @click="openAddAttribute(item)" />
              <n-button icon="trash" flat round @click="removeAttribute(item.name)" />
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div v-else class="message">Атрибутов нет</div>

    <div class="top-button">
      <n-button v-if="$app.auth.can('folder_write', type === 'folder' ? model.id : model.folderId)" @click="openAddAttribute">Добавить атрибут</n-button>
    </div>

    <n-modal v-if="$var('add')" :loading="$var('loadAttrs')" @close="closeAddAttribute">
      <n-form @submit="submitAttribute">
        <n-items>
          <n-select v-if="!$var('add').name" title="Атрибут" :data="attributes" v-bind="$form.input('name', 'select')" item-value="name" />
          <n-form-item v-else title="Атрибут">
            {{ $n.find(model.attributes, [ 'name', $var('add').name, ]).title }}
          </n-form-item>
          <n-select v-if="data[$form.get('name').name]" title="Значение" :data="data[$form.get('name').name]" v-bind="$form.input('value', 'select')" />
          <n-input v-else title="Значение" v-bind="$form.input('value')" />
          <n-button type="submit">Сохранить</n-button>
        </n-items>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalDetailAttributes',
  props: {
    selected: { type: Object, required: true, },
    type: { type: String, required: true, }, // document or folder
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      columns: [
        { name: 'title', title: 'Атрибут', },
        { name: 'value', title: 'Значение', },
        { name: 'inherit', title: 'Наследование', },
        { name: 'tools', title: '', },
      ],
      model: {},
      attributes: [],
      data: [],
    }
  },
  watch: {
    'selected.id'() {
      this.load()
    },
  },
  created() {
    this.$emit('update:loading', true)
    this.load()
    this.loadData()
  },
  methods: {
    load() {
      if (this.selected.id) {
        this.$emit('update:loading', true)
        $api.fs[this.type + 's'].get(this.selected.id).with('attributes').with('fields').then((response) => {
          this.model = response.data.content
          this.model.attributes = $n.filter(this.model.attributes, (item) => item.title)
        }).finally(() => {
          this.$emit('update:loading', false)
        })
      }
    },
    loadData() {
      $api.fs.attributes.getData().then((response) => {
        this.data = response.data.content
      })
    },
    loadAttributes() {
      this.$var('loadAttrs', true)
      let type = this.type
      if (this.type === 'folder' && !this.model.folderId) {
        type = 'project'
      }
      $api.fs.attributes.get().filter({ type: 'in:null,'+type, }).then((response) => {
        this.attributes = response.data.content
      }).finally(() => {
        this.$var('loadAttrs', false)
      })
    },
    openAddAttribute(attribute = {}) {
      this.$form.init({
        name: attribute.name ? attribute : '',
        value: attribute.value,
      })
      this.$var('add', attribute || true)
      this.loadAttributes()
    },
    closeAddAttribute() {
      this.$form.reset()
      this.$var('add', false)
    },
    submitAttribute() {
      const data = {
        attributes: [ {
          name: this.$form.get('name').name,
          value: this.$form.get('value'),
        }, ],
      }
      this.$var('loadAttrs', true)
      $api.fs[this.type + 's'].edit(this.selected.id, data).then((response) => {
        this.load()
        this.$var('add', false)
      }).finally(() => {
        this.$var('loadAttrs', false)
      })
    },
    removeAttribute(name) {
      this.$emit('update:loading', true)
      $api.fs[this.type + 's'].removeAttr(this.selected.id, name).then((response) => {
        this.load()
      }).catch(() => {
        this.$emit('update:loading', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-detail-attributes {
  font-size: .9em;
  position: relative;

  .top-button {
    font-size: .9em;
    margin: 10px 0;
  }

  .attributes {
    td {
      height: 34px;
    }
    .title {
      display: inline-block;
      opacity: .9;
    }
    .inherit {
      margin-left: 5px;
      font-size: .9em;
      opacity: .9;
    }
    .value {
      margin-left: 15px;
      display: inline-block;
    }
    .tools {
      margin-left: 10px;
      display: inline-block;
      .n-button {
        opacity: .2;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .message {
    font-style: italic;
    opacity: .6;
    margin: 10px 0 25px;
  }
}
</style>
