<template>
  <div class="actors">
    <div class="title-block row"><span class="title">Участники задачи</span></div>
    <template v-if="$n.size(process.actors)">
      <div v-for="actor in process.actors" :key="actor.id" class="actor-block row">
        <div class="actor" :class="[ { deletable: isDeletable(actor), }, ]" @click="remove(actor)">{{ actor.title }}:</div>

        <div v-for="user in actor.values" :key="user.id" class="action-block deletable" @click="removeUser(actor, user.id)">
          {{ user.title }},
        </div>

        <div v-if="actor.disabled" class="message">
          назначено
        </div>
        <div v-else class="add-button" @click="$var('addUser', actor)">
          <n-icon icon="plus" /> назначить
        </div>
      </div>
    </template>
    <div v-else class="message row">Участников нет</div>
    <div class="add-button row" @click="$var('add', true)">
      <n-icon icon="plus" /> участник
    </div>
    <process-modal-actor2 v-if="$var('add')" @submit="add" @close="$var('add', false)" />
    <process-modal-user v-if="$var('addUser')" :actor="$var('addUser')" :actors="actors"
                        @submit="addUser" @close="$var('addUser', false)" />
  </div>
</template>

<script>
import ProcessModalActor2 from './ModalActor2.vue'
import ProcessModalUser from './ModalUser.vue'

export default {
  name: 'ProcessActors',
  components: { ProcessModalUser, ProcessModalActor2, },
  props: {
    actors: { type: Array, default: () => [], },
    process: { type: Object, default: () => ({}), },
  },
  data() {
    return {
    }
  },
  methods: {
    add(title) {
      const id = $n.uuid()
      this.$emit('update:process', {
        ...this.process,
        actors: {
          ...this.process.actors,
          [id]: { id, title, values: [], },
        },
      })
    },
    remove(actor) {
      if (this.isDeletable(actor)) {
        const actors = $n.cloneDeep(this.process.actors)
        delete actors[actor.id]
        this.$emit('update:process', {
          ...this.process,
          actors,
        })
      }
    },
    addUser(actor, user) {
      this.$emit('update:process', {
        ...this.process,
        actors: {
          ...this.process.actors,
          [actor.id]: {
            ...this.process.actors[actor.id],
            values: [
              ...this.process.actors[actor.id].values,
              user,
            ],
          },
        },
      })
    },
    removeUser(actor, id) {
      this.$emit('update:process', {
        ...this.process,
        actors: {
          ...this.process.actors,
          [actor.id]: {
            ...this.process.actors[actor.id],
            values: $n.filter(this.process.actors[actor.id].values, (v) => {
              return v.id !== id
            }),
          },
        },
      })
    },
    isDeletable(actor) {
      return ![ 'author', 'system', ].includes(actor.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.actors {
  background: #f4fbff;
  position: relative;
  margin: 0 0 30px 0;
  border: 1px solid #ddd;

  .row {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .title-block {
    background: rgba(127, 127, 127, .05);
    font-size: .9em;
    .title {
      font-size: 1.1em;
      font-weight: bold;
    }
  }
  .actor-block {
    color: #777;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    line-height: 1;
    &>* {
      margin-right: 5px;
    }
    .actor {
      margin-bottom: 5px;
    }
  }
  .action-block {
    font-size: .9em;
    .action {
    }
    &>* {
      display: inline-block;
    }
    .event-block {
      margin-left: 4px;
      font-size: .8em;
    }
  }
  .editable {
    color: #000;
    border-bottom: 1px dotted #000;
    cursor: pointer;
  }
  .deletable {
    color: #000;
    border-bottom: 1px dotted #000;
    cursor: pointer;
    &:hover {
      text-decoration: line-through;
    }
  }
  .message {
    font-size: .9em;
    font-style: italic;
    opacity: .8;
  }

  .add-button {
    font-size: .8em;
    display: flex;
    align-items: center;
    line-height: 1;
    color: var(--primary);
    cursor: pointer;
    opacity: .6;
    &:hover {
      opacity: 1;
    }
    .n-icon {
      margin-right: 4px;
      font-size: .9em;
    }
  }
}
</style>
