import CAccesses from './accesses/Index'
import CReportCard from './reportCard/Index'
import CReportForm from './reportForm/Index'
import CPageSidebar from './pageSidebar/Index'
import CProcess from './process/Index'

export default {
  CAccesses,
  CReportCard,
  CReportForm,
  CPageSidebar,
  CProcess,
}

