<template>
  <n-modal class="process-modal-action" @close="$emit('close')">
    <n-form @submit="save">
      <n-items>
        <n-input v-if="type" text title="Действие" :value="selected.title" />
        <n-select v-else title="Действие" :data="actionsValues" v-bind="$form.input('action', 'select')" />
        <n-select v-if="getValues('action').length" title="Значение" :data="getValues('action')" v-bind="$form.input('value', 'select')" />

        <n-select title="Событие" :data="Object.values(variants.events)" v-bind="$form.input('event', 'select')" />
        <n-select v-if="getValues('event').length" title="Значение" :data="getValues('event')" v-bind="$form.input('eventValue', 'select')" />
        <n-input v-else-if="getHasInput('event')" title="Значение" v-bind="$form.input('eventValue')" />
        <div class="form-columns">
          <div><n-button outline color="danger" @click="remove">Удалить</n-button></div>
          <div><n-button color="success" type="submit">Сохранить</n-button></div>
        </div>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ProcessModalAction',
  props: {
    path: { type: String, required: true, },
    variants: { type: Object, required: true, },
    process: { type: Object, default: () => ({}), },
  },
  data() {
    return {
    }
  },
  computed: {
    action() {
      const parts = this.path.split('.')
      if (parts[2]) {
        return $n.get(this.process, 'actions.'+this.path)
      }
      return ''
    },
    selected() {
      return this.type ? this.variants.actions[this.type] : ''
    },
    type() {
      return this.action ? this.action.do.type : ''
    },
    value() {
      const value = this.action ? this.action.do.value : []
      return $n.map(value, (v) => {
        return $n.taskStatus(v)
      })
    },
    status() {
      const parts = this.path.split('.')
      return parts[0]
    },
    actor() {
      const parts = this.path.split('.')
      return parts[1]
    },
    actionsValues() {
      return Object.values(this.variants.actions)
    },
    eventType() {
      return this.action?.when ? this.action.when[0]?.type : ''
    },
    eventValue() {
      return this.action?.when ? this.action.when[0]?.value : ''
    },
  },
  created() {
    this.$form.init({
      action: this.selected,
      value: this.value,
      event: this.eventType,
      eventValue: this.eventValue,
    })
  },
  methods: {
    getValues(type) {
      const valuesName = this.$form.get(type)?.values
      if (!valuesName || valuesName === 'input') {
        return []
      } else if (valuesName === 'actors') {
        return $n.reduce(this.process.actors, (result, item, name) => {
          result.push({ id: name, title: item.title, })
          return result
        }, [])
      }
      return Object.values(this.variants.values[valuesName])
    },
    getHasInput(type) {
      return this.$form.get(type)?.values === 'input'
    },
    save() {
      const process = $n.cloneDeep(this.process)

      const when = []
      if (this.$form.get('event')) {
        when.push({
          type: this.$form.get('event').id,
          value: $n.map(this.$form.get('eventValue'), (v) => v.id),
        })
      }
      const value = {
        do: {
          type: this.$form.get('action').id,
          value: $n.map(this.$form.get('value'), (v) => v.id),
        },
        when,
      }
      
      if (this.action) {
        $n.set(process, 'actions.'+this.path, value)
      } else {
        $n.set(process, 'actions.'+this.path, [
          ...($n.get(process, 'actions.'+this.path) || []),
          value,
        ])
      }

      this.$emit('update:process', process)
      this.$emit('close')
    },
    remove() {
      const process = $n.cloneDeep(this.process)
      $n.unset(process, 'actions.'+this.path)

      this.$emit('update:process', process)
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.process-modal-action {

}
</style>
