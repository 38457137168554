import BaseRouter from 'nast-router'

/**
 *
 */
export default class NastRouter extends BaseRouter {
  /**
   * @param {Object} route
   */
  push(route) {
    this._router.push(route)
  }
}
