<template>
  <n-card class="card-delete-project">
    <h3>Удаление проекта</h3>
    <p>
      <i>Осторожно! Это действие необратимо!</i>
    </p>
    <n-button color="danger" @click="$var('modal', true)">Удалить проект</n-button>
    <n-modal v-if="$var('modal')" :loading="$var('load')" @close="$var('modal', false)">
      <h3>Удаление проекта</h3>
      <p>
        <i>Вы действительно хотите удалить проект?</i><br />
        <i>Это повлечет за собой удаление всех папок, файлов и задач.</i>
      </p>
      <n-button color="danger" @click="remove">Да, удалить проект</n-button>
    </n-modal>
  </n-card>
</template>

<script>
export default {
  name: 'CardDeleteProject',
  props: {
    project: { type: Object, default: () => {}, },
  },
  methods: {
    remove() {
      this.$var('load', true)
      $api.fs.projects.remove(this.project.id).then(() => {
        this.$var('modal', false)
        this.$emit('submit')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.card-delete-project {

  .n-button {
    font-size: .8em;
  }
}
</style>
