<template>
  <n-modal class="modal-edit-task" :loading="$var('load')" @close="$emit('close')">
    <div class="task-info">
      <div>
        <div class="title">Описание задачи</div>
        <n-items>
          <n-input title="Название" v-bind="$form.input('title')" />
          <n-textarea title="Описание" v-bind="$form.input('content')" />
          <div class="form-columns" style="width: 500px">
            <n-datepicker title="Дата начала" v-bind="$form.input('startedAt')" />
            <n-datepicker title="Дата окончания" v-bind="$form.input('endedAt')" />
          </div>
          <n-upload title="Сопроводительные документы" multi v-bind="$form.input('newFiles')" />
        </n-items>
      </div>
    </div>

    <br />
    <process :process.sync="process" title="Процесс задачи" />

    <div class="buttons">
      <div></div>
      <n-button color="success" @click="submit">Сохранить</n-button>
    </div>
  </n-modal>
</template>

<script>
import Process from '../../../components/process/Index.vue'

export default {
  name: 'ModalEditTask',
  components: { Process, },
  props: {
    task: { type: Object, default: () => {}, },
  },
  data() {
    return {
      process: this.task.process,
    }
  },
  created() {
    this.$form.init({
      ...this.task,
      startedAt: $app.date.format(this.task.startedAt, 'date'),
      endedAt: $app.date.format(this.task.endedAt, 'date'),
      newFiles: [],
      type: $n.taskType(this.task.type),
    })
  },
  methods: {
    submit() {
      this.$var('load', true)
      if ($n.size(this.$form.get('newFiles'))) {
        const apis = $n.map(this.$form.get('newFiles'), (file) => $api.base.files.create({ file, }))
        Promise.all(apis).then((responses) => {
          this.save($n.map(responses, (r) => r.data.content.id))
        })
      } else {
        this.save()
      }
    },
    save(newFiles) {
      const data = {
        title: this.$form.get('title'),
        content: this.$form.get('content'),
        startedAt: this.$form.get('startedAt'),
        endedAt: this.$form.get('endedAt'),
        process: this.process,
        newFiles,
      }
      $api.fs.tasks.edit(this.task.id, data).then((response) => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-edit-task {
  --n-modal-width: 1600px;

  .n-modal {
    --n-modal-width: 600px;
  }
  .buttons  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .task-info {
    display: flex;
    border: 1px solid #ddd;
    padding: 20px 10px;

    &>*:not(:last-child) {
      margin-right: 20px;
      width: 50%;
    }

    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .departments {
      margin: 10px 0;
    }
    .create-subtasks {
      margin-top: 20px;
    }
  }
  .has-tools {
    position: relative;
    padding-right: 30px;
    display: inline-block;
    &:hover .tools {
      opacity: 1;
    }
  }
  .tools {
    position: absolute;
    right: 0;
    top: 0;
    font-size: .7rem;
    opacity: .3;
    transition: opacity .3s;
  }
}
</style>
