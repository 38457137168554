<template>
  <n-modal class="modal-retrieve" :loading="$var('load')" @close="$emit('close')">
    <h3>Вернуть файл</h3>
    <n-form @submit="submit">
      <n-items>
        <div style="width: 100%"><n-upload title="Файл" v-bind="$form.input('file')" /></div>
        <n-input title="Процент готовности файла (%)" v-bind="$form.input('done')" />
        <n-input title="Затраченное время (минут)" v-bind="$form.input('time')" />
        <n-button type="submit">Загрузить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalRetrieve',
  props: {
    file: { type: Object, required: true, },
  },
  created() {
    this.$form.init({
      file: null,
      done: this.file.done,
      time: Math.ceil($app.date.now().diff($app.date.now(this.file.takeAt)) / 1000 / 60),
    })
  },
  methods: {
    submit() {
      this.$var('load', true)

      const data = {
        file: this.$form.get('file'),
        done: this.$form.get('done'),
      }
      $api.fs.documents.editFile(this.file.id, data).then((response) => {
        this.$emit('submit')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-retrieve {

}
</style>
