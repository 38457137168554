<template>
  <div class="c-time">
    <div v-if="title" class="block-title">{{ title }}</div>
    <span class="scale" :class="[ { select: !parsedTime.scale.id }, ]" @click="selected = 'scale'">
      {{ parsedTime.scale.title || 'Выбрать масштаб' }}
    </span>
    <span class="text">по полю</span>
    <span class="scale-field" :class="[ { select: !parsedTime.scaleField.id }, ]" @click="selected = 'scale'">
      {{ parsedTime.scaleField.title || 'выбрать поле' }}
    </span>
    <br />
    <span class="text">В периоде от</span>
    <span class="range" :class="[ { select: !parsedTime.startedAt }, ]" @click="selected = 'range'">
      {{ parsedTime.startedAt || 'выбрать дату' }}
    </span>
    <span class="text">до</span>
    <span class="range" :class="[ { select: !parsedTime.endedAt }, ]" @click="selected = 'range'">
      {{ parsedTime.endedAt || 'выбрать дату' }}
    </span>
    <br />
    <span class="text">С группировкой по</span>
    <template v-if="parsedTime.sliceFields.length">
      <span v-for="field in parsedTime.sliceFields" :key="field.id" class="slice-field" @click="selected = 'slice'">{{ field.title }}</span>
    </template>
    <span v-else class="slice-field select" @click="selected = 'slice'">выбрать поля</span>

    <modal-time-scale v-if="selected === 'scale'" :params="params" :ui="ui" :model="model"
                      @update:params="(v) => $emit('update:params', v)" @close="selected = null" />
    <modal-time-range v-if="selected === 'range'" :params="params" :ui="ui" :model="model"
                      @update:params="(v) => $emit('update:params', v)" @close="selected = null" />
    <modal-time-slice v-if="selected === 'slice'" :params="params" :ui="ui" :model="model"
                      @update:params="(v) => $emit('update:params', v)" @close="selected = null" />
  </div>
</template>

<script>
import map from 'lodash/map'
import { getBuilder, } from '../../utils'
import ModalTimeScale from './ModalTimeScale'
import ModalTimeRange from './ModalTimeRange'
import ModalTimeSlice from './ModalTimeSlice'

export default {
  name: 'CTime',
  components: { ModalTimeSlice, ModalTimeRange, ModalTimeScale, },
  props: {
    params: { type: Object, required: true, }, // RequestBuilder.params()
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    title: { type: String, default: '', },
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    parsedTime() {
      const scaleParts = this.params.scale ? this.params.scale.split(',') : []
      const rangeParts = this.params.range ? this.params.range.split(',') : []
      const sliceParts = this.params.slice ? this.params.slice.split(',') : []
      const sliceFields = sliceParts.length ? map(sliceParts, (item) => this.ui[this.model].fields[item]) : []
      return {
        scaleField: this.ui[this.model].fields[scaleParts[0]] || {},
        scale: this.ui._general.scale[scaleParts[1]] || {},
        startedAt: rangeParts[0] || '',
        endedAt: rangeParts[1] || '',
        sliceFields,
      }
    },
  },
  methods: {
    remove(name) {
      const builder = getBuilder(this.params)
      builder.remove('filter', name)
      this.$emit('update:params', builder.params())
    },
  },
}
</script>

<style lang="scss" scoped>
.c-time {
  width: 100%;
  .block-title {
    opacity: .5;
    font-size: .8em;
  }

  .text {
    font-size: 1em;
    opacity: .6;
  }
  .scale, .scale-field, .range, .slice-field {
    border-bottom: 1px dotted #000;
    cursor: pointer;
    margin: 0 8px;
  }
  .scale {
    margin-left: 0;
  }
  .select {
    color: var(--primary);
    border-color: var(--primary);
  }
  .slice-field {
    &+.slice-field {
      margin-left: 0;
    }
  }
}
</style>
