import App from 'layouts/App'
import MainLayout from 'layouts/main/Index'
import AuthLayout from 'layouts/auth/Index'
import SettingsLayout from 'pages/settings/Layout.vue'
import Index from 'pages/index/Index'
import ReportsLayout from 'pages/reports/Layout'
import ReportsStart from 'pages/reports/start/Index'
import ReportsCreate from 'pages/reports/create/Index'
import ReportsIndex from 'pages/reports/index/Index'
import TasksList from 'pages/tasks/list/Index'
import ProjectList from 'pages/projects/list/Index'
import Profile from 'pages/Profile'
import Notifies from 'pages/notifies/Index'
import Files from 'pages/filesNew/Index'
import FilesDetails from 'pages/filesNew/Details'
import Login from 'pages/auth/Login'
import ProjectDetail from 'pages/projects/detail/Index'
import SettingsUsers from 'pages/settings/users/Index'
import SettingsUsersIndex from 'pages/settings/users/users/Index'
import SettingsUsersDepartments from 'pages/settings/users/departments/Index'
import SettingsUsersGroups from 'pages/settings/users/groups/Index'
import SettingsProjectsTemplates from 'pages/settings/projectsTemplates/Index'
import SettingsTasksTemplates from 'pages/settings/tasksTemplates/Index'
import SettingsTasksStatuses from 'pages/settings/tasksStatuses/Index'

export default [
  { path: '/', component: App, children: [
    { path: '/', component: MainLayout, children: [

      { path: '/', name: 'index', component: Index, title: 'Панель состояния', },

      { path: 'filesystem/:category?/:id?', name: 'files.index', component: Files, parent: 'index', title: 'Файлы', children: [
        { path: ':type/details/:itemId', name: 'files.details', component: FilesDetails, },
      ], },

      { path: 'projects', name: 'projects.list', component: ProjectList, parent: 'index', title: 'Проекты', },
      { path: 'projects/:id', name: 'projects.detail', component: ProjectDetail, parent: 'projects.list', title: 'Карточка проекта', },

      { path: 'tasks/project-:projectId?/tasks', name: 'tasks.list', component: TasksList, title: 'Список задач', },

      { path: 'reports', component: ReportsLayout, children: [
        { path: 'create', name: 'reports', component: ReportsStart, title: 'Отчеты', },
        { path: 'create/:type', name: 'reports.create', component: ReportsCreate, parent: 'reports.list', title: 'Создание отчета', },
        { path: ':id', name: 'reports.index', component: ReportsIndex, parent: 'reports.list', title: 'Отчет', },
      ], },

      { path: 'notifies', name: 'notifies', component: Notifies, title: 'Уведомления', },
      { path: 'profile', name: 'profile', component: Profile, },

      { path: 'settings', component: SettingsLayout, children: [
        { path: 'users', component: SettingsUsers, children: [
          { path: '', name: 'settings.users.index', component: SettingsUsersIndex, parent: 'index', title: 'Настройки пользователей', },
          { path: 'departments', name: 'settings.users.deps', component: SettingsUsersDepartments, parent: 'settings.users.index', title: 'Настройки департаментов', },
          { path: 'groups', name: 'settings.users.groups', component: SettingsUsersGroups, parent: 'settings.users.index', title: 'Настройки групп', },
        ], },
        { path: 'templates/projects', name: 'settings.projects.templates', component: SettingsProjectsTemplates, parent: 'index', title: 'Шаблоны проектов', },
        { path: 'templates/tasks', name: 'settings.tasks.templates', component: SettingsTasksTemplates, parent: 'index', title: 'Шаблоны задач', },
        { path: 'templates/tasks-statuses', name: 'settings.tasks.statuses', component: SettingsTasksStatuses, parent: 'index', title: 'Статусы задач', },
      ], },
    ], },

    { path: '/', component: AuthLayout, children: [
      { path: 'login', name: 'login', component: Login, },
    ], },
  ], },
]
