<template>
  <div class="n-chart-bar">
    <canvas :id="name"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import size from 'lodash/size'
import reduce from 'lodash/reduce'
import each from 'lodash/each'

export default {
  name: 'NChartBar',
  props: {
    data: { type: Array, required: true, },
    columns: { type: Array, required: true, }, // [ { name: '', title: '', }, ]

    name: { type: String, default: () => 'n-chart-'+Math.random(), },
    titleField: { type: String, default: 'title', },
    colors: { type: Array, default: () => [
      'rgba(255, 99, 132, 0.7)',
      'rgba(54, 162, 235, 0.7)',
      'rgba(255, 206, 86, 0.7)',
      'rgba(75, 192, 192, 0.7)',
      'rgba(153, 102, 255, 0.7)',
      'rgba(255, 159, 64, 0.7)',
    ], },
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    titles() {
      return reduce(this.data, (result, item) => {
        result.push(item[this.titleField])
        return result
      }, [])
    },
    values() {
      return reduce(this.data, (result, item) => {
        each(this.columns, (column) => {
          if (column.name === this.titleField) return
          if (!result[column.name]) result[column.name] = []
          result[column.name].push(item[column.name])
        })
        return result
      }, {})
    },
  },
  watch: {
    data() {
      this.initChart()
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      if (size(this.data)) {
        let datasetIndex = 0
        if (this.chart) {
          this.chart.destroy()
        }
        this.chart = new Chart(document.getElementById(this.name), {
          type: 'bar',
          data: {
            labels: this.titles,
            datasets: reduce(this.columns, (result, column) => {
              if (column.name === this.titleField) {
                return result
              }
              result.push({
                label: column.title,
                data: this.values[column.name],
                backgroundColor: this.colors[datasetIndex++],
              })
              return result
            }, []),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.n-chart-bar {
  max-width: 600px;
}
</style>
