export default [
  { name: "title", title: "Название", sortable: true, id: 1 },
  { name: "status", title: "", id: 2 },
  { name: "author", title: "Автор", sortable: true, id: 3 },
  {
    name: "updatedAt",
    title: "Дата изменений",
    sortable: true,
    type: "date",
    id: 4,
  },
  { name: "type", title: "Тип", sortable: true, id: 5 },
  { name: "size", title: "Размер", sortable: true, type: "number", id: 6 },
  { name: "project", title: "Проект", sortable: true, id: 7 },
  { name: "updatedBy", title: "Кем изменен", sortable: true, id: 8 },
  { name: "takenBy", title: "Кем взят", sortable: true, id: 9 },
];
