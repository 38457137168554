<template>
  <div class="page-login">
    <n-loader :loading="loader" />

    <n-form @submit="submit">
      <n-items>
        <n-input title="Логин" v-bind="$form.input('login')" />
        <n-input title="Пароль" type="password" v-bind="$form.input('password')" />
        <n-button color="primary" type="submit" wide :disabled="loader">Вход</n-button>
      </n-items>
    </n-form>
  </div>
</template>

<script>
export default {
  name: 'PageLogin',
  data() {
    return {
      loader: false,
    }
  },
  created() {
    this.$form.init({
      login: '',
      password: '',
    })
    this.$form.rules({
      login: [ 'required', ],
      password: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.loader = true
        $api.auth.login().data(this.$form.get()).then((response) => {
          $app.auth.login(response.data.content)
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-login {

}
</style>
