<template>
  <n-card class="page-reports-create" :loading="$var('load')">
    <template v-if="type">
      <h3>{{ type.title }}</h3>
      <c-report-form :type="type.name" @submit="save" />
    </template>
  </n-card>
</template>

<script>
export default {
  name: 'PageReportsCreate',
  computed: {
    type() {
      return $n.reportType(this.$route.params.type)
    },
  },
  methods: {
    save(data) {
      this.$var('load', true)
      $api.reports.post(data).then((response) => {
        this.$emit('reload')
        this.$router.push({ name: 'reports.index', params: { id: response.data.content.id, }, })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-reports-create {
  position: relative;
  max-width: 800px;

  &::v-deep>.content>main {
    padding-bottom: 200px;
  }
}
</style>
