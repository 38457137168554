<template>
  <n-modal class="process-modal-user" @close="$emit('close')">
    <n-form @submit="() => save()">
      <n-items>
        <n-select title="Участник" :data="actors" v-bind="$form.input('user', 'select')" />
        <n-button type="submit" color="success" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ProcessModalUser',
  props: {
    actor: { type: Object, required: true, },
    actors: { type: Array, default: () => [], },
  },
  created() {
    this.$form.init({
      user: null,
    })
  },
  methods: {
    save() {
      this.$emit('submit', this.actor, this.$form.get('user'))
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.process-modal-user {
  --n-modal-width: 400px;
}
</style>
