<template>
  <div class="page-settings-users">
    <div class="page-menu">
      <n-link :to="{ name: 'settings.users.index', }">Все пользователи</n-link>
      <n-link :to="{ name: 'settings.users.deps', }">Департаменты</n-link>
<!--      <n-link :to="{ name: 'settings.users.groups', }">Группы</n-link>-->
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Index',
}
</script>

<style lang="scss" scoped>
.page-settings-users {
  .page-menu {
    margin-bottom: 20px;
    .n-link {
      margin-right: 15px;
    }
    .link-exact-active {
      color: inherit;
    }
  }
}
</style>
