<template>
  <n-modal class="modal-add-document" :loading="$var('load')" @close="$emit('close')">
    <h3>Загрузить файл</h3>
    <n-form @submit="submit">
      <n-items>
        <div>
          <n-upload title="Файл" v-bind="$form.input('file')" @update:value="updateFile" />
        </div>
        <n-input v-if="$form.get('file')" title="Название" v-bind="$form.input('title')" />
        <div v-if="$form.get('file') && !isPrivate">
          <n-checkbox v-bind="$form.input('useCode')">Использовать код</n-checkbox>
        </div>
        <div v-if="$form.get('file') && $form.get('useCode')">
          Итоговое название: {{ result }}
        </div>
        <n-button type="submit">Загрузить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalAddDocument',
  props: {
    folder: { type: Object, required: true, },
    file: { type: File, default: null, },
    isPrivate: { type: Boolean, required: true, },
  },
  data() {
    return {
      project: null,
    }
  },
  computed: {
    result() {
      const title = this.$form.get('title')

      if (this.project && this.project.codeNames && $n.size(this.project.codeNames)) {
        const attributes = this.project.folder.attributes || []
        // const ext = title.split('.').slice(-1)[0]
        return $n.reduce(this.project.codeNames, (result, name, index) => {
          const attribute = attributes[name]?.value || '['+name+']'
          result += name === 'title' ? this.$form.get('title') : attribute
          if (index + 1 < $n.size(this.project.codeNames)) {
            result += this.project.codeSeparator
          }
          return result
        }, '')
      }

      return title
    },
  },
  created() {
    this.load()
    this.$form.init({
      file: null,
      title: '',
    })
    this.$form.rules({
      file: [ 'required', ],
      title: [ 'required', ],
    })
    if (this.file) {
      this.updateFile(this.file)
    }
  },
  methods: {
    load() {
      this.$var('load', true)
      const api = $api.fs.folders.get(this.folder.id)
        .with('project',
          (q) => q.with('folder',
            (q) => q.with('attributes',
              (q) => q.key('name')
            )
          )
        )
      api.then((response) => {
        this.project = response.data.content.project
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      // if (this.$form.check()) {
      this.$var('load', true)
      const data = {
        file: this.$form.get('file'),
        title: this.$form.get('title'),
        folderId: this.folder.id,
        useCode: this.$form.get('useCode') ? 'true' : 'false',
        isPrivate: this.isPrivate,
      }
      $api.fs.documents.create(data).then(() => {
        this.$emit('close')
        this.$emit('reload')
      }).finally(() => {
        this.$var('load', false)
      })
      // }
    },
    updateFile(file) {
      this.$form.set('file', file)
      this.$form.set('title', file ? file.name.replace(/\.[^/.]+$/, '') : '')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-add-document {
  .n-items>div {
    width: 100%;
  }
}
</style>
