<template>
  <div class="page-profile">
    <n-button @click="logout">Выход</n-button>
  </div>
</template>

<script>
export default {
  name: 'PageProfile',
  methods: {
    logout() {
      $app.store.action('notify.stop')
      $app.auth.logout()
    },
  },
}
</script>

<style></style>
