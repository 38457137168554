/**
 *
 */
export default class RestApi {
  auth = {
    info: () => $app.api.get([ 'auth/info', ]),
    login: (login, password) => $app.api.post([ 'auth/login', ]).data({ login, password, }),
    users: {
      get: (id) => $app.api.get([ 'users*', id, ]),
      post: (data) => $app.api.post([ 'users', ]).data(data),
    },
    accesses: {
      get: (id, model) => $app.api.get([ 'accesses**', model, id, ]),
      getNew: (id, model) => $app.api.get([ 'accesses**/new', model, id, ]),
      create: (data) => $app.api.post([ 'accesses', ]).data(data),
      delete: (id) => $app.api.delete([ 'accesses*', id, ]),
      models: {
        groups: (id, model, getNew = undefined, group = 'group') => $app.api.get([ 'accesses/models/groups', ])
          .query({ id, model, new: getNew, group, }),
        users: (id, model, getNew = undefined) => $app.api.get([ 'accesses/models/users', ])
          .query({ id, model, new: getNew, }),
        roles: (id, model, ownerId, owner, getNew = undefined, endedAt) => $app.api.get([ 'accesses/models/roles', ])
          .query({ id, model, ownerId, owner, new: getNew, endedAt, }),
      },
      cancels: {
        toggle: (data) => $app.api.post([ 'accesses/cancel', ]).data(data),
      },
    },
    groups: {
      get: (id) => $app.api.get([ 'auth/groups*', id, ]),
    },
    roles: {
      get: () => $app.api.get([ 'auth/roles', ]),
    },
    grants: {
      clarify: (name) => $app.api.get([ 'accesses/grants*/clarify', name, ]),
    },
  }

  base = {
    files: {
      create: (data) => {
        const formData = new FormData()
        $n.each(data, (value, name) => formData.append(name, value))
        return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
      },
    },
  }

  profile = {
    widgets: {
      get: (id) => $app.api.get([ 'widgets*', id, ]),
      post: (data) => $app.api.post([ 'widgets', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'widgets*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'widgets*', id, ]),
    },
  }

  company = {
    users: {
      get: (id) => $app.api.get([ 'users*', id, ]),
    },
    departments: {
      get: (id) => $app.api.get([ 'departments*', id, ]),
      post: (data) => $app.api.post([ 'departments', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'departments', id, ]).data(data),
      remove: (id) => $app.api.delete([ 'departments', id, ]),
    },
  }

  process = {
    get: (id = undefined) => $app.api.get([ 'processes*', id, ]),
    create: (data) => $app.api.post([ 'processes', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'processes*', id, ]).data(data),
    remove: (id) => $app.api.delete([ 'processes*', id, ]),
    variants: (model) => $app.api.get([ 'processes/variants', ]).query({ model, }),
    users: (id, actors) => $app.api.get([ 'tasks*/process/users', id, ]).query({ actors, }),
    statuses: {
      get: () => $app.api.get([ 'process/statuses', ]),
      create: (data) => $app.api.post([ 'process/statuses', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'process/statuses*', id, ]).data(data),
      remove: (id) => $app.api.delete([ 'process/statuses*', id, ]),
    },
  }

  reports = {
    get: (id) => $app.api.get([ 'reports*', id, ]),
    data: (id) => $app.api.get([ 'reports*/data', id, ]),
    titles: () => $app.api.get([ 'reports/titles', ]),
    select: (model, data, id = undefined) => $app.api.get([ 'reports/select', ]).query({ model, data, id, }),
    post: (data) => $app.api.post([ 'reports', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'reports*', id, ]).data(data),
    remove: (id) => $app.api.delete([ 'reports*', id, ]),
  }

  fs = {
    notifies: {
      get: () => $app.api.get([ 'notifies', ]),
      info: () => $app.api.get([ 'notifies/info', ]),
      read: (id) => $app.api.post([ 'notifies*/read', id, ]),
      readAll: () => $app.api.post([ 'notifies/read', ]),
    },
    documents: {
      get: (id) => $app.api.get([ 'documents*', id, ]),
      create: (data) => {
        const formData = new FormData()
        $n.each(data, (value, name) => {
          formData.append(name, value)
        })
        return $app.api.post([ 'documents', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
      },
      edit: (id, data) => $app.api.patch([ 'documents*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'documents*', id, ]),
      take: (id) => $app.api.post([ 'documents*/take', id, ]),
      return: (id) => $app.api.post([ 'documents*/return', id, ]),
      editFile: (id, data) => {
        const formData = new FormData()
        $n.each(data, (value, name) => {
          formData.append(name, value)
        })
        return $app.api.post([ 'documents*/file', id, ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
      },
      removeAttr: (id, name) => $app.api.delete([ 'documents*/attributes*', id, name, ]),
      versions: {
        edit: (id, data) => $app.api.patch([ 'documents/versions*', id, ]).data(data),
      },
    },
    fs: {
      get: () => $app.api.get([ 'filesystem/folders', ]),
    },
    folders: {
      get: (id) => $app.api.get([ 'folders*', id, ]),
      getDocuments: (folderId) => $app.api.get([ 'folders*/documents', folderId, ]),
      getTrashed: () => $app.api.get([ 'folders/trashed', ]),
      getArchive: () => $app.api.get([ 'folders/archive', ]),
      getData: (id) => $app.api.get([ 'filesystem*', id, ]).with('taker'),
      getDataTrash: (id) => $app.api.get([ 'filesystem/trash*', id, ]).with('taker'),
      getDataArchive: (id) => $app.api.get([ 'folders/archive', id, ]),
      getDataManager: () => $app.api.get([ 'filesystem/manager', ]).with('taker'),
      getDataFavorites: () => $app.api.get([ 'filesystem/favorites', ]).with('taker'),
      create: (data) => $app.api.post([ 'folders', ]).data(data),
      createRevision: (id, data) => $app.api.post([ 'folders*/revision', id, ]).data(data),
      edit: (id, data) => $app.api.patch([ 'folders*', id, ]).data(data),
      removeAttr: (id, name) => $app.api.delete([ 'folders*/attributes*', id, name, ]),
      delete: (id) => $app.api.delete([ 'folders*', id, ]),
      trash: (id) => $app.api.get([ 'trashBin*', id, ]),
      users: {
        get: (id) => $app.api.get([ 'folders*/users', id, ]),
        getNew: (id) => $app.api.get([ 'folders*/users/new', id, ]),
        accesses: {
          get: (id) => $app.api.get([ 'folders*/users/accesses', id, ]),
          getNew: (id, userId) => $app.api.get([ 'folders*/users*/accesses/new', id, userId, ]),
          add: (id, userId, access, data) => $app.api.post([ 'folders*/users*/accesses*', id, userId, access, ]).data(data),
          remove: (id, userId, access) => $app.api.delete([ 'folders*/users*/accesses*', id, userId, access, ]),
        },
      },
    },
    projects: {
      get: (id) => $app.api.get([ 'projects*', id, ]),
      edit: (id, data) => $app.api.patch([ 'projects*', id, ]).data(data),
      addMember: (id, memberId, data) => $app.api.post([ 'projects*/members*', id, memberId, ]).data(data).mock(() => {}),
      create: (data) => $app.api.post([ 'projects', ]).data(data),
      remove: (id) => $app.api.delete([ 'projects', id, ]),
      removeAttr: (id, name) => $app.api.delete([ 'projects*/attributes*', id, name, ]),
      archive: {
        get: () => $app.api.get([ 'projects/archive', ]),
        to: (id) => $app.api.post([ 'projects*/archive/to', id, ]),
        from: (id) => $app.api.post([ 'projects*/archive/from', id, ]),
      },
      revision: {
        create: (id) => $app.api.post([ 'projects*/revision', id, ]),
      },
      users: {
        get: (id) => $app.api.get([ 'projects*/users', id, ]),
        getNew: (id) => $app.api.get([ 'projects*/users/new', id, ]),
        accesses: {
          get: (id) => $app.api.get([ 'projects*/users/accesses', id, ]),
          getNew: (id, userId) => $app.api.get([ 'projects*/users*/accesses/new', id, userId, ]),
          add: (id, userId, access, data) => $app.api.post([ 'projects*/users*/accesses*', id, userId, access, ]).data(data),
          remove: (id, userId, access) => $app.api.delete([ 'projects*/users*/accesses*', id, userId, access, ]),
        },
      },
    },
    roles: {
      get: (id)=> $app.api.get([ 'roles*', id, ]).mock(()=>{}),
    },
    attributes: {
      get: (id) => $app.api.get([ 'attributes*', id, ]),
      getData: () => $app.api.get([ 'attributes/data', ]),
      create: (data) => $app.api.post([ 'attributes', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'attributes*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'attributes*', id, ]),
    },

    tasks: {
      get: (id) => $app.api.get([ 'tasks*', id, ]),
      create: (data) => $app.api.post([ 'tasks', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'tasks*', id, ]).data(data),
      remove: (id) => $app.api.delete([ 'tasks*', id, ]),
      status: (id, status) => $app.api.patch([ 'tasks*/status', id, ]).data({ status, }),
      sign: (id, data) => $app.api.post([ 'tasks*/sign', id, ]).data(data),
      appoint: (id, data) => $app.api.post([ 'tasks*/appoint', id, ]).data(data),
      files: {
        get: (id) => $app.api.get([ 'tasks*/files', id, ]),
      },
      comments: {
        get: (taskId) => $app.api.get([ 'projects/tasks*/comments', taskId, ]),
        create: (taskId, data) => $app.api.post([ 'projects/tasks*/comments', taskId, ]).data(data),
        edit: (id, data) => $app.api.patch([ 'projects/tasks/comments*', id, ]).data(data),
        remove: (id) => $app.api.delete([ 'projects/tasks/comments*', id, ]),
      },
    },
  }
}
