<template>
  <div class="tasks-templates">
    <h2>Статусы задач</h2>
    <n-button @click="$var('edit', true)">Создать</n-button>
    <n-table :data="statuses" :columns="columns" :loading="$var('load')">
      <template #createdAt="{item}">
        {{ $app.date.format(item.createdAt) }}
      </template>
      <template #tools="{item}">
        <n-button icon="pen" @click="$var('edit', item)" />
      </template>
    </n-table>

    <modal-edit-status v-if="$var('edit')" :item="$var('edit')" @close="$var('edit', false)" @submit="load" />
  </div>
</template>

<script>
import ModalEditStatus from './ModalEdit.vue'
export default {
  name: 'TasksStatuses',
  components: { ModalEditStatus, },
  data() {
    return {
      statuses: [],
      columns: [
        { name: 'title', title: 'Название', },
        { name: 'createdAt', title: 'Дата создания', },
        { name: 'tools', title: '', },
      ],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.process.statuses.get().then((response) => {
        this.statuses = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.tasks-templates {
  position: relative;
  h2 {
    margin-top: 0;
    padding-top: 20px;
  }
}
</style>
