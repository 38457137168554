<template>
  <div class="fs-pages">
    <fs-page v-for="(window, i) in windows" :key="i"
             :folders="folders" :roots="roots" :window="window" :favorites.sync="favorites" :loading="$var('load')" :updated="updated"
             @reload="(t) => load(t)" @update:window="(w) => editWindow(i, w)" @createWindow="(f) => createWindow(f)" @removeWindow="removeWindow(i)" />
  </div>
</template>

<script>
import FsPage from './fs/Index.vue'

export default {
  name: 'PageFiles',
  components: { FsPage, },
  data() {
    return {
      folders: {}, // общий массив всех папок
      roots: [], // id рутовых директорий категорий
      updated: $app.date.now(),
    }
  },
  computed: {
    windows() {
      return $app.store.getter('filesystem.windows')
    },
    favorites: {
      get() {
        return $app.store.getter('filesystem.favorites')
      },
      set(value) {
        $app.store.mutation('filesystem.favorites', value)
      },
    },
  },
  created() {
    this.load()
  },
  methods: {
    load(type = 'structure') {
      if (type === 'structure' || type === 'all') {
        this.$var('load', true)
        $api.fs.fs.get().key('id').with('childIds').with('parentIds').then((response) => {
          this.folders = response.data.content
          this.roots = response.data.tree?.roots
        }).finally(() => {
          this.$var('load', false)
        })
        $app.auth.clarify('folder_write')
      }
      if (type === 'data' || type === 'all') {
        this.updated = $app.date.now()
      }
    },
    createWindow(f) {
      $app.store.mutation('filesystem.createWindow', f)
    },
    removeWindow(index) {
      $app.store.mutation('filesystem.removeWindow', index)
    },
    editWindow(index, window) {
      $app.store.mutation('filesystem.window', { index, window, })
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-pages {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  &>* {
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 1500px;
    &:nth-child(2) {
      margin-left: 20px;
    }
  }
}
</style>
