<template>
  <n-modal class="modal-sign" :loading="$var('load')" @close="$emit('close')">
    <h3>Назначить исполнителя</h3>
    <n-form @submit="submit">
      <n-items>
        <n-select title="Пользователь" :data="users" v-bind="$form.input('user', 'select')" />
        <n-button type="submit">Назначить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalAppoint',
  props: {
    task: { type: Object, default: () => {}, },
    actors: { type: Array, required: true, },
  },
  data() {
    return {}
  },
  computed: {
    users() {
      return this.task.processInfo.users[this.actors[0]]
    },
  },
  created() {
    this.$form.init({
      user: null,
    })
  },
  methods: {
    submit() {
      this.$var('load', true)
      const data = {
        actor: this.actors[1],
        userId: this.$form.get('user.id'),
      }
      $api.fs.tasks.appoint(this.task.id, data).then((response) => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-sign {
  --n-modal-width: 600px;

}
</style>
