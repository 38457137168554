<template>
  <n-modal class="process-modal-status" @close="$emit('close')">
    <n-form @submit="() => save()">
      <n-items>
        <n-select title="Статус" :data="$n.values(statuses)" v-bind="$form.input('status', 'select')" />
        <div class="form-columns">
          <div><n-button v-if="status !== true" outline color="danger" @click="remove()">Удалить</n-button></div>
          <div><n-button color="success" type="submit">Сохранить</n-button></div>
        </div>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ProcessModalStatus',
  props: {
    status: { type: [ Object, Boolean, ], default: true, },
    process: { type: Object, default: () => ({}), },
    statuses: { type: Object, default: () => ({}), },
  },
  created() {
    this.$form.init({
      status: this.status !== true ? $n.find($n.taskStatus(), [ 'name', this.status, ]) : null,
    })
  },
  methods: {
    save() {
      let process = $n.cloneDeep(this.process)
      let value = {}
      if (this.status !== true) {
        value = process.actions[this.status.id]
        $n.unset(process.actions, this.status.id)
      }
      if (!process.actions[this.$form.get('status.id')]) {
        process = {
          ...process,
          actions: {
            ...process.actions,
            [this.$form.get('status.id')]: value,
          },
        }
      }

      this.$emit('update:process', process)
      this.$emit('close')
    },
    remove() {
      const process = $n.cloneDeep(this.process)
      $n.unset(process.actions, this.status.id)

      this.$emit('update:process', process)
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.process-modal-status {

}
</style>
