<template>
  <div class="main-sidebar">
    <nav class="navbar">
      <div>
        <n-link :to="{ name: 'index', }" class="logo">
          <img :src="imgLogo" alt="logo" />
        </n-link>
      </div>
      <n-link v-for="(item, i) in navigation" :key="i" :to="item.to">
        <div class="item">
          <div class="icon"><n-icon :icon="item.icon" /></div>
          <div class="title">{{ item.title }}</div>
        </div>
      </n-link>
    </nav>
  </div>
</template>

<script>
import imgLogo from 'assets/design/logo.png'

export default {
  name: 'MainSidebar',
  data() {
    return {
      imgLogo,
    }
  },
  computed: {
    navigation() {
      return $app.auth.filter([
        { title: 'Задачи', to: { name: 'tasks.list', }, icon: 'tasks', },
        // { title: 'Файлы old', to: { name: 'Files', }, },
        { title: 'Файлы', to: { name: 'files.index', params: { category: 'projects', }, }, icon: 'folder', },
        { title: 'Проекты', to: { name: 'projects.list', }, can: 'project_read', icon: 'project-diagram', },
        { title: 'Отчеты', to: { name: 'reports', }, icon: 'chart-line', },
        { title: 'Уведомления', to: { name: 'notifies', }, icon: 'bell', },
        { title: 'Настройки', to: { name: 'settings.users.index', }, can: 'admin', icon: 'cog', },
      ])
    },
  },
}
</script>

<style lang="scss" scoped>
.main-sidebar {
  width: 100px;
  background: #fff;
  border-right: 1px solid #eee;
  box-shadow: -6px 0 9px 0px #000;

  .navbar {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &>* {
      margin: 10px 0;
    }
    a {
      text-decoration: none;
    }
  }

  .item {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border: 1px solid #ccc;
      border-radius: 10px;
    }
    .title {
      margin-top: 3px;
      font-size: .9em;
      color: #777;
    }
  }

  .n-link {
    &.link-active {
      .icon {
        background: var(--primary);
        border-color: var(--primary);
        color: var(--primary-text);
      }
      .title {
        color: var(--primary);
      }
    }
    &:hover:not(.link-active) {
      .icon {
        background: var(--primary-t-10);
        border-color: var(--primary-t-9);
      }
      .title {
        color: var(--primary-t-2);
      }
    }
  }

  .logo {
    width: 50px;
    display: inline-block;
    img {
      max-width: 100%;
    }
  }
}
</style>
