<template>
  <n-modal class="process-modal-actor" @close="$emit('close')">
    <n-form @submit="save">
      <n-items>
        <n-select title="Участник" :data="actorsValues" v-bind="$form.input('actor', 'select')" />
        <div class="form-columns">
<!--          <div><n-button v-if="actor" outline color="danger" @click="() => save(true)">Удалить</n-button></div>-->
          <div><n-button color="success" type="submit">Сохранить</n-button></div>
        </div>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ProcessModalActor',
  props: {
    status: { type: [ String, Number, ], required: true, },
    actors: { type: Object, default: () => ({}), },
    process: { type: Object, default: () => ({}), },
  },
  computed: {
    actorsValues() {
      return Object.values(this.process.actors)
    },
  },
  created() {
    this.$form.init({
      actor: null,
    })
  },
  methods: {
    save() {
      const process = $n.cloneDeep(this.process)
      const value = this.$form.get('actor')

      this.$emit('update:process', {
        ...process,
        actions: {
          ...process.actions,
          [this.status]: {
            ...process.actions[this.status],
            [value.id]: [],
          },
        },
      })
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.process-modal-actor {

}
</style>
