<template>
  <div class="c-breadcrumbs">
    <div class="breadcrumb-wrap">
      <div v-for="item in $app.router.breadcrumbs()" :key="item.name" class="breadcrumb">
        <n-link v-if="item.name !== $route.name" :to="item">
          {{ item.title }}
        </n-link>
        <n-icon v-if="item.name !== $route.name" icon="angle-right" />
        <h1 v-else>{{ item.title }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CBreadcrumbs',
}
</script>

<style lang="scss" scoped>
.c-breadcrumbs {
  .breadcrumb {
    display: inline-block;
  }
  .n-link {
    font-size: .9em;
    color: #bbb;
  }
  .n-icon {
    margin: 0 8px 3px;
    font-size: .8em;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 400;
  }
}
</style>
