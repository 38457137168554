export default [
  { name: "title", title: "Название задания", id: 1 },
  { name: "responsible", title: "Кто выполняет", id: 2 },
  { name: "status", title: "Статус", id: 3 },
  { name: "percent", title: "Процент", id: 4 },
  { name: "department", title: "Учавствующий отдел", id: 5 },
  { name: "checker", title: "Кто проверяет", id: 6 },
  { name: "author", title: "Автор задания", id: 7 },
  { name: "negotiated", title: "Согласовано", id: 8 },
  { name: "deadline", title: "Срок сдачи", id: 9 },
];
