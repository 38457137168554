<template>
  <div class="process">

    <h3>{{ title }}</h3>

    <process-actors :process="process" :actors="variants.actors" @update:process="(v) => $emit('update:process', v)" />

    <div v-if="$n.size(process.actions)" class="statuses">
      <status v-for="(v, id) in process.actions" :key="id" :process.sync="s_process" :status="getStatus(id)" :variants="variants" />
    </div>
    <n-button @click="$var('modalStatus', true)">Добавить статус</n-button>

    <process-modal-status v-if="$var('modalStatus')" :status="$var('modalStatus')" :process="process" :statuses="variants.values.statuses"
                          @update:process="(v) => $emit('update:process', v)" @close="$var('modalStatus', false)" />
  </div>
</template>

<script>
import ProcessModalStatus from './ModalStatus.vue'
import Status from './Status.vue'
import ProcessActors from './Actors.vue'

export default {
  name: 'Process',
  components: { ProcessActors, Status, ProcessModalStatus, },
  props: {
    variants: { type: Object, required: true, }, // { actors, actions, events, }
    process: { type: Object, default: () => ({ start: '', actions: {}, }), },
    title: { type: String, default: '', },
  },
  data() {
    return {
    }
  },
  computed: {
    s_process: {
      get() {
        return this.process
      },
      set(v) {
        this.$emit('update:process', v)
      },
    },
  },
  methods: {
    getStatus(id) {
      return this.variants?.values?.statuses[id] || {}
    },
  },
}
</script>

<style lang="scss" scoped>
.process {
  --n-modal-width: 600px;
  position: relative;
  width: 100%;
  min-height: 100px;

  h3 {
    margin-top: 0;
  }
  &+.process h3 {
    margin-top: 20px;
  }

  .button {
    font-size: .8em;
  }

  .statuses {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    flex-direction: column;
  }


  .has-tools {
    position: relative;
    padding-right: 30px;
    display: inline-block;
    &:hover .tools {
      opacity: 1;
    }
  }
  .tools {
    position: absolute;
    right: 0;
    top: 0;
    font-size: .7rem;
    opacity: .3;
    transition: opacity .3s;
  }
}
</style>
