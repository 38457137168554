<template>
  <div class="block-actors">
    <div class="block-table">
      <div class="title-block row">
        <div class="divide">
          <div>Участники</div>
          <div v-if="hasSub && departments.length > 1" class="tools">
            <select>
              <option v-for="task in tasks" :key="task">{{ task }}</option>
            </select>
          </div>
        </div>
      </div>

      <div v-for="actor in actors" :key="actor.id" class="row item">
        <div class="editable title">{{ actor.title }}:</div>
        <div v-for="(user, index) in actor.users" :key="user.title" class="users">
          <div>{{ user.title }}</div>
          <template v-if="index < actor.users.length-1">,&nbsp;</template>
        </div>
        <div v-if="!actor.users.length" class="empty">Не назначено</div>
      </div>

      <div class="add-button row" @click="$var('addDepartment', true)">
        <n-icon icon="plus" /> роль
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'BlockActors',
  props: {
    departments: { type: Array, required: true, },
    actors: { type: Array, required: true, },
    hasSub: { type: Boolean, required: true, },
  },
  data() {
    return {
    }
  },
  computed: {
    tasks() {
      return [
        'Основная задача',
        ...$n.map(this.departments, (i) => i.title),
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.block-actors {
  .table {
    .title {
      margin-right: 7px;
    }
    .empty {
      font-size: .9em;
      opacity: .5;
      font-style: italic;
    }
    .users {
      display: flex;
      font-size: .9em;
    }
    .tools {
      select {
        outline: none;
        border: none;
        background: transparent;
        text-align: right;
        font-size: .9em;
      }
    }
  }
}
</style>
