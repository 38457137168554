import files from './files'
import table from './table'
import tasks from './tasks'
import filesystem from './filesystem'
import notify from './notify'

export default {
  files: {
    ...files,
    modules: {
      table,
    },
  },
  tasks,
  filesystem,
  notify,
}
