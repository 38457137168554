<template>
  <div class="page-settings-users">
    <n-table :data="users" :columns="columns" :loading="$var('load')">
      <template #accesses="{ item, }">
        <div v-for="acs in item.accessesGlobal" :key="acs.id">
          {{ roles[acs.name] }}
        </div>
      </template>
      <template #tools="{ item, }">
        <n-button v-if="$app.auth.can('admin')" icon="key" @click="$var('user', item)">редактировать</n-button>
      </template>
    </n-table>

    <user-modal v-if="$var('user')" :user="$var('user')" editable @reload="load" @close="$var('user', false)" />
    <!--    <n-modal v-if="$var('user')" @close="$var('user', false)">-->
    <!--      <c-accesses :model="$var('user')" type="user" @reload="load" />-->
    <!--    </n-modal>-->
  </div>
</template>

<script>
import UserModal from 'components/accesses/UserModal'

export default {
  name: 'PageSettingsUsers',
  components: { UserModal, },
  data() {
    return {
      users: [],
      columns: [
        { name: 'username', title: 'Логин', },
        { name: 'fullName', title: 'Имя', },
        { name: 'accesses', title: 'Полномочия', },
        { name: 'tools', title: '', },
      ],
      roles: {
        admin: 'Администратор',
        worker: 'Работа с файловой системой',
        archive: 'Архивариус',
      },
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.company.users.get().with('accessesGlobal').view('all').then((response) => {
        this.users = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-settings-users {

}
</style>
