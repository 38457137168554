<template>
  <div class="tab2">
    <n-loader :loading="$var('load')" />

    <block-parent :project-id="projectId" :parent-id="parentId" @update:parentId="(v) => $emit('update:parentId', v)" />

    <block-departments :departments="departments" :has-sub="hasSub"
                       @update:departments="(v) => $emit('update:departments', v)" @update:hasSub="(v) => $emit('update:hasSub', v)" />

<!--    <block-actors :actors="actors" :departments="departments" :has-sub="hasSub" @update:actors="(v) => $emit('update:actors', v)" />-->


    <div class="footer-buttons">
      <n-button @click="$emit('prev')">Назад</n-button>
      <n-button color="primary" @click="$emit('next')">Далее</n-button>
    </div>
  </div>
</template>

<script>
import BlockDepartments from './tab2/BlockDepartments'
import BlockParent from './tab2/BlockParent'
import BlockActors from './tab2/BlockActors'

export default {
  name: 'Tab2',
  components: { BlockActors, BlockParent, BlockDepartments, },
  props: {
    projectId: { type: Number, required: true, },
    departments: { type: Array, required: true, },
    actors: { type: Array, required: true, },
    hasSub: { type: Boolean, required: true, },
    parentId: { type: Number, default: null, },
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.tab2 {
  position: relative;
  padding-top: 10px;
  min-height: 200px;

  .table {
    .parent-task {
      cursor: pointer;
    }
  }
}
</style>
