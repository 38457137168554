
export default {
  take(self, doc) {
    $api.fs.documents.take(doc.id).then(() => {
      const link = document.createElement('a')
      link.href = doc.src
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      self.load()
    })
  },
  retrieve(self, document) {
    self.$var('retrieve', document)
  },
  return(self, document) {
    self.$var('load', true)
    $api.fs.documents.return(document.id).then(() => {
      self.load()
    }).catch(() => {
      self.$var('load', false)
    })
  },
  renameDocument(self, document) {
    self.$var('renameDocument', document)
  },
  renameFolder(self, folder) {
    self.$var('renameFolder', folder)
  },
  revision(self, folder) {
    self.$var('revision', folder)
  },
  delete(self, item) {
    self.$var('load', true)
    $api.fs[item.model+'s'].delete(item.id).then(() => {
      self.load()
      self.$emit('reload')
      self.selected = self.opened
    }).catch(() => {
      self.$var('load', false)
    })
  },
  addFolder(self, folder) {
    self.$var('addFolder', folder)
  },
  addDocument(self, folder) {
    self.$var('addDocument', folder)
  },
}
