<template>
  <div class="n-force-auth">
    <n-select title="Просмотр от лица" :data="users" :value.sync="user" option-title="fullName" selected-title="fullName" item-value="id" />
    <div class="loader-wrapper">
      <n-loader :loading="loading" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NForceAuth',
  props: {
    apiUsers: { type: Function, default: () => $api.company.users.get(), },
    apiLogin: { type: Function, default: (user) => $api.auth.login(user.username, '123123'), },
  },
  data() {
    return {
      users: [],
      loading: false,
    }
  },
  computed: {
    user: {
      get() {
        return $app.auth.user()
      },
      set(v) {
        this.loading = true
        this.apiLogin(v).then((response) => {
          $app.auth.login({
            ...response.data.content,
            redirect: false,
          })
          window.location.reload()
        }).finally(() => {
          this.loading = false
        })
      },
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.apiUsers().then((response) => {
        this.users = response.data.content
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.n-force-auth {
  position: relative;
  .n-select::v-deep {
    .n-popup-content {
      color: var(--text-color);
    }
  }
  .loader-wrapper {
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: 0;
    overflow: hidden;
    &::v-deep {
      .n-icon {
        display: none;
      }
    }
  }
}
</style>
