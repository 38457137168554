<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  html() {
    return {
      title: 'King Filesystem',
      meta: [
        { mid: 'theme-color', name: 'theme-color', content: 'rgb(120, 178, 253)', },
      ],
    }
  },
  beforeCreate() {
    if ($config('auth.method') === 'sso') {
      $app.api.config({
        withCredentials: true,
      })
      if (!$app.auth.loggedIn()) {
        $api.auth.info().then((response) => {
          if (response.data.content.user?.id) {
            $app.auth.login(response.data.content)
          } else {
            alert('Пользователь не найден!')
          }
        })
      }
    } else {
      $app.auth.init()
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import '~nast-ui/styles/global';

@include initialize((
    default: (
        colors: (
            primary: [ #1a90d0, #fff, ],
            secondary: #000,
            warning: [ #dcb626, #fff, ],
            default: [ #efefef, #686868, ],
        ),
        typography: (
            header-font: 'Roboto, sans-serif',
            text-font: '400 1em Roboto, sans-serif',
            h1-font: '300 2em var(--header-font)',
            h2-font: '500 1.5em var(--header-font)',
            h3-font: '400 1.2em var(--header-font)',
            h4-font: '400 1.1em var(--header-font)',
            text-color: #444,
        ),
    ),
));

html {
}

.primary-bg { background: var(--primary); color: var(--primary-text); }
.secondary-bg { background: var(--secondary); color: var(--secondary-text); }
.success-bg { background: var(--success); color: var(--success-text); }
.danger-bg { background: var(--danger); color: var(--danger-text); }
.warning-bg { background: var(--warning); color: var(--warning-text); }
.default-bg { background: var(--default); color: var(--default-text); }

.primary-text { color: var(--primary); }
.secondary-text { color: var(--secondary); }
.success-text { color: var(--success); }
.danger-text { color: var(--danger); }
.warning-text { color: var(--warning); }
.default-text { color: var(--default-text); }

.form-columns {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  &>*:not(:last-child) {
    margin-right: 20px;
  }
}

.table-tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.empty-message {
  font-style: italic;
  padding: 15px 0;
  font-size: .9em;
}

p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

.double-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.block-table {
  position: relative;
  margin: 0 0 20px 0;
  border: 1px solid #ddd;
  &>*:last-child {
    border-bottom: none !important;
    margin-bottom: 0;
  }

  .editable {
    color: #000;
    border-bottom: 1px dotted #000;
    cursor: pointer;
  }
  .divide {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-block {
    background: rgba(127, 127, 127, .05);
    font-weight: bold;
    .sub {
      font-weight: normal;
      font-size: .8em;
    }
    .tools {
      font-size: .9em;
      font-weight: normal;
    }
  }

  .item {
    font-size: .9em;
    line-height: 1;
    display: flex;
    align-items: center;
    .content {

    }
    .tools {
      display: inline-block;
      margin-left: 7px;
      font-size: .8em;
      opacity: .3;
      transition: opacity .3s;
      &:hover {
        opacity: 1;
      }
    }
  }
  .row {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  .add-button {
    font-size: .8em;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    color: var(--primary);
    cursor: pointer;
    opacity: .6;
    &:hover {
      opacity: 1;
    }
    .n-icon {
      margin-right: 4px;
      font-size: .9em;
    }
  }
}

* {
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    margin-left: -10px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    background: rgba(127, 127, 127, .5) content-box;
    border: solid transparent;
    border-width: 1px 2px;
    border-radius: var(--border-radius);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(127, 127, 127, .1);
    border: 1px solid rgba(127, 127, 127, .15);
  }
}
</style>
