<template>
  <n-modal :loading="$var('load')" class="modal-edit-template" @close="$emit('close')">
    <div v-if="!isNew" class="remove-block">
      <n-button round icon="trash" @click="remove" />
    </div>

    <h3 v-if="isNew">Создание статуса</h3>
    <h3 v-else>Редактирование статуса</h3>

    <n-form @submit="submit">
      <n-items>
        <n-input title="Название" v-bind="$form.input('title')" />
      </n-items>
      <br />

      <n-divide>
        <n-button>Отмена</n-button>
        <n-button color="success" type="submit">Сохранить</n-button>
      </n-divide>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalEditStatus',
  props: {
    item: { type: [ Object, Boolean, ], required: true, }, // { title: '', }
  },
  data() {
    return {
    }
  },
  computed: {
    isNew() {
      return this.item === true
    },
  },
  created() {
    if (this.isNew) {
      this.$form.init({
        title: '',
      })
    } else {
      this.$form.init({
        title: this.item.title,
      })
    }
  },
  methods: {
    submit() {
      this.$var('load', true)
      const data = {
        title: this.$form.get('title'),
      }
      const api = this.isNew ? $api.process.statuses.create(data) : $api.process.statuses.edit(this.item.id, data)
      api.then((result) => {
        this.$emit('submit')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
    remove() {
      this.$var('load', true)
      $api.process.statuses.remove(this.item.id).then(() => {
        this.$emit('submit')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.modal-edit-template {
  --n-modal-width: 600px;
  .processes {
    margin: 40px 0;
    &>*:nth-child(2) {
      margin-top: 40px;
    }
  }

  .remove-block {
    float: right;
  }
}
</style>
