<template>
  <n-modal class="modal-create-task" :loading="$var('load') || $var('loadDepartments')" @close="$emit('close')">
    <n-stepper :data="tabs" state />

    <div class="content">
      <tab1 v-if="step === '1' && template" :template.sync="template" @next="changeTab('2')" />
      <tab2 v-if="step === '2' && template" :project-id="projectId" :departments.sync="departments" :actors="actors"
            :has-sub.sync="hasSub" :parent-id.sync="parentId" @prev="changeTab('1')" @next="changeTab('3')" />
      <tab3 v-if="step === '3' && template" :template="template" :task.sync="task" @prev="changeTab('2')" @next="changeTab('4')" @submit="submit" />
      <tab4 v-if="step === '4' && template" :process.sync="process" :subprocess.sync="subprocess" @prev="changeTab('3')" @submit="submit" />
    </div>
  </n-modal>
</template>

<script>
import Tab1 from './Tab1'
import Tab2 from './Tab2'
import Tab3 from './Tab3'
import Tab4 from './Tab4'

export default {
  name: 'ModalCreateTask',
  components: { Tab1, Tab2, Tab3, Tab4, },
  data() {
    return {
      template: null,
      hasSub: false,
      departments: [],
      task: {},
      process: null,
      subprocess: null,
      actors: [
        { id: '1', title: 'Инициатор задачи', users: [ { type: 'person', title: 'Вы', }, { type: 'person', title: 'Автор задачи', }, ], },
        { id: '2', title: 'Исполнители', users: [ { type: 'persons', title: 'Руководители выбранных отделов', }, ], },
        { id: '3', title: 'Наблюдатели', users: [], },
      ],

      tabs: [
        { name: '1', title: 'Шаблон', },
        { name: '2', title: 'Структура', },
        { name: '3', title: 'Описание', },
        { name: '4', title: 'Процессы', },
      ],
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.query.step
      },
      set(step) {
        if (this.step !== step) {
          this.$router.push({
            ...this.$route,
            query: { ...this.$route.query, step, },
          })
        }
      },
    },
    parentId: {
      get() {
        return this.$route.query.parentId * 1 || null
      },
      set(value) {
        if (this.parentId !== value) {
          this.$router.push({
            ...this.$route,
            query: { ...this.$route.query, parentId, },
          })
        }
      },
    },
    templateId: {
      get() {
        return this.$route.query.template * 1
      },
      set(template) {
        if (this.templateId !== template) {
          this.$router.push({
            ...this.$route,
            query: { ...this.$route.query, template, },
          })
        }
      },
    },
    projectId() {
      return this.$route.params.projectId * 1
    },
  },
  watch: {
    templateId(value) {
      if (value !== this.template.id) {
        this.load()
      }
    },
    template(value) {
      if (value.id) {
        this.templateId = value.id
        this.process = value.process
        this.subprocess = value.subprocess
        this.hasSub = !!value.subprocess
        this.task = {
          title: value.title,
        }
        // this.loadDepartments()
      }
    },
  },
  created() {
    if (!this.templateId) {
      this.templateId = 0
    }
    this.load()
  },
  methods: {
    load() {
      if (this.templateId === 0) {
        this.template = { id: 0, }
      } else {
        this.$var('load', true)
        $api.process.get(this.templateId).then((response) => {
          this.template = response.data.content
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    loadDepartments() {
      const ids = this.template.data.task.departments
      if (ids) {
        this.$var('loadDepartments', true)
        const filter = ids ? { id: 'in:'+ids.join(','), } : {}
        $api.company.departments.get().filter(filter).then((response) => {
          this.departments = response.data.content
        }).finally(() => {
          this.$var('loadDepartments', false)
        })
      }
    },
    changeTab(name) {
      this.step = name
    },
    submit() {
      this.$var('load', true)
      if ($n.size(this.task.newFiles)) {
        const apis = $n.map(this.task.newFiles, (file) => $api.base.files.create({ file, }))
        Promise.all(apis).then((responses) => {
          this.save($n.map(responses, (r) => r.data.content.id))
        })
      } else {
        this.save()
      }
    },
    save(newFiles) {
      const data = {
        task: { ...this.task, newFiles, },
        projectId: this.projectId,
        parentId: this.parentId,
        hasSub: this.hasSub,
        departments: $n.map(this.departments, (d) => d.id),
        process: this.process,
        subprocess: this.subprocess,
      }
      $api.fs.tasks.create(data).then((response) => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-create-task {
  --n-modal-width: 800px;

  .content {
    margin-top: 20px;
  }

  &::v-deep {
    .footer-buttons {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
