<template>
  <n-card class="modal-task-card" :loading="$var('load')" @close="$emit('close')">
    <n-button icon="arrow-left" round flat style="margin-bottom: 10px;" @click="$emit('close')"></n-button>
    <div class="edit">
      <n-button v-if="can('edit')" icon="pen" flat round @click="$var('edit', true)" />
    </div>
    <div class="status-block">
      <div class="status" :class="['primary-bg']">{{ task.status.title }}</div>
    </div>
    <div class="title">
      {{ task.title }}
    </div>

    <task-actions :task="task" :statuses="statuses" :can="can" @reload="reload" />

    <div class="approvers">
      <n-table :data="approvers" :columns="approversColumns">
        <template #actor="{ item }">
          <span>{{ item.actor.title }}</span>
          <span v-if="statuses[item.statusId]" class="status">({{ statuses[item.statusId].title }})</span>
        </template>
        <template #users="{ item }">
          <div v-for="user in item.users" :key="user.id">
            <span v-if="user.position">[{{ user.position }}]</span> {{ user.title }}
          </div>
        </template>
        <template #sign="{ item }">
          <template v-for="user in item.users">
            <template v-for="sign in task.signs">
              <span v-if="sign.statusId+'' === item.statusId && sign.userId === user.id" :key="sign.id">
                <span v-if="sign.isApproved">
                  Согласовано
                </span>
                <span v-else>
                  Отказано
                  <div v-html="$app.secure.clean(sign.comment)" />
                </span>
                <span class="date">{{ $app.date.format(sign.resolvedAt) }}</span>
              </span>
            </template>
          </template>
          <div v-if="task.status.id+'' === item.statusId">
            Ожидание
          </div>
        </template>
      </n-table>
    </div>

    <div class="content">
      <div class="desc">
        <div v-html="$app.secure.clean(task.content)" />
        <div class="attachments">
          <div v-for="file in task.files" :key="file" class="file">
            <a :href="file.src" target="_blank" download>
              <n-icon icon="file" /> {{ file.name }}
            </a>
          </div>
        </div>
        <a class="download-task-file" :href="host+'v1/tasks/'+task.id+'/file'">
          <n-icon icon="file-word" />&nbsp;&nbsp;Скачать файл задания
        </a>
      </div>
    </div>

    <div class="relations">
      <div v-if="task.parent" class="item">
        [Основная задача] <n-link :to="taskLink(task.parent.id)">{{ task.parent.title }}</n-link>
      </div>
      <div v-for="item in task.children" :key="item.id" class="item">
        [Подзадача] <n-link :to="taskLink(item.id)">{{ item.title }}</n-link>
      </div>
      <n-link :to="newTaskLink">Создать подзадачу</n-link>
    </div>

    <n-button v-if="can('files') || can('review')" @click="$var('selectFiles', true)">Прикрепить файлы к задаче</n-button>

    <block-files :task="task" :can="can" />
    <block-task-comments :task="task" />

    <template v-if="can('delete')">
      <br />
      <hr />
      <br />
      <n-button color="danger" @click="remove">Удалить задачу</n-button>
    </template>

    <modal-status v-if="$var('modalStatus')" :task-id="task.id" :statuses="task.actions.push" @reload="reload" @close="$var('modalStatus', false)" />
    <modal-edit-task v-if="$var('edit')" :task="task" @close="$var('edit', false)" @reload="reload" />
    <modal-select-files v-if="$var('selectFiles')" :task="task" @close="$var('selectFiles', false)" @reload="reload" />
  </n-card>
</template>

<script>
import ModalEditTask from './ModalEditTask'
import ModalSelectFiles from './ModalSelectFiles'
import ModalStatus from './ModalStatus'
import BlockTaskComments from './BlockTaskComments'
import BlockFiles from './blockFiles/Index'
import TaskActions from './Actions.vue'

export default {
  name: 'ModalTaskCard',
  components: { TaskActions, BlockFiles, BlockTaskComments, ModalStatus, ModalSelectFiles, ModalEditTask, },
  data() {
    return {
      host: $config('api.servers.default'),
      task: { actions: {}, status: {}, },
      statuses: {},
      approversColumns: [
        { name: 'actor', title: 'Роль', },
        { name: 'users', title: 'Согласующие', },
        { name: 'sign', title: 'Статус', },
      ],
    }
  },
  computed: {
    newTaskLink() {
      return { name: 'tasks.list', params: { projectId: this.projectId, }, query: { new: null, parentId: this.task?.id, }, }
    },
    taskId() {
      return this.$route.query.task
    },
    projectId() {
      return this.$route.params.projectId
    },
    signs() {
      return $n.reduce(this.task.signs, (result, i) => {
        result['' + i.type + i.userId] = i
        return result
      }, {})
    },
    approvers() {
      const result = []
      if (this.task.process) {
        $n.each(this.task.process.actions, (statusInfo, statusId) => {
          $n.each(statusInfo, (actions, actorName) => {
            $n.each(actions, (action) => {
              if (action.do.type === 'approve') {
                result.push({
                  statusId,
                  actor: {
                    id: this.task.process.actors[actorName].id,
                    title: this.task.process.actors[actorName].title,
                  },
                  users: this.task.processInfo.users[actorName],
                })
              }
            })
          })
        })
      }
      return result
    },
  },
  watch: {
    taskId() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    can(name) {
      return $app.auth.can('task_'+name, this.task.id)
    },
    reload() {
      this.load()
      this.$emit('reload')
    },
    taskLink(id = undefined) {
      return { ...this.$route, query: { ...this.$route.query, task: id, }, }
    },
    load() {
      this.$var('load', true)
      const api = $api.fs.tasks.get(this.taskId)
        .with('children').with('parent').with('status').with('actions').with('folder').with('processInfo').with('signs').with('files')
      const apiGrants = $api.auth.info()
      const apiStatuses = $api.process.statuses.get().key('id')
      Promise.all([ api, apiGrants, apiStatuses, ]).then((responses) => {
        this.task = responses[0].data.content
        $app.auth.grants(responses[1].data.content.grants)
        this.statuses = responses[2].data.content
        this.loadAccepters(this.task.processInfo.byAction)
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadAccepters(actions) {
      if (actions) {
        const types = [ 'accept', 'review', 'approve', 'files', 'observe', ]
        const api = $n.reduce(types, (result, type) => {
          if (actions[type]) {
            result.push({
              promise: $api.process.users(this.taskId, actions[type]),
              type,
            })
          }
          return result
        }, [])
        this.$var('loadActors', true)
        $n.promiseObjects(api).then((response) => {
          $n.each(response, (r) => {
            this[r.type+'Users'] = r.response.data.content
          })
        }).finally(() => {
          this.$var('loadActors', false)
        })
      } else {
        this.$var('loadActors', false)
      }
    },
    remove() {
      this.$var('load', true)
      $api.fs.tasks.remove(this.taskId).then((response) => {
        this.$emit('close')
        this.$emit('reload')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-task-card {
  --n-modal-width: 500px;
  width: 100%;
  margin: 0 0 0 20px !important;
  height: calc(100vh - 55px - 20px);
  overflow-y: auto;

  &::v-deep {
    &>.n-wrapper {
      --n-modal-width: 500px;
      top: 0 !important;
      left: auto !important;
      margin: 0 0 0 50% !important;
      width: 50% !important;

      &>.n-card {
        margin: 0;
        height: 100vh;
        overflow-y: auto;
        &>.content>main {
          padding: 25px 30px;
        }
      }
    }
  }

  .approvers {
    font-size: .9em;
    .status {
      opacity: .8;
      font-size: .8em;
      display: inline-block;
      margin-right: 10px;
    }
    .date {
      opacity: .8;
      font-size: .8em;
      display: inline-block;
    }
  }

  .edit {
    float: right;
  }
  .status-block {
    display: flex;
    align-items: center;
    .status {
      display: inline-block;
      padding: .5em 1em;
      font-size: .8em;
      line-height: 1;
      border-radius: 5px;
    }
    .change {
      margin-left: 8px;
      display: inline-block;
      font-size: .8em;
      --link-color: #999;
    }
  }

  .actors-block {
    position: relative;
    background: rgba(26, 144, 208, 0.04);
    padding: 20px 20px;
    .actors {
      margin-top: 15px;
      font-size: .9em;
      &:first-child {
        margin-top: 0;
      }
      h3 {
        font-size: .9em;
        opacity: .5;
        font-weight: 500;
        margin: 0;
      }

      .actors {
        margin: 0 0 10px;
      }
    }
  }

  .title {
    font-size: 1.3em;
    margin: 3px 0 10px;
  }
  .relations {
    font-size: .9em;
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 20px 0 30px;
    border: solid #ddd;
    border-width: 1px 0;
    .desc {
      width: calc(100% - 250px);
      font-size: .9em;
      padding: 20px 20px 20px 0;
      position: relative;
    }
    .attachments {
      margin-top: 10px;
      min-height: 20px;
      .file {
        --link-color: #999;
        margin: 3px 0;
      }
    }
    .download-task-file {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}
</style>
