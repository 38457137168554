<template>
  <div class="fs-page">
    <fs-tabs :tabs.sync="tabs" :active.sync="activeTab" />
    <fs-breadcrumbs :folders="folders" :opened.sync="opened" :favorite.sync="favorite"
                    @createWindow="$emit('createWindow', opened)" @removeWindow="$emit('removeWindow')" />
    <fs-tools :opened="opened" :selected="selected" :category="category" :context-event="contextEvent" @action="action" />
    <div class="nav-table-wrapper">
      <fs-nav :folders="folders" :roots="roots" :opened.sync="opened" :loading="loading" />
      <fs-table :data="data" :opened="opened" :selected="selected" :loading="$var('load')"
                @contextmenu="onContextmenu" @click="click" @dblclick="dblclick" @drop="onDrop" />
    </div>
    <fs-details :selected="selected" />

    <modal-add-folder v-if="$var('addFolder')" :folder="$var('addFolder')" :is-private="category === 'documents'"
                      @reload="reload" @close="$var('addFolder', false)" />
    <modal-add-document v-if="$var('addDocument')" :folder="$var('addDocument')" :is-private="category === 'documents'" :file="draggedNewFile"
                        @reload="load" @close="$var('addDocument', false); draggedNewFile = null" />
    <modal-create-revision v-if="$var('revision')" :folder="$var('revision')"
                           @reload="reload" @close="$var('revision', false)" />
    <modal-rename-folder v-if="$var('renameFolder')" :folder="$var('renameFolder')"
                         @reload="reload" @close="$var('renameFolder', false)" />
    <modal-rename-document v-if="$var('renameDocument')" :document="$var('renameDocument')" :is-private="category === 'documents'"
                           @reload="load" @close="$var('renameDocument', false)" />
    <modal-retrieve-document v-if="$var('retrieve')" :document="$var('retrieve')"
                             @reload="load" @close="$var('retrieve', false)" />
  </div>
</template>

<script>
import actions from './actions'
import FsTabs from './Tabs'
import FsTools from './Tools'
import FsBreadcrumbs from './Breadcrumbs'
import FsNav from './Nav'
import FsTable from './Table'
import FsDetails from './details/Index.vue'
import ModalAddFolder from './modals/AddFolder.vue'
import ModalAddDocument from './modals/AddDocument.vue'
import ModalCreateRevision from './modals/CreateRevision.vue'
import ModalRenameFolder from './modals/RenameFolder.vue'
import ModalRenameDocument from './modals/RenameDocument.vue'
import ModalRetrieveDocument from './modals/RetrieveDocument.vue'

export default {
  name: 'FsPage',
  components: { ModalRetrieveDocument, ModalRenameDocument, ModalRenameFolder, ModalCreateRevision, ModalAddDocument, ModalAddFolder,
    FsDetails, FsTable, FsNav, FsBreadcrumbs, FsTools, FsTabs, },
  props: {
    folders: { type: Object, required: true, },
    roots: { type: Array, required: true, },
    favorites: { type: Array, default: () => [], },
    window: { type: Object, required: true, }, // { tabs: [ {folder}, ], active: 0, }
    loading: { type: Boolean, default: false, },
    updated: { type: Object, default: null, },
  },
  data() {
    return {
      opened: null,
      selected: null,
      contextEvent: null,
      draggedNewFile: null,
      data: [],
      api: {
        data: (id) => $api.fs.folders.getData(id),
        favorites: () => $api.fs.folders.getDataFavorites().query('ids', $app.store.state('filesystem.favorites')),
        manager: () => $api.fs.folders.getDataManager(),
      },
    }
  },
  computed: {
    category() {
      if (!this.opened) {
        return ''
      }
      if (this.opened.parentIds[0]) {
        return this.folders[this.opened.parentIds[0]]?.type
      } else {
        return this.opened.type
      }
    },
    tabs: {
      get() {
        return this.window.tabs
      },
      set(tabs) {
        this.$emit('update:window', { tabs, active: this.window.active, })
      },
    },
    activeTab: {
      get() {
        return this.window.active
      },
      set(active) {
        // добавил таймут для того чтобы не было конфликтов при одновременном сохранении с tabs
        setTimeout(() => {
          this.$emit('update:window', { tabs: this.window.tabs, active, })
        }, 1)
      },
    },
    favorite: {
      get() {
        return this.favorites.includes(this.opened?.id)
      },
      set(value) {
        const favorites = this.favorites
        if (value) {
          favorites.push(this.opened.id)
        } else {
          const index = this.favorites.indexOf(this.opened.id)
          if (index > -1) {
            favorites.splice(index, 1)
          }
        }
        this.$emit('update:favorites', favorites)
      },
    },
  },
  watch: {
    opened() {
      this.selected = this.opened
      this.load()

      const tabs = $n.clone(this.tabs)
      tabs[this.activeTab] = this.opened
      this.tabs = tabs
    },
    activeTab() {
      this.opened = this.tabs[this.activeTab]
    },
    updated() {
      this.load()
    },
    folders() {
      this.setHome()
      this.load()
    },
  },
  created() {
    if ($n.size(this.folders)) {
      this.setHome()
    }
  },
  methods: {
    setHome() {
      if ($n.size(this.tabs) === 0) {
        const folder = this.folders[Object.keys(this.folders)[0]]
        this.tabs = [ folder, ]
        this.opened = folder
      } else if (!this.opened) {
        this.opened = this.tabs[this.activeTab]
      }
    },
    load() {
      if (this.opened) {
        this.$var('load', true)
        const api = this.api[this.category] || this.api.data
        api(this.opened.id).then((response) => {
          const content = response.data.content
          this.data = [].concat(content.folders||[], content.files||[])
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    reload() {
      this.$emit('reload')
      this.load()
    },
    action(type, item) {
      actions[type](this, item)
    },
    onContextmenu(event, item) {
      event.preventDefault()
      event.stopPropagation()
      this.selected = item
      this.contextEvent = event
    },
    click(event, item) {
      this.selected = item || this.opened
    },
    dblclick(event, item) {
      this.opened = this.folders[item.id]
    },
    onDrop(event, item) {
      if (item) {
        const api = item.model === 'document' ? $api.fs.documents.edit : $api.fs.folders.edit
        const data = { folderId: this.opened.id, }
        this.$var('load', true)
        api(item.id, data).then(() => {
          if (item.model === 'folder') {
            this.$emit('reload', 'all')
          } else {
            this.$emit('reload', 'data')
          }
        }).catch(() => {
          this.$var('load', false)
        })
      } else {
        this.draggedNewFile = event.dataTransfer.files[0]
        this.$var('addDocument', this.opened)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-page {
  --fs-border-color: #ddd;
  margin-top: 20px;
  .nav-table-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: stretch;

    .fs-table {
      width: calc(100% - 250px);
    }
    .fs-nav {
      width: 250px;
    }
  }
}
</style>
