<template>
  <n-modal class="modal-refuse" :loading="$var('load')" @close="$emit('close')">
    <h3>Согласование</h3>
    <n-form @submit="submit">
      <n-items>
        <n-textarea title="Комментарий" v-bind="$form.input('comment')" />
        <n-button color="primary" wide type="submit">Отказываю</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalRefuse',
  props: {
    task: { type: Object, required: true, },
  },
  created() {
    this.$form.init({
      comment: '',
    })
    this.$form.rules({
      comment: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          ...this.$form.get(),
          isApproved: false,
          type: 'approve',
        }
        $api.fs.tasks.sign(this.task.id, data).then((response) => {
          this.$emit('reload')
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-refuse {
  --n-modal-width: 600px;

}
</style>
