<template>
  <div class="tab1">
    <n-loader :loading="$var('load')" />
    <div class="templates">
<!--      <div v-if="!$var('load')" class="template" :class="[ { active: !$form.get('active.id'), }, ]" @click="$emit('update:template', 0)">-->
<!--        Без шаблона-->
<!--      </div>-->
      <div v-for="item in templates" :key="item.id"
           class="template" :class="[ { active: item.id === $form.get('active.id'), }, ]" @click="select(item)">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab1',
  props: {
    template: { type: Object, default: null, },
  },
  data() {
    return {
      templates: [],
    }
  },
  watch: {
    template(item) {
      this.$form.set('active', item)
      this.$form.init({
        active: item,
      })
    },
  },
  created() {
    this.load()
    this.$form.init({
      active: this.template,
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.process.get().then((response) => {
        this.templates = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    select(item) {
      this.$emit('update:template', item)
      this.$emit('next')
    },
  },
}
</script>

<style lang="scss" scoped>
.tab1 {
  position: relative;
  padding-top: 10px;
  min-height: 200px;

  .templates {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .template {
    padding: 10px;
    height: 100px;
    border: 1px solid var(--border-color);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      border: 1px solid var(--primary-t-7);
      background: var(--primary-t-9);
    }
    &.active {
      border: 1px solid var(--primary-d-1);
      background: var(--primary);
      color: var(--primary-text);
    }
  }
}
</style>
