<template>
  <n-modal class="modal-field" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Поле" :data="Object.values(ui[model][columnsType])" v-bind="$form.input('uiField', 'select')" :update:value="select" />
        <n-input title="Название поля" v-bind="$form.input('title')" />
        <n-form-item v-if="$form.get('uiField.type') === 'count'" title="Фильтры">
          <!--          <c-filters :builder="filterBuilder" :info="titles" />-->
        </n-form-item>
        <n-button type="submit" color="success" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
import reduce from 'lodash/reduce'
import { getBuilder, } from '../../utils'
import CFilters from '../filters/Index'

export default {
  name: 'ModalColumn',
  components: { CFilters, },
  props: {
    column: { type: Object, required: true, }, // { name: '', title: '', type: '', }
    params: { type: Object, required: true, },
    columns: { type: Array, required: true, },
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    columnsType: { type: String, default: 'fields', }, // fields | numbers | dates
  },
  computed: {
    isNew() {
      return !this.column.name
    },
  },
  created() {
    if (this.isNew) {
      this.$form.init({
        uiField: null,
        title: '',
      })
    } else {
      this.$form.init({
        uiField: this.column.uiField,
        title: this.column.title,
      })
    }
    this.$form.rules({
      uiField: [ 'required', ],
      title: [ 'required', ],
    })
  },
  methods: {
    select(value) {
      this.$form.set('uiField', value)
      this.$form.set('title', value.title)
    },
    submit() {
      if (this.$form.check()) {
        const builder = getBuilder(this.params)
        const uiField = this.$form.get('uiField')
        let name
        let callback
        if (uiField.type !== 'count') {
          name = uiField.id
        } else if (uiField.id === this.column.uiField?.id) {
          name = this.column.name
          callback = undefined
        } else {
          name = uiField.id + ':' + new Date().getTime()
          callback = undefined
        }
        const newColumn = {
          name,
          title: this.$form.get('title'),
          type: uiField.type,
        }
        
        builder.fields(name, callback)

        let itWas = false
        const columns = reduce(this.columns, (result, column) => {
          if (column.name === this.column.name || column.name === name) {
            if (!itWas) {
              itWas = true
              result.push(newColumn)
            }
          } else {
            result.push(column)
          }
          return result
        }, [])

        if (this.isNew && !itWas) {
          columns.push(newColumn)
        }
        
        this.$emit('update:params', builder.params())
        this.$emit('update:columns', columns)
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-field {
}
</style>
