<template>
  <div class="layout-main">
    <main-sidebar />
    <div class="content-wrapper">
      <main-header />
      {{ $app.store.state('notify.messages') }}
      <div class="main-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from './Header'
import MainSidebar from './Sidebar'

export default {
  name: 'MainLayout',
  components: { MainSidebar, MainHeader, },
  data: () => ({
  }),
  created() {
    if ($config('auth.method') !== 'sso') {
      $app.auth.needAuth(true)
    }
    $app.store.action('notify.start')
  },
}
</script>

<style lang="scss">
  .layout-main {
    display: grid;
    grid-template-columns: 100px 1fr;
    min-height: 100vh;

    .main-content {
      display: grid;
      grid-template-rows: 1fr;
      &>* {
        padding: 0 20px;
      }
    }

    .content-wrapper {
      display: grid;
      grid-template-rows: auto 1fr;
    }
  }
</style>
