<template>
  <n-modal class="modal-select-files" :loading="$var('load')" @close="$emit('close')">
    <h3>Выбрать файлы</h3>
    <n-form @submit="submit">
      <n-items>
        <n-select title="Папка в файловой системе" :data="folders" v-bind="$form.input('folder', 'select')"
                  item-value="id" />
        <n-select v-if="$form.get('folder')" title="Выбор" :data="types" :value="type" @update:value="changeType" />
        <n-select v-if="type.value === 'custom'" title="Файлы" :data="documents"
                  v-bind="$form.input('documents', 'select')" item-value="id" />
        <n-button type="submit">Загрузить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalSelectFiles',
  props: {
    task: { type: Object, default: () => ({}), },
  },
  data() {
    return {
      folders: [],
      type: { value: 'all', title: 'Все файлы в папке', },
      types: [
        { value: 'all', title: 'Все файлы в папке', },
        { value: 'code', title: 'Файлы с шифром', },
        { value: 'custom', title: 'Выбрать файлы вручную', },
      ],
      documents: [],
    }
  },
  created() {
    this.load()
    this.$form.init({
      folder: null,
      documents: [],
    })
  },
  methods: {
    changeType(value) {
      if (value.value === 'custom') {
        this.loadFiles()
      }
      this.type = value
    },
    load() {
      this.$var('load', true)
      $api.fs.folders.get().filter({ 'projectId': this.task.projectId, }).view('tree').then((response) => {
        this.folders = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadFiles() {
      this.$var('load', true)
      $api.fs.folders.getDocuments(this.$form.get('folder.id')).then((response) => {
        this.documents = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      this.$var('load', true)
      const data = this.type.value === 'custom' ? {
        documents: $n.map(this.$form.get('documents'), (i) => i.id),
      } : {
        folderId: this.$form.get('folder.id'),
        selectType: this.type.value,
      }
      $api.fs.tasks.edit(this.task.id, data).then((response) => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-select-files {

}
</style>
