<template>
  <n-modal class="modal-add-folder" :loading="$var('load')" @close="$emit('close')">
    <h3>Создать папку</h3>
    <n-form @submit="submit">
      <n-items>
        <n-input title="Название" v-bind="$form.input('title')" />
        <n-button type="submit">Создать</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalAddFolder',
  props: {
    folder: { type: Object, required: true, },
    isPrivate: { type: Boolean, required: true, },
  },
  created() {
    this.$form.init({
      title: '',
    })
    this.$form.rules({
      title: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          title: this.$form.get('title'),
          folderId: this.folder.id,
          isPrivate: this.isPrivate,
        }
        $api.fs.folders.create(data).then(() => {
          this.$emit('close')
          this.$emit('reload')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-add-folder {

}
</style>
