import { v4 as uuid, } from 'uuid'

export default {
  uuid() {
    return uuid()
  },
  reportType(name) {
    const data = {
      table: { name: 'table', title: 'Табличный отчет', icon: 'table', },
      time: { name: 'time', title: 'Отчет по времени', icon: 'hourglass', },
      line: { name: 'line', title: 'Линейная диаграмма', icon: 'chart-line', },
      pie: { name: 'pie', title: 'Круговая диаграмма', icon: 'chart-pie', },
      column: { name: 'column', title: 'Столбиковая диаграмма', icon: 'chart-column', },
      radar: { name: 'radar', title: 'Радиальная диаграмма', icon: 'bullseye', },
    }

    return name ? data[name] : Object.values(data)
  },
  taskType(name) {
    const data = {
      task: { name: 'task', title: 'Задание', color: 'default', },
      project: { name: 'project', title: 'Задание на проектирование', color: 'success', },
      cpe: { name: 'cpe', title: 'Задание от ГИПа', color: 'warning', },
      interdepartment: { name: 'interdepartment', title: 'Междисциплинарное задание', color: 'secondary', },
    }

    return name ? data[name] : Object.values(data)
  },
  taskStatus(name) {
    const data = {
      created: { name: 'created', title: 'Создано', color: 'default', },
      reviewing: { name: 'reviewing', title: 'На проверке', color: 'warning', },
      reviewed: { name: 'reviewed', title: 'Проверено', color: 'success', },
      notReviewed: { name: 'notReviewed', title: 'Не принято', color: 'danger', },
      working: { name: 'working', title: 'В работе', color: 'primary', },
      approving: { name: 'approving', title: 'На согласовании', color: 'warning', },
      approved: { name: 'approved', title: 'Согласовано', color: 'success', },
      rejected: { name: 'rejected', title: 'Отказано', color: 'danger', },
      done: { name: 'done', title: 'Завершено', color: 'default', },
      canceled: { name: 'canceled', title: 'Отменено', color: 'danger', },
    }

    return name ? data[name] : Object.values(data)
  },

  fileSize(size) {
    if (!size) {
      return ''
    }
    const i = Math.floor( Math.log(size) / Math.log(1024) )
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + [ 'B', 'kB', 'MB', 'GB', 'TB', ][i]
  },
  fileIcon(ext) {
    const icons = {
      txt: 'file-alt',
      png: 'file-image',
      jpg: 'file-image',
      jpeg: 'file-image',
      mp3: 'file-audio',
      mp4: 'file-video',
      avi: 'file-video',
      doc: 'file-word',
      docx: 'file-word',
      pdf: 'file-pdf',
    }
    return icons[ext.toLowerCase()] || 'file'
  },
}
