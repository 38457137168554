<template>
  <n-modal class="modal-time-range" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-datepicker title="От" v-bind="$form.input('startedAt')" />
        <n-datepicker title="До" v-bind="$form.input('endedAt')" />
        <n-button type="submit" color="success" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
import { getBuilder, } from '../../utils'

export default {
  name: 'ModalTimeRange',
  props: {
    params: { type: Object, required: true, },
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    'update:params': { type: Function, default: (params) => {}, },
  },
  created() {
    const rangeParts = this.params.range ? this.params.scale.split(',') : []
    this.$form.init({
      startedAt: rangeParts[0],
      endedAt: rangeParts[1],
    })
    this.$form.rules({
      startedAt: [ 'required', ],
      endedAt: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        const builder = getBuilder(this.params)
        builder.range(this.$form.get('startedAt'), this.$form.get('endedAt'))
        this.$emit('update:params', builder.params())
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-time-range {
}
</style>
