<template>
  <div class="block-parent">
    <div v-if="parent" class="block-table">
      <div class="title-block row">
        <div class="parent-task" @click="$var('parent', true)">
          <div class="sub">Родительская задача</div>
          {{ parent.title }}
        </div>
      </div>
    </div>
    <div v-else class="block-table">
      <div class="add-button row" @click="$var('parent', true)">
        <n-icon icon="plus" /> назначить родительскую задачу
      </div>
    </div>

    <modal-edit-parent v-if="$var('parent')" :task="parent" :project-id="projectId"
                       @submit="change" @close="$var('parent', false)" />
  </div>
</template>

<script>
import ModalEditParent from './ModalEditParent'

export default {
  name: 'BlockParent',
  components: { ModalEditParent, },
  props: {
    projectId: { type: Number, required: true, },
    parentId: { type: Number, default: null, },
    'update:parentId': { type: Function, default: (v) => {}, },
  },
  data() {
    return {
      parent: null,
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      if (!this.parentId) return

      this.$var('load', true)
      $api.fs.tasks.get(this.parentId).then((response) => {
        this.parent = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    change(parent) {
      this.parent = parent
      this.$emit('update:parentId', parent.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.block-parent {
  .parent-task {
    cursor: pointer;
  }
}
</style>
