<template>
  <div class="page-settings-users">
    <div class="double-page">
      <div class="block-table">
        <n-loader :loading="$var('loadDeps')" />
        <div class="title-block row">Отделы</div>

        <div v-for="dep in departments" :key="dep.id" class="row item">
          <div class="content">{{ dep.title }}</div>
          <div class="tools"><n-button icon="angle-right" round @click="remove(dep.id)" /></div>
        </div>

        <div class="add-button row" @click="$var('add', true)">
          <n-icon icon="plus" /> отдел
        </div>
      </div>
      <div class="block-table">
        <n-loader :loading="$var('loadDeps')" />
        <div class="title-block row">Бюро ГИПов</div>

        <div v-for="dep in departments" :key="dep.id" class="row item">
          <div class="content">{{ dep.title }}</div>
          <div class="tools"><n-button icon="angle-right" round @click="remove(dep.id)" /></div>
        </div>

        <div class="add-button row" @click="$var('add', true)">
          <n-icon icon="plus" /> отдел
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageSettingsUsers',
  data() {
    return {
      departments: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('loadDeps', true)
      $api.company.departments.get().then((response) => {
        this.departments = response.data.content
      }).finally(() => {
        this.$var('loadDeps', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-settings-users {

}
</style>
