<template>
  <div class="fs-nav">
<!--    <n-loader :loading="loading" />-->
    <div v-for="item in rootItems" :key="item.id" class="list-item">
      <div :class="[ 'title-button', { opened: item.opened, }]" @click="() => click(item)">
        <n-icon :icon="item.icon" /><span>{{ item.title }}</span>
      </div>
      <div v-if="item.collapsed" class="collapsed">
        <fs-nav :folders="folders" :roots="item.childIds" :opened="opened"
                @update:opened="(i) => click(i)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FsNav',
  props: {
    folders: { type: Object, required: true, },
    roots: { type: Array, required: true, },
    opened: { type: Object, default: null, },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      icons: {
        projects: 'project-diagram',
        project: 'project-diagram',
        documents: 'id-badge',
        trash: 'trash',
        archive: 'archive',
        manager: 'user-cog',
        favorites: 'star',
        other: 'folder-minus',
      },
    }
  },
  computed: {
    rootItems() {
      return $n.reduce(this.roots, (result, id) => {
        const item = this.getItem(id)
        if (!item || !item.id) return result
        result.push({
          ...item,
          icon: this.icons[item.type] || this.icons.other,
          opened: this.opened?.id === id,
          collapsed: this.opened && (this.opened?.id === id || this.opened.parentIds.includes(id)),
        })
        return result
      }, [])
    },
  },
  methods: {
    getItem(id) {
      return this.folders[id] || {}
    },
    click(item) {
      this.$emit('update:opened', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-nav {
  border: solid var(--fs-border-color);
  background: #f5f5f5;
  border-width: 0 1px;
  font-size: .9em;
  padding: 7px;
  position: relative;

  .fs-nav {
    border-left: none;
    border-right: none;
    font-size: 1em;
    padding: 0;
  }

  &::v-deep {
    .title-button {
      cursor: pointer;
      padding: 6px 10px;
      border-radius: 5px;
      font-size: .9em;
      transition: background-color .2s;
      &:hover:not(.opened) {
        background: #e5e5e5;
      }
      &.opened {
        background: #ddd;
      }
      .n-icon {
        margin-right: 8px;
        font-size: .9em;
        opacity: .5;
      }
    }

    .collapsed {
      margin-left: 1em;
    }
  }
}
</style>
