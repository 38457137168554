<template>
  <div v-if="$n.size(files)" class="block-files">
    <!--      <n-button v-if="can('review')" @click="changeStatus">Сменить статус файлов</n-button>-->
    <n-table :data="files" :columns="columns">
      <template #title="{item}">
        <div class="file-status">
          <div class="status-title">
            <n-icon :icon="fileStatuses[item.status].icon" />{{ fileStatuses[item.status].title }} {{ item.done }}%
          </div>
          <div class="status-buttons">
            <span v-if="(can('review') || can('files')) && item.status === 'work'" @click="status(item, 'wait')"><n-icon icon="pause" /></span>
            <span v-if="(can('review') || can('files')) && item.status === 'wait'" @click="status(item, 'work')"><n-icon icon="play" /></span>
            <span v-if="(can('review') || can('files')) && ['reviewed', 'done'].includes(item.status)" @click="status(item, 'work')">
              <n-icon icon="rotate-left" />
            </span>
            <span v-if="can('review')" @click="$var('editDocument', item)"><n-icon icon="pen" /></span>
          </div>
        </div>
        <a :href="item.src" target="_blank">{{ item.title }}.{{ item.ext }}</a>
      </template>
      <template #createdAt="{item}">{{ $app.date.format(item.createdAt) }}</template>
      <template #updatedAt="{item}">{{ $app.date.format(item.updatedAt) }}</template>
      <template #edit="{item}">
        <template v-if="item.status === 'work'">
          <n-button v-if="item.takerId === $app.auth.user().id" icon="upload" @click="$var('retrieve', item)">вернуть</n-button>
          <n-button v-else-if="!item.takerId && can('files')" icon="download" @click="take(item)">редактировать</n-button>
          <div v-if="item.takerId && item.takerId !== $app.auth.user().id" class="file-comment">Редактируется: <br />{{ item.taker.fullName }}</div>
        </template>
        <div v-if="item.status === 'wait'" class="file-comment">Причина остановки: <br />{{ item.comment }}</div>
      </template>
      <template #review="{item}">
        <template v-if="!item.takerId">
          <template v-if="can('files') && item.status === 'work'">
            <n-button icon="stop" @click="status(item, 'done')">выполнено</n-button>
          </template>
          <template v-if="can('review') && item.status === 'done'">
            <n-button icon="check" @click="status(item, 'reviewed')">принять</n-button>
          </template>
        </template>

        <!--          <n-select v-if="can('review')" title="Сменить статус" :data="Object.values(fileStatuses)"-->
        <!--                    v-bind="$form.input(item.id+'', 'select', 'review')" />-->
        <!--          <n-input v-if="$form.get(item.id+'', 'review') && $form.get(item.id+'', 'review').value === 'wait'" title="Комментарий"-->
        <!--                   v-bind="$form.input(item.id+'', 'input', 'comments')" />-->
      </template>
    </n-table>

    <modal-edit-file v-if="$var('editDocument')" :document="$var('editDocument')" @reload="load" @close="$var('editDocument', false)" />
    <modal-retrieve v-if="$var('retrieve')" :file="$var('retrieve')" @submit="load" @close="$var('retrieve', false)" />
    <modal-to-wait v-if="$var('toWait')" :file="$var('toWait')" @submit="load" @close="$var('toWait', false)" />
  </div>
</template>

<script>
import ModalEditFile from './ModalEditFile'
import ModalRetrieve from './ModalRetrieve'
import ModalToWait from './ModalToWait'

export default {
  name: 'BlockFiles',
  components: { ModalToWait, ModalRetrieve, ModalEditFile, },
  props: {
    task: { type: Object, required: true, },
    can: { type: Function, required: true, },
  },
  data() {
    return {
      files: [],
      fileStatuses: {
        work: { value: 'work', title: 'В разработке', icon: 'play', color: 'primary', },
        wait: { value: 'wait', title: 'Ожидает', icon: 'pause', color: 'warning', },
        done: { value: 'done', title: 'Завершен', icon: 'stop', color: 'primary', },
        reviewed: { value: 'reviewed', title: 'Проверен', icon: 'check', color: 'success', },
      },
      columns: [
        { name: 'title', title: 'Файлы в разработке', },
        { name: 'edit', title: '', },
        // { name: 'createdAt', title: 'Дата создания', },
        { name: 'updatedAt', title: 'Дата изменения', },
        { name: 'review', title: '', },
      ],
    }
  },
  watch: {
    task() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      if (!this.task.id) return

      this.$var('load', true)
      $api.fs.tasks.files.get(this.task.id).with('taker').then((response) => {
        this.files = response.data.content
        this.$form.init($n.reduce(this.files, (result, i) => {
          result[i.id + ''] = null
          return result
        }, {}), true, 'review')
        this.$form.init($n.reduce(this.files, (result, i) => {
          result[i.id + ''] = null
          return result
        }, {}), true, 'comments')
      }).finally(() => {
        this.$var('load', false)
      })
    },
    status(document, status) {
      if (status === 'wait') {
        this.$var('toWait', document)
        return
      }

      this.$var('load', true)
      $api.fs.documents.edit(document.id, { status, }).then((response) => {
        this.load()
      }).catch(() => {
        this.$var('load', false)
      })
    },
    take(item) {
      $api.fs.documents.take(item.id).then(() => {
        const link = document.createElement('a')
        link.href = item.src
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.loadFiles()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.block-files {
  &::v-deep {
    .table {
      .header .cell {
        border-top: none;
      }
    }
  }

  .file-status {
    display: flex;
    align-items: center;
    min-width: 300px;
    .n-icon {
      margin-right: 5px;
    }

    .status-title {
      font-size: .8em;
      opacity: .7;
      margin-bottom: 3px;
    }

    .status-buttons {
      margin-left: 20px;
      font-size: .8em;
      --link-color: #999;
      &>span {
        opacity: .5;
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .file-comment {
    font-style: italic;
    opacity: .5;
  }
}
</style>
