<template>
  <div class="draggable" :class="[ {dropzone}, ]"
       @drop.prevent="onDrop($event, name)" @dragover.prevent="onDragEnter($event, name)" @dragleave="onDragLeave">
    <slot name="content" v-bind="{ onDrag, onDragEnd, }"></slot>
  </div>
</template>

<script>
export default {
  name: 'Draggable',
  props: {
    name: { type: [ String, Number, ], required: true, },
  },
  data() {
    return {
      columns: [
        { name: 'title', title: 'Название', },
        { name: 'updatedAt', title: 'Дата изменения', },
        { name: 'size', title: 'Размер', },
      ],
      dropzone: false,
      dragTimeOut: null,
    }
  },
  methods: {
    onDrop(event, group) {
      const drag = $app.store.getter('filesystem.drag')
      this.dropzone = false

      if (drag.item) {
        if (drag.group !== group) {
          this.$emit('drop', event, drag.item)
        }
      } else {
        this.$emit('drop', event)
      }

      $app.store.mutation('filesystem.drag', { item: null, group: null, })
    },
    onDragEnter(event, group) {
      const drag = $app.store.getter('filesystem.drag')
      if (drag.item) {
        if (drag.group !== group) {
          if (this.dragTimeOut) {
            clearTimeout(this.dragTimeOut)
            this.dragTimeOut = null
          }
          this.dropzone = true
        }
      } else {
        if (this.dragTimeOut) {
          clearTimeout(this.dragTimeOut)
          this.dragTimeOut = null
        }
        this.dropzone = true
      }
    },
    onDragLeave() {
      const drag = $app.store.getter('filesystem.drag')
      if (drag.item) {
        if (drag.group !== this.name) {
          this.dragTimeOut = setTimeout(() => {
            this.dropzone = false
          }, 10)
        }
      } else {
        this.dragTimeOut = setTimeout(() => {
          this.dropzone = false
        }, 10)
      }
    },
    onDrag(event, item, group) {
      $app.store.mutation('filesystem.drag', { item, group, })
    },
    onDragEnd() {
      this.dropzone = false
      $app.store.mutation('filesystem.drag', { item: null, group: null, })
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable {
  &.dropzone {
    position: relative;
    &::after {
      z-index: 100;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: "DROP HERE";
      color: rgba(127, 127, 127, .7);
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(207, 219, 227, 0.8);
    }
  }
}
</style>
