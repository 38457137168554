import { render, staticRenderFns } from "./RenameDocument.vue?vue&type=template&id=364f5723&scoped=true&"
import script from "./RenameDocument.vue?vue&type=script&lang=js&"
export * from "./RenameDocument.vue?vue&type=script&lang=js&"
import style0 from "./RenameDocument.vue?vue&type=style&index=0&id=364f5723&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364f5723",
  null
  
)

export default component.exports