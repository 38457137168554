<template>
  <div class="project-item">
    <div class="wrap">
      <router-link class="link" :to="{ name: 'projects.detail', params: { id: project.id, }, }"></router-link>
      <div class="header">
        <div class="date">{{ $app.date.format(project.createdAt, 'datetime') }}</div>
        <div class="author">{{ project.author }}</div>
      </div>
      <div class="body">
        <div class="title">
          {{ project.title }}
          <span v-if="project.isTemplate" style="font-size: .9rem; opacity: .9; color: #825809;">Шаблон</span>
          <span v-if="project.archivedAt" style="font-size: .9rem; opacity: .9; color: #825809;">Переносится в архив</span>
        </div>
        <div class="tasks-status-wrap">
          <div class="tasks-status-item">
            <div class="name">Задачи</div>
            <div class="count">{{ project.taskcount }}</div>
          </div>

          <div class="tasks-status-item">
            <div class="name">Закрытые задачи</div>
            <div class="count">{{ project.closetaskcount }}</div>
          </div>

          <div class="tasks-status-item">
            <div class="name">Задействованные отделы</div>
            <div class="count">{{ project.department }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectItem',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.project-item {
  padding: calc(35px / 2);
  width: 25%;

  .wrap {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: 18px 14px 10px;
    position: relative;
  }

  .link{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .header {
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #C4C4C4;
    text-align: right;
    margin-bottom: 30px;
  }

  .date {
    margin-bottom: 4px;
  }

  .body {
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #C4C4C4;
  }

  .title {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 20px;
  }

  .tasks-status-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .name {
      flex: 4;
    }

    .count {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      flex: 2;
    }
  }
}
</style>
