<template>
  <n-modal class="modal-edit-widget" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Отчет" :data="reports" v-bind="$form.input('report', 'select')" :update:value="selectReport" />
        <n-input title="Название" v-bind="$form.input('title')" />
        <n-button type="submit">Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalEditWidget',
  props: {
    widget: { type: Object, required: true, },
  },
  data() {
    return {
      reports: [],
    }
  },
  computed: {
    isNew() {
      return !this.widget.id
    },
  },
  created() {
    this.load()
    this.$form.init({
      report: this.isNew ? null : this.widget.report,
      title: this.widget.title,
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.reports.get().then((response) => {
        this.reports = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      this.$var('load', true)
      const data = {
        reportId: this.$form.get('report.id'),
        title: this.$form.get('title'),
      }
      const api = this.isNew ? $api.profile.widgets.post(data) : $api.profile.widgets.edit(this.widget.id, data)
      api.then(() => {
        this.$emit('submit')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
    selectReport(v) {
      this.$form.set('report', v)
      if (!this.$form.get('title')) {
        this.$form.set('title', v.title)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-edit-widget {

}
</style>
