<template>
  <div class="page-settings-users">
    <div class="double-page">
      <div class="departments">
        <div class="block-table">
          <n-loader :loading="$var('loadDeps')" />
          <div class="title-block row">Департаменты</div>

          <div v-for="dep in departments" :key="dep.id" class="row item" :class="[{ active: departmentId === dep.id}]" @click="departmentId = dep.id">
            <div class="content">{{ dep.title }}</div>
          </div>

          <div class="add-button row" @click="$var('dep', {})">
            <n-icon icon="plus" /> департамент
          </div>
        </div>
      </div>

      <div>
        <div v-if="department.id" class="block-table">
          <div class="row item">
            <div class="content">
              Название отдела:
              <span class="editable" @click="$var('dep', department)">{{ department.title }}</span>
            </div>
          </div>
          <div class="row item">
            <div class="content">
              Группа начальника департамента:
              <span v-if="group(department.headGroupId).id" class="editable" @click="$var('dep', department)">
                {{ group(department.headGroupId).title }}
              </span>
              <span v-else class="add-button" @click="$var('dep', department)"><n-icon icon="plus" /> назначить</span>
            </div>
          </div>
          <div class="row item">
            <div class="content">
              Группа сотрудников департамента:
              <span v-if="group(department.stuffGroupId).id" class="editable" @click="$var('dep', department)">
                {{ group(department.stuffGroupId).title }}
              </span>
              <span v-else class="add-button" @click="$var('dep', department)"><n-icon icon="plus" /> назначить</span>
            </div>
          </div>

          <div class="add-button row remove" @click="remove(department.id)">
            <n-icon icon="trash" />&nbsp;удалить департамент
          </div>
        </div>
      </div>
    </div>

    <modal-department v-if="$var('dep')" :model="$var('dep')" @submit="load" @close="$var('dep', false)" />
  </div>
</template>

<script>
import ModalDepartment from './ModalDepartment'

export default {
  name: 'PageSettingsUsers',
  components: { ModalDepartment, },
  data() {
    return {
      departments: [],
      groupsById: {},
    }
  },
  computed: {
    departmentId: {
      get() {
        return this.$route.query.department * 1 || 0
      },
      set(value) {
        if (this.departmentId !== value) {
          this.$router.push({
            ...this.$route,
            query: {
              ...this.$route.query,
              department: value,
            },
          })
        }
      },
    },
    department() {
      return $n.find(this.departments, [ 'id', this.departmentId, ]) || {}
    },
  },
  created() {
    this.load()
    this.loadGroups()
  },
  methods: {
    load() {
      this.$var('loadDeps', true)
      $api.company.departments.get().with('head').with('stuff').then((response) => {
        if ($n.size(this.departments) === 0 || !this.departmentId) {
          this.departmentId = response.data.content[0]?.id
        }
        this.departments = response.data.content
      }).finally(() => {
        this.$var('loadDeps', false)
      })
    },
    loadGroups() {
      this.$var('loadGroups', true)
      $api.auth.groups.get().key('id').then((response) => {
        this.groupsById = response.data.content
      }).finally(() => {
        this.$var('loadGroups', false)
      })
    },
    group(id) {
      return $n.get(this.groupsById, id, {})
    },
    remove(id) {
      this.$var('loadDeps', true)
      $api.company.departments.remove(id).then((response) => {
        this.departmentId = null
        this.load()
      }).catch(() => {
        this.$var('loadDeps', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-settings-users {
  .departments {
    .item {
      cursor: pointer;
      &.active {
        background: var(--primary-t-9);
      }
      &:hover:not(.active) {
        background: var(--primary-t-10);
      }
    }
  }

  .remove {
    color: var(--danger);
  }
}
</style>
