import tasksTableColumns from 'miscs/tasks-table-columns'
import exampleTableColumns from 'miscs/example-table-columns'


export default {
  namespaced: true,
  state: {
    tree: {},
    tasksTableColumns: Object.freeze(tasksTableColumns),
    exampleTableColumns: Object.freeze(exampleTableColumns),
    folder_view_mode: 'table',
  },
  mutations: {
    SET_TREE(state, payload) {
      state.tree = payload
    },
    set_folder_view_mode(state, viewMode) {
      state.folder_view_mode = viewMode
    },
  },
}
