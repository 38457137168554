<template>
  <n-modal class="modal-create-revision" :loading="$var('load')" @close="$emit('close')">
    <h3>Создать ревизию</h3>
    <n-form @submit="submit">
      <n-items>
        <n-input title="Номер ревизии" v-bind="$form.input('revision')" />
        <n-button type="submit">Создать</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalCreateRevision',
  props: {
    folder: { type: Object, required: true, },
  },
  created() {
    this.$form.init({
      revision: '',
    })
    this.$form.rules({
      revision: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.fs.folders.createRevision(this.folder.id, { v: this.$form.get('revision'), }).then(() => {
          this.$emit('close')
          this.$emit('reload')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-create-revision {

}
</style>
