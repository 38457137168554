export default class {
  #timeout = 1000;

  constructor(timeout) {
    if (typeof timeout == "number") {
      this.#timeout = timeout;
    }
  }

  setTimeoutVar(timeout) {
    if (typeof timeout == "number") {
      this.#timeout = timeout;
    } else {
      throw new Error("timeout must be Number type");
    }
  }

  getPlug(url) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        import("./modules/get/" + url + ".js")
          .then((module) => {
            resolve(module.default());
          })
          .catch((error) => {
            reject(error);
          });
      }, this.#timeout);
    });
  }

  postPlug(url, data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        import("./modules/post/" + url + ".js")
          .then(async (module) => {
            try {
              const resolveData = await module.default(data);
              resolve(resolveData);
            } catch (error) {
              reject(error);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }, this.#timeout);
    });
  }
}
