<template>
  <div class="c-fields">
    <div v-if="title" class="block-title">{{ title }}</div>

    <div v-for="column in parsedColumns" :key="column.name" class="query-item">
      <span class="query-item-content" @click="selected = column">
        <span class="title">{{ column.title }}</span>
      </span>
      <span class="remove-button" @click="remove(column.name)">удалить</span>
    </div>
    <div v-if="!parsedColumns.length" class="empty-message">Колонок нет</div>

    <div class="add-button" @click="selected = {}"><n-icon icon="plus" /> добавить</div>

    <modal-column v-if="selected" :column="selected" :params="params" :columns="columns" :ui="ui" :model="model" :columns-type="columnsType"
                  @update:params="(v) => $emit('update:params', v)" @update:columns="(v) => $emit('update:columns', v)" @close="selected = false" />
  </div>
</template>

<script>
import map from 'lodash/map'
import filter from 'lodash/filter'
import { getBuilder, } from '../../utils'
import ModalColumn from './ModalColumn'

export default {
  name: 'CFields',
  components: { ModalColumn, },
  props: {
    params: { type: Object, required: true, },
    columns: { type: Array, required: true, }, // { name: '', title: '', type: '', }
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    title: { type: String, default: '', },
    columnsType: { type: String, default: 'fields', }, // fields | numbers | dates
    'update:params': { type: Function, default: () => {}, },
    'update:columns': { type: Function, default: () => {}, },
  },
  data() {
    return {
      selected: false,
    }
  },
  computed: {
    parsedColumns() {
      return map(this.columns, (column) => {
        const parts = column.name.split(':')
        const fieldId = parts[1] ? parts[0] : column.name
        return {
          name: column.name,
          title: column.title,
          type: column.type,
          uiField: this.ui[this.model].fields[fieldId],
        }
      })
    },
  },
  methods: {
    remove(name) {
      const builder = getBuilder(this.params)
      builder.remove('fields', name)
      this.$emit('update:params', builder.params())
      this.$emit('update:columns', filter(this.columns, (v) => v.name !== name))
    },
  },
}
</script>

<style lang="scss" scoped>
.c-fields {
  width: 100%;
  .block-title {
    opacity: .5;
    font-size: .8em;
  }

  .query-item {
    margin: 4px 0 8px;
    line-height: 1;
  }
  .query-item-content {
    border-bottom: 1px dotted #000;
    cursor: pointer;
    .title {}
    .method {
      display: inline-block;
      margin-left: 10px;
      opacity: .6;
      text-transform: lowercase;
    }
    .values {
      margin-left: 10px;
      font-style: italic;
      .value {
        color: var(--primary);
      }
      .or {
        font-size: .8em;
      }
    }
  }

  .add-button {
    display: inline-block;
    color: var(--primary);
    font-size: .8em;
    opacity: .6;
    cursor: pointer;
    transition: opacity .2s;
    &:hover {
      opacity: 1;
    }
  }
  .remove-button {
    display: inline-block;
    margin-left: 10px;
    font-size: .8em;
    opacity: .2;
    cursor: pointer;
    &:hover {
      opacity: 1;
      color: var(--danger);
    }
  }

  .empty-message {
    padding: 6px 0;
    font-style: italic;
    opacity: .7;
    font-size: .8em;
  }
}
</style>
