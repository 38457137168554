<template>
  <div class="modal-detail-versions">
    <n-table :data="model.versions" :columns="columns" :loading="$var('loading')">
      <template #version="{item}">
        {{ item.version }}
        <n-button v-if="!item.savedAt" @click="$var('version', item.id)">Сохранить</n-button>
        <span v-else class="saved-version">[сохраненная версия]</span>
      </template>
      <template #size="{item}">
        <span>{{ getFileSize(item.size) }}</span>
      </template>
      <template #tools="{item}">
        <n-link :to="item.src" type="external">Скачать</n-link>
      </template>
    </n-table>

    <modal-save-version v-if="$var('version')" :id="$var('version')" @reload="load" @close="$var('version', false)" />
  </div>
</template>

<script>
import ModalSaveVersion from './ModalSaveVersion'
export default {
  name: 'ModalDetailVersions',
  components: { ModalSaveVersion, },
  props: {
    selected: { type: Object, required: true, },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      columns: [
        { name: 'version', title: 'Версия', },
        { name: 'size', title: 'Размер', },
        { name: 'tools', title: '', },
      ],
      model: {},
    }
  },
  watch: {
    selected() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('loading', true)
      $api.fs.documents.get(this.selected.id).with('versions').then((response) => {
        this.model = response.data.content
      }).finally(() => {
        this.$var('loading', false)
      })
    },
    getFileSize(size) {
      const i = Math.floor( Math.log(size) / Math.log(1024) )
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + [ 'B', 'kB', 'MB', 'GB', 'TB', ][i]
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-detail-versions {
  .saved-version {
    font-size: .8em;
    opacity: .6;
  }
}
</style>
