<template>
  <n-modal class="user-modal" :loading="$var('load') || $var('loadRoles')" @close="$emit('close')">
    <h3>Полномочия пользователя</h3>
    <n-select v-if="!user.id" title="Пользователь" :data="users" v-bind="$form.input('user', 'select')" :update:value="change"
              selected-title="fullName" option-title="fullName" />
    <n-input v-else title="Пользователь" :value="user.fullName" text />

    <div class="block-title">
      Роли
      <n-button v-if="editable" icon="plus" round @click="() => $var('role', {})" />
    </div>
    <n-table :data="roles" :columns="columns" headless>
      <template #info="{item}">
        <div v-if="!item.isSelf" class="inherit">
          <template v-if="item.isInherit">Наследуется от родителя</template>
          <template v-else-if="item.isGroup">Наследуется от группы</template>
        </div>
        <div v-else></div>
      </template>
      <template #endedAt="{item}">
        <div v-if="item.endedAt">
          До {{ $app.date.format(item.endedAt, 'date') }}
        </div>
        <span v-else></span>
      </template>
      <template #tools="{item}">
        <div class="tools">
          <n-button v-if="editable && item.isSelf" icon="minus" @click="remove(item.accessId)" />
        </div>
      </template>
    </n-table>

    <add-role-modal v-if="$var('role')" :model-id="modelId" :type="type" :owner-id="$form.get('user.id')" owner="user"
                    @reload="reload" @close="$var('role', false)" />
  </n-modal>
</template>

<script>
import AddRoleModal from './AddRoleModal'

export default {
  name: 'UserModal',
  components: { AddRoleModal, },
  props: {
    user: { type: Object, default: () => ({}), },
    modelId: { type: [ Number, String, ], default: undefined, },
    type: { type: String, default: undefined, },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      users: [],
      roles: [],
      perms: [],
      columns: [
        { name: 'title', title: '', },
        { name: 'info', title: '', },
        { name: 'endedAt', title: '', },
        { name: 'tools', title: '', width: '40px', },
      ],
    }
  },
  created() {
    this.$form.init({
      user: this.user,
    })
    this.load()
  },
  methods: {
    change(value) {
      this.$form.set('user', value)
      if (value) {
        this.loadRoles()
      }
    },
    load() {
      this.$var('load', true)
      const api = this.user.id ?
        $api.auth.users.get(this.user.id) :
        $api.auth.accesses.models.users(this.modelId, this.type, true)
      api.with('groups').then((response) => {
        if (this.user.id) {
          this.$form.init({
            user: response.data.content,
          })
          this.loadRoles()
        } else {
          this.users = response.data.content
        }
      }).finally(() => {
        this.$var('load', false)
      })
    },
    reload() {
      this.loadRoles()
      this.$emit('reload')
    },
    loadRoles() {
      this.$var('loadRoles', true)
      $api.auth.accesses.models.roles(this.modelId, this.type, this.$form.get('user.id'), 'user').then((response) => {
        this.roles = response.data.content
      }).finally(() => {
        this.$var('loadRoles', false)
      })
    },
    remove(accessId) {
      this.$var('load', true)
      $api.auth.accesses.delete(accessId).then(() => {
        this.reload()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-modal {

}
</style>
