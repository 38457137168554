<template>
  <div class="accesses">
    <h3>Доступы</h3>

    <n-loader :loading="$var('load')" />

    <template v-if="!root">
      <div v-if="model.isCancelAccess" class="cancel-accesses">
        Наследование полномочий отключено
        <span v-if="editable" class="action" @click="toggleCancel">Разрешить</span>
      </div>
      <div v-else class="cancel-accesses">
        <span v-if="editable" class="action" @click="toggleCancel">Запретить наследование полномочий</span>
      </div>
    </template>


    <div class="block-title">
      Группы
      <n-button v-if="editable" icon="plus" round @click="() => $var('group', {})" />
    </div>
    <n-table :data="groups" :columns="columns" headless>
      <template #info="{item}">
        <div v-if="!item.isSelf" class="inherit">
          <template v-if="item.isInherit">Наследуется от родителя</template>
          <template v-else-if="item.isGroup">Наследуется от группы</template>
        </div>
        <div v-else></div>
      </template>
      <template #tools="{item}">
        <div class="tools">
          <n-button icon="eye" @click="$var('group', item)" />
          <n-button v-if="editable && item.isSelf" icon="minus" @click="remove(item.accessId)" />
        </div>
      </template>
    </n-table>


    <div class="block-title">
      Пользователи
      <n-button v-if="editable" icon="plus" round @click="() => $var('user', {})" />
    </div>
    <n-table :data="users" :columns="columns" headless>
      <template #title="{item}">{{ item.fullName }}</template>
      <template #info="{item}">
        <div v-if="!item.isSelf" class="inherit">
          <template v-if="item.isInherit">Наследуется от родителя</template>
          <template v-else-if="item.isGroup">Наследуется от группы</template>
        </div>
        <div v-else></div>
      </template>
      <template #tools="{item}">
        <div class="tools">
          <n-button icon="eye" @click="$var('user', item)" />
          <n-button v-if="editable && item.isSelf" icon="minus" @click="remove(item.accessId)" />
        </div>
      </template>
    </n-table>

    <group-modal v-if="$var('group')" :group="$var('group')" :model-id="model.id" :type="type" :editable="editable"
                 @reload="load" @close="$var('group', false)" />
    <user-modal v-if="$var('user')" :user="$var('user')" :model-id="model.id" :type="type" :editable="editable"
                @reload="load" @close="$var('user', false)" />
  </div>
</template>

<script>
import GroupModal from './GroupModal'
import UserModal from './UserModal'

export default {
  name: 'CAccesses',
  components: { UserModal, GroupModal, },
  props: {
    model: { type: Object, default: () => ({}), },
    type: { type: String, default: 'folder', },
    editable: { type: Boolean, default: false, },
    root: { type: Boolean, default: false, },
  },
  data() {
    return {
      users: [],
      groups: [],
      inheritOptions: [
        { value: false, title: 'Наследуется вниз', },
        { value: true, title: 'Локальный', },
      ],
      columns: [
        { name: 'title', title: '', },
        { name: 'info', title: '', },
        { name: 'tools', title: '', width: '80px', },
      ],
    }
  },
  watch: {
    model() {
      this.load()
    },
  },
  created() {
    this.$var('load', true)
    this.load()
  },
  methods: {
    load() {
      if (this.model.id) {
        this.$var('load', true)
        const apis = [
          $api.auth.accesses.models.groups(this.model.id, this.type),
          $api.auth.accesses.models.users(this.model.id, this.type),
        ]
        Promise.all(apis).then((response) => {
          this.groups = response[0].data.content
          this.users = response[1].data.content
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    toggleCancel() {
      this.$var('load', true)
      $api.auth.accesses.cancels.toggle({ modelId: this.model.id, modelType: this.type, }).then((response) => {
        this.$emit('reload')
      }).finally(() => {
        this.$var('load', false)
      })
    },
    remove(accessId) {
      this.$var('load', true)
      $api.auth.accesses.delete(accessId).then(() => {
        this.$emit('reload')
        this.load()
      }).catch(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.accesses {
  .cancel-accesses {
    margin: 10px 0;
    font-style: italic;
    .action {
      text-decoration: underline;
      color: var(--primary);
      cursor: pointer;
    }
  }

  &::v-deep {
    .block-title {
      margin: 25px 0 10px;
      font-weight: 500;

      .n-button {
        font-size: .8em;
      }
    }

    .n-table {
      width: 100%;
      .n-button {
        font-size: .7em;
      }

      .inherit {
        font-size: .9em;
        opacity: .8;
        font-style: italic;
      }
      .tools {
        &>*:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
