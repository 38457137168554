<template>
  <n-modal class="modal-time-scale" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Масштаб" :data="Object.values(ui._general.scale)" v-bind="$form.input('scale', 'select')" />
        <n-select title="Поле даты" :data="Object.values(ui[model].dates)" v-bind="$form.input('scaleField', 'select')" />
        <n-button type="submit" color="success" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
import { getBuilder, } from '../../utils'

export default {
  name: 'ModalTimeScale',
  props: {
    params: { type: Object, required: true, },
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    'update:params': { type: Function, default: (params) => {}, },
  },
  created() {
    const scaleParts = this.params.scale ? this.params.scale.split(',') : []
    this.$form.init({
      scaleField: this.ui[this.model].dates[scaleParts[0]],
      scale: this.ui._general.scale[scaleParts[1]],
    })
    this.$form.rules({
      scale: [ 'required', ],
      scaleField: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        const builder = getBuilder(this.params)
        builder.scale(this.$form.get('scaleField.id'), this.$form.get('scale.id'))
        this.$emit('update:params', builder.params())
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-time-scale {
}
</style>
