import { render, staticRenderFns } from "./AddRoleModal.vue?vue&type=template&id=fc2397bc&scoped=true&"
import script from "./AddRoleModal.vue?vue&type=script&lang=js&"
export * from "./AddRoleModal.vue?vue&type=script&lang=js&"
import style0 from "./AddRoleModal.vue?vue&type=style&index=0&id=fc2397bc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc2397bc",
  null
  
)

export default component.exports