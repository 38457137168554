<template>
  <n-card class="c-report-card" :loading="loading">
    <h3 v-if="report.id && !loading">
      {{ headless ? '&nbsp;' : (report.title || ' ') }}
      <n-link v-if="editable" to="#" class="edit-button" @click="$var('edit', true)"><n-icon icon="pen" /></n-link>
    </h3>

    <template v-if="report.id && !loading">
      <n-chart-table v-if="type('table')" :data="data" :columns="report.columns" />
      <n-chart-time v-if="type('time')" :data="data" :columns="report.columns" />
      <n-chart-pie v-if="type('pie')" :data="s_data" :column="pieColumn" />
      <n-chart-bar v-if="type('bar')" :data="s_data" :columns="report.columns" />
      <n-chart-line v-if="type('line')" :data="s_data" :columns="report.columns" fill />
      <n-chart-radar v-if="type('radar')" :data="s_data" :columns="report.columns" fill />
    </template>

    <n-modal v-if="$var('edit')" class="modal-edit" :loading="$var('load')" @close="$var('edit', false)">
      <c-report-form :type="report.type" :report="report" @submit="submit" />
    </n-modal>
  </n-card>
</template>

<script>
export default {
  name: 'CReportCard',
  props: {
    report: { type: Object, required: true, },
    data: { type: Array, required: true, },
    editable: { type: Boolean, default: false, },
    loading: { type: Boolean, default: false, },
    headless: { type: Boolean, default: false, },
  },
  computed: {
    s_data() {
      const valueField = $n.reduce(this.data[0], (result, value, key) => key === 'title' ? result : key, '')
      return $n.reduce(this.data, (result, item) => {
        result.push({
          ...item,
          value: item[valueField],
        })
        return result
      }, [])
    },
    pieColumn() {
      const valueField = $n.reduce(this.data[0], (result, value, key) => (key === 'title' && value*1) ? result : key, '')
      return $n.find(this.report.columns, [ 'name', valueField, ])
    },
  },
  methods: {
    type(type) {
      // return true
      // return 'table' === type
      return this.report.type === type
    },
    submit(data) {
      this.$var('load', true)
      $api.reports.edit(this.report.id, data).then((response) => {
        this.$emit('reload')
        this.$var('edit', false)
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-report-card {
  display: inline-block;
  min-width: 700px;
  min-height: 300px;
  --n-modal-width: 800px;

  h3 {
    display: flex;
    align-items: center;
    .edit-button {
      margin-left: 10px;
      font-size: .8rem;
    }
  }
}
</style>
