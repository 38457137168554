<template>
  <n-modal class="modal-add-department" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items v-if="data.length || $var('load')">
        <n-select title="Добавить отдел" :data="data" v-bind="$form.input('department', 'select')" />
        <n-button type="submit" color="success" wide>Добавить</n-button>
      </n-items>
      <n-items v-else>
        <div class="empty-message">Все отделы уже добавлены</div>
        <n-button wide @click="$emit('close')">Понятно</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalAddDepartment',
  props: {
    departments: { type: Array, default: null, },
  },
  data() {
    return {
      data: [],
    }
  },
  created() {
    this.$form.init({
      department: null,
    })
    this.$form.rules({
      department: [ 'required', ],
    })

    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      const ids = $n.map(this.departments, (item) => item.id)
      const filter = ids.length ? { id: 'not:'+ids.join(','), } : {}
      $api.company.departments.get().filter(filter).then((response) => {
        this.data = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$emit('submit', this.$form.get('department'))
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-add-department {
  --n-modal-width: 600px;
}
</style>
