import ChartTable from './table/Index'
import ChartTime from './time/Index'
import ChartPie from './pie/Index'
import ChartBar from './bar/Index'
import ChartLine from './line/Index'
import ChartRadar from './radar/Index'

export default {
  install(Vue) {
    Vue.component('nChartTable', ChartTable)
    Vue.component('nChartTime', ChartTime)
    Vue.component('nChartPie', ChartPie)
    Vue.component('nChartBar', ChartBar)
    Vue.component('nChartLine', ChartLine)
    Vue.component('nChartRadar', ChartRadar)
  },
}
