import Vue from 'vue'

export default () => ({
  api: {
    servers: {
      default: $env('server')+'/api/',
    },
    then: (response) => {
      if (response && !response.data.content) {
        response.data.content = response.data.data
      }
      if (response?.data?.message) {
        Vue.$toast.open({
          message: response.data.message,
          type: 'success',
          position: 'bottom-left',
          duration: 5000,
        })
      }
      return response
    },
    catch: (error) => {
      if (error.response?.status === 401) {
        if ($config('auth.method') === 'sso') {
          // window.location.url = $env('server')
        } else {
          $app.auth.logout()
        }
      } else if (error.response?.status === 422) {
        const message = error.response?.data.message
        const data = error.response?.data.data
        let errors = ''
        $n.each(data, (item) => {
          errors += item[0]
        })
        Vue.$toast.open({
          message: 'Ошибка: ' + message + ', ' + errors,
          type: 'error',
          position: 'bottom-left',
          duration: 5000,
        })
      } else {
        const message = error.response?.data.message || 'Сервер недоступен'
        Vue.$toast.open({
          message: 'Ошибка: ' + message,
          type: 'error',
          position: 'bottom-left',
          duration: 5000,
        })
      }
      throw error
    },
  },
  router: {
    config: {
      base: $env('baseUrl'),
      mode: 'history',
    },
  },
  store: {
    saveDepth: 2,
  },
  auth: {
    method: $env('auth', 'jwt'),
    loginRoute: 'login',
    authRoute: 'index',
    apiAuth: (token) => {
      if ($config('auth.method') === 'jwt') {
        $app.api.config({
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
      }
    },
    clarifyApi: (grantName) => {
      return $api.auth.grants.clarify(grantName).then((response) => response.data.content)
    },
  },
  date: {
    formats: {
      date: 'DD.MM.YYYY',
      time: 'HH:mm',
      datetime: 'DD.MM.YYYY HH:mm',
    },
    default: 'datetime',
  },
  form: {
    input: (self, name, type, form) => {
      if (type === 'select') {
        return {
          name,
          value: self.$form.get(name, form),
          'update:value': (value) => self.$form.set(name, value, form),
          danger: self.$form.errors(name, form),
          text: self.$form.editable(form) === false,
          itemValue: 'id',
        }
      }
      return {
        name,
        value: self.$form.get(name, form),
        input: (value) => self.$form.set(name, value, form),
        danger: self.$form.errors(name, form),
        text: self.$form.editable(form) === false,
      }
    },
    validations: {
      required(value) {
        return $n.size(value) > 0 || value === true
      },
      email(value) {
        return value.indexOf('@') !== -1
      },
      phone(value) {
        return value.indexOf('+') !== -1
      },
    },
    messages: {
      required: 'Поле обязательно для заполнения',
      email: 'Поле должно быть email адресом',
      phone: 'Поле должно быть корректным телефонным номером',
      between: (name, params, value) => `Поле должно быть от ${params[0]} до ${params[1]}`,
    },
  },
  secure: {
    whiteList: { p: [], br: [], span: [], },
  },
})
