<template>
  <n-modal class="modal-approve" :loading="$var('load')" @close="$emit('close')">
    <h3>Согласование</h3>
    <n-button color="primary" wide @click="submit">Принимаю</n-button>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalApprove',
  props: {
    task: { type: Object, required: true, },
  },
  methods: {
    submit() {
      this.$var('load', true)
      const data = {
        isApproved: true,
        comment: '',
        type: 'approve',
      }
      $api.fs.tasks.sign(this.task.id, data).then((response) => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-approve {
  --n-modal-width: 600px;

}
</style>
