<template>
  <div class="status" :class="[ {start: process.start === '', } ]">
    <!--    <div class="set-start-button">-->
    <!--      <n-button v-if="process.start === name" @click="$emit('setStart', name)">Первый</n-button>-->
    <!--    </div>-->
    <div class="title-block row" @click="$var('modalStatus', status)">
      В статусе <span class="title editable">{{ status.title }}</span>
    </div>

    <div v-if="$n.isString(actors)" class="message row">
      Все действия как у статуса {{ getTitle($n.taskStatus(actors)) }}
    </div>
    <div v-else-if="!$n.size(actors)" class="message row">
      Нет действий
    </div>
    <template v-else>
      <div v-for="(actions, actorName) in actors" :key="actorName" class="actor-block row">
        <div class="actor deletable" @click="removeActor(actorName)">{{ getTitle(process.actors[actorName]) }}</div>
        <div class="can">может:</div>
        <div v-for="(action, i) in actions" :key="i" class="action-block">
          <div v-if="action" class="action deletable" @click="removeAction(actorName, i)">
            {{ getTitle(variants.actions[action.do.type]) }}
          </div>

          <div v-if="$n.size(action.when) || $n.size(action.do.value)" class="hint">
            <div class="hint-action"><n-button round icon="info" /></div>
            <div class="hint-content">
              <div v-if="$n.size(action.when) && true" class="when-block">
                {{ $n.map(action.when, (v) => getTitle(variants.events[v.type])).join(', ') }}
              </div>
              <div v-if="$n.size(action.do.value) && true" class="event-block">
                [{{ $n.map(action.do.value, (v) => getValuesTitle(variants.actions[action.do.type].values, v)).join(', ') }}]
              </div>
            </div>
          </div>

          <div v-if="i < actions.length">, </div>
        </div>
        <div class="add-button" @click="$var('modalAction', status.id+'.'+actorName)">
          <n-icon icon="plus" /> действие
        </div>
      </div>
    </template>
    <div v-if="!$n.isString(actors)" class="add-button row" @click="$var('modalActor', status.id)">
      <n-icon icon="plus" /> участник
    </div>

    <process-modal-status v-if="$var('modalStatus')" :status="$var('modalStatus')" :process="process" :statuses="variants.values.statuses"
                          @update:process="(v) => $emit('update:process', v)" @close="$var('modalStatus', false)" />
    <process-modal-actor v-if="$var('modalActor')" :status="$var('modalActor')" :process="process"
                         @update:process="(v) => $emit('update:process', v)" @close="$var('modalActor', false)" />
    <process-modal-action v-if="$var('modalAction')" :path="$var('modalAction')" :process="process" :variants="variants"
                          @update:process="(v) => $emit('update:process', v)" @close="$var('modalAction', false)" />
  </div>
</template>

<script>
import ProcessModalActor from './ModalActor.vue'
import ProcessModalStatus from './ModalStatus.vue'
import ProcessModalAction from './ModalAction.vue'

export default {
  name: 'Status',
  components: { ProcessModalAction, ProcessModalStatus, ProcessModalActor, },
  props: {
    status: { type: Object, required: true, },
    process: { type: Object, required: true, },
    variants: { type: Object, required: true, },
    'update:process': { type: Function, default: (value) => {}, },
  },
  computed: {
    actors() {
      return this.process.actions[this.status.id]
    },
  },
  methods: {
    removeActor(name) {
      const process = $n.cloneDeep(this.process)
      $n.unset(process.actions[this.status.id], name)

      this.$emit('update:process', process)
    },
    removeAction(actorName, i) {
      const process = $n.cloneDeep(this.process)
      process.actions[this.status.id][actorName].splice(i, 1)

      this.$emit('update:process', process)
    },
    getTitle(obj) {
      return obj?.title || '!Ошибка!'
    },
    getValuesTitle(valuesName, v) {
      const actors = $n.reduce(this.process.actors, (result, item, name) => {
        result[name] = ({ id: name, title: item.title, })
        return result
      }, [])
      const container = valuesName === 'actors' ? actors : this.variants?.values && this.variants?.values[valuesName]
      return $n.get(container, v+'.title') || '!Ошибка!'
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  position: relative;
  margin: 0 10px 10px 0;
  border: 1px solid #ddd;
  &.start {
    border-color: var(--primary);
  }
  &>*:last-child {
    border-bottom: none !important;
  }

  .hint {
    display: inline-block; /* Строчно-блочный элемент */
    position: relative;
    .hint-action {
      position: relative;
      cursor: help;
      font-size: .7em;
      margin-left: 3px;
      &:hover+.hint-content {
        display: block;
      }
    }
    .hint-content {
      display: none;
      width: 200px;
      position: absolute;
      left: -100px;
      bottom: 100%;
      z-index: 1;
      background: rgba(255,255,230,0.9);
      font-size: .9em;
      padding: 5px 10px;
      border: 1px solid #333;
    }
  }
  
  .title-block {
    background: rgba(127, 127, 127, .05);
    font-size: .9em;
    .title {
      font-size: 1.1em;
      font-weight: bold;
    }
  }
  .actor-block {
    color: #777;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    line-height: 1;
    &>* {
      margin-right: 5px;
    }
    .actor {
      margin-bottom: 5px;
    }
  }
  .action-block {
    font-size: .9em;
    .action {
    }
    &>* {
      display: inline-block;
    }
    .event-block {
      margin-left: 4px;
      font-size: .8em;
    }
  }
  .when-block {
    font-size: .8em;
    font-style: italic;
    margin-left: 3px;
  }

  .row {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  .editable {
    color: #000;
    border-bottom: 1px dotted #000;
    cursor: pointer;
  }
  .deletable {
    color: #000;
    border-bottom: 1px dotted #000;
    cursor: pointer;
    &:hover {
      text-decoration: line-through;
    }
  }
  .message {
    font-size: .9em;
    font-style: italic;
    opacity: .8;
  }

  .add-button {
    font-size: .8em;
    display: flex;
    align-items: center;
    line-height: 1;
    color: var(--primary);
    cursor: pointer;
    opacity: .6;
    &:hover {
      opacity: 1;
    }
    .n-icon {
      margin-right: 4px;
      font-size: .9em;
    }
  }

}
</style>
