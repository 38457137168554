<template>
  <div class="project-detail">
    <div class="project-title">
      <n-loader :loading="$var('load')" />
      <h2>
        {{ project.title }}
        <span v-if="project.isTemplate" style="font-size: .9rem; opacity: .9; color: #825809;">Шаблон</span>
        <span v-if="project.archivedAt" style="font-size: .9rem; opacity: .9; color: #825809;">Переносится в архив</span>
      </h2>
      <n-items v-if="project.id">
        <n-button v-if="!project.archivedAt && !project.isTemplate && $app.auth.can('project_archive', projectId)" @click="toArchive">В архив</n-button>
        <n-button v-if="project.archivedAt && $app.auth.can('project_archive', projectId)" @click="fromArchive">Восстановить из архива</n-button>
        <!--          <n-button v-if="!project.archivedAt && !project.isTemplate" @click="createRevision">Создать ревизию</n-button>-->
        <n-button v-if="!project.archivedAt && !project.isTemplate && $app.auth.can('project_template', projectId)" @click="toggleTemplate">Пометить как шаблон</n-button>
      </n-items>
    </div>

    <div class="cards">
      <n-card>
        <h3>Атрибуты проекта</h3>
        <modal-detail-attributes type="folder" :selected="project.folder" :loading="$var('loadAttr')" @update:loading="(v) => $var('loadAttr', v)" />
      </n-card>
      <document-code :project="project" />

      <n-card>
        <c-accesses :model="project.folder" type="folder" root :editable="$app.auth.can('folder_users', project.folderId)" />
      </n-card>
    </div>

    <card-delete-project v-if="project.id && $app.auth.can('project_manager', project.id)" :project="project" @submit="remove" />
  </div>
</template>

<script>
import DocumentCode from './DocumentCode'
import ModalDetailAttributes from 'pages/filesNew/fs/details/Attributes.vue'
import CardDeleteProject from './CardDeleteProject.vue'

export default {
  name: 'ProjectDetail',
  components: { CardDeleteProject, ModalDetailAttributes, DocumentCode, },
  data() {
    return {
      project: {
        folder: {},
      },
    }
  },
  computed: {
    projectId() {
      return this.$route.params.id
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.fs.projects.get(this.projectId).with('folder', (q) => q.with('attributes')).then((response) => {
        this.project = response.data.content
        $app.router.set(this.$route.name, {
          title: this.project.title,
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    toArchive() {
      this.$var('load', true)
      $api.fs.projects.archive.to(this.projectId).then((response) => {
        this.load()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    fromArchive() {
      this.$var('load', true)
      $api.fs.projects.archive.from(this.projectId).then((response) => {
        this.load()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    createRevision() {
      this.$var('load', true)
      $api.fs.projects.revision.create(this.projectId).then((response) => {
        this.load()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    toggleTemplate() {
      this.$var('load', true)
      const data = {
        isTemplate: !this.project.isTemplate,
      }
      $api.fs.projects.edit(this.project.id, data).then((response) => {
        this.load()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    remove() {
      this.$router.push({ name: 'projects.list', })
    },
  },
}
</script>

<style scoped lang="scss">
.project-detail {

  .cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    .n-card {
      width: 100%;
      max-width: 600px;
    }
  }

  .card-delete-project {
    margin: 0;
  }

  .project-title {
    position: relative;
    min-height: 50px;
  }

  .tools {
    .n-button {
      font-size: .8em;
      &:first-child {
        margin-right: 5px;
      }
    }
  }
}
</style>
