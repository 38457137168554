<template>
  <div class="fs-table">
    <n-loader :loading="loading" />
    <draggable :name="name" @drop="onDrop">
      <template #content="{ onDrag, onDragEnd, }">
        <div id="table-wrapper" ref="wrapper" class="table-wrapper" @click="click($event)" @contextmenu="rightClick($event, opened)">
          <div ref="headerAnchor"></div>
          <div class="table">
            <div ref="header" class="row header">
              <div v-for="col in columns" :key="col.name" :class="[ 'cell', col.name, ]">
                {{ col.title }}
              </div>
            </div>
            <div v-for="item in data" :key="item.id+''+item.model" class="row" :class="[ {selected: isSelected(item), }]" draggable="true"
                 @dragstart="onDrag($event, item, name)" @dragend="onDragEnd"
                 @click.stop="click($event, item)" @contextmenu="rightClick($event, item)" @dblclick="dblclick($event, item)">
              <div v-for="col in columns" :key="col.name" :class="[ 'cell', col.name, ]">
                <template v-if="col.name === 'updatedAt'">
                  {{ $app.date.format(item.updatedAt || item.createdAt) }}
                </template>
                <template v-else-if="col.name === 'size'">
                  {{ $n.fileSize(item.size) }}
                </template>
                <template v-else-if="col.name === 'title'">
                  <div class="model-title">
                    <div class="icon">
                      <n-icon :icon="item.ext ? $n.fileIcon(item.ext) : 'folder'" />
                    </div>
                    <div :class="['content', {'useCode':item.useCode}]">
                      {{ item.ext ? item.fullTitle : item.title }}<template v-if="item.ext">.{{ item.ext }}</template>
                      <span v-if="item.takerId" class="info">[Редактируется: {{ item.taker.fullName }}]</span>
                    </div>
                    <div class="tools"><n-icon v-if="item.takerId" icon="lock" /></div>
                  </div>
                </template>
                <template v-else>
                  {{ item[col.name] }}
                </template>
              </div>
            </div>
            <div ref="footer" class="footer-shadow"></div>
          </div>
          <div ref="footerAnchor"></div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import Draggable from './Draggable'

export default {
  name: 'FsTable',
  components: { Draggable, },
  props: {
    data: { type: Array, required: true, },
    opened: { type: Object, default: null, },
    selected: { type: Object, default: null, },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      name: Math.random(),
      columns: [
        { name: 'title', title: 'Название', },
        { name: 'updatedAt', title: 'Дата изменения', },
        { name: 'size', title: 'Размер', },
      ],
      drag: false,
      dragTimeOut: null,
      stickyObserverHeader: null,
      stickyObserverFooter: null,
    }
  },
  mounted() {
    this.stickyObserverHeader = new IntersectionObserver(
      ([ e, ]) => this.$refs.header.toggleAttribute('stuck', e.intersectionRatio < 1),
      { root: this.$refs.wrapper, threshold: [ 0, 1, ], }
    )
    this.stickyObserverFooter = new IntersectionObserver(
      ([ e, ]) => this.$refs.footer.toggleAttribute('stuck', e.intersectionRatio < 1),
      { root: this.$refs.wrapper, threshold: [ 0, 1, ], }
    )
    this.stickyObserverHeader.observe(this.$refs.headerAnchor)
    this.stickyObserverFooter.observe(this.$refs.footerAnchor)
  },
  beforeDestroy() {
    this.stickyObserverHeader.unobserve(this.$refs.headerAnchor)
    this.stickyObserverFooter.unobserve(this.$refs.footerAnchor)
  },
  methods: {
    isSelected(item) {
      if (this.selected) {
        return (this.selected.id === item.id) && (this.selected.model === item.model)
      }
      return false
    },
    click(event, item) {
      this.$emit('click', event, item)
    },
    rightClick(event, item) {
      this.$emit('contextmenu', event, item)
    },
    dblclick(event, item) {
      this.$emit('dblclick', event, item)
    },
    getFileSize(size) {
      const i = Math.floor( Math.log(size) / Math.log(1024) )
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + [ 'B', 'kB', 'MB', 'GB', 'TB', ][i]
    },
    onDrop(event, item) {
      this.$emit('drop', event, item)
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-table {
  position: relative;
  border-right: 1px solid var(--fs-border-color);

  .table-wrapper {
    overflow-y: auto;
    min-height: 400px;
  }

  .table {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
  .row {
    display: table-row;
    &:not(.header) {
      cursor: pointer;
      transition: background-color .2s;
    }
    &:not(.header):not(.selected) {
      &:hover {
        background: var(--fs-border-color);
      }
    }
    &.selected {
      background: var(--primary-t-10);
    }
    &.header {
      position: sticky;
      top: 0;
      z-index: 2;
      display: table-header-group;
      &[stuck] {
        box-shadow: 0 3px 5px -2px #8a8a8a54;
      }
      .cell {
        background: #fafafa;
        font-weight: 300;
        font-size: .8em;
        opacity: 1;
        padding: 6px 16px 6px;
      }
    }
  }
  .footer-shadow {
    position: sticky;
    display: table-footer-group;
    bottom: 0;
    width: 100%;
    &[stuck] {
      box-shadow: 0 3px 5px 4px #8a8a8a54;
    }
  }
  .cell {
    display: table-cell;
    vertical-align: top;
    padding: 4px 16px;
    border: var(--n-table-border);
    border-width: 0 var(--n-table-border-right-width)
    0 var(--n-table-border-left-width);
  }


  .title {
    border-right: 1px solid var(--fs-border-color);
  }
  .model-title {
    display: flex;
    justify-content: flex-start;
    .icon {
      margin-right: 8px;
      font-size: .9em;
      opacity: .8;
      width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content {
      font-size: .9em;
      .info {
        font-size: .8em;
        opacity: .5;
        margin: 0 12px 0 10px;
      }
    }
    .tools {
      opacity: .3;
      font-size: .9em;
    }
    .useCode {
      font-weight: bold;
    }
  }
  .size {
    width: 100px;
    &:not(.header) {
      font-size: .9em;
      opacity: .9;
    }
    text-align: right;
  }
  .updatedAt {
    width: 160px;
    border-right: 1px solid var(--fs-border-color);
    &:not(.header) {
      font-size: .9em;
      opacity: .9;
    }
    text-align: right;
  }
}
</style>
