<template>
  <div class="widget-card" :class="[ widget.report.type, ]">
    <div class="title">{{ widget.title }}</div>
    <div class="tools">
      <n-icon icon="pen" @click="$var('edit', widget)" />
      <n-icon icon="trash" @click="remove" />
    </div>
    <c-report-card :report="report" :data="data" :loading="$var('load')" headless />

    <modal-edit-widget v-if="$var('edit')" :widget="$var('edit')" @submit="$emit('reload')" @close="$var('edit', false)" />
  </div>
</template>

<script>
import ModalEditWidget from './ModalEditWidget'

export default {
  name: 'WidgetCard',
  components: { ModalEditWidget, },
  props: {
    widget: { type: Object, required: true, },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      report: {},
      data: [],
    }
  },
  watch: {
    widget() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.reports.data(this.widget.reportId).then((response) => {
        this.report = response.data.report
        this.data = response.data.content
        $app.router.set({ title: this.report.title, })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    remove() {
      this.$var('load', true)
      $api.profile.widgets.delete(this.widget.id).then((response) => {
        this.$emit('reload')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-card {
  display: inline-block;
  position: relative;
  vertical-align: top;
  &::v-deep {
    .c-report-card {
      min-width: 500px;
    }
    &.pie {
      .c-report-card {
        min-width: 350px;
        width: 350px;
      }
    }
  }

  .title {
    position: absolute;
    top: 35px;
    left: 45px;
    z-index: 2;
    font-size: 1.2em;
  }

  .tools {
    position: absolute;
    right: 45px;
    top: 35px;
    z-index: 2;
    font-size: .9em;

    .n-icon {
      margin-left: 20px;
      opacity: .2;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
