<template>
  <div class="n-checkbox" :title="title">
    <label :for="name">
      <input :id="name" :name="name" :value="val" :checked="checked" type="checkbox" :disabled="text" @change="click" />
      <span class="title"><slot /></span>
    </label>
    <div v-if="danger" class="danger-style">
      {{ danger }}
    </div>
  </div>
</template>

<script>
import isObject from 'lodash/isObject'
import isBoolean from 'lodash/isBoolean'
import get from 'lodash/get'

export default {
  name: 'NCheckbox',
  props: {
    title: { type: String, default: '', },
    name: { type: String, default: () => Math.random() + '', },
    value: { type: [ String, Boolean, Object, ], default: false, },
    val: { type: [ String, Boolean, Object, ], default: null, },
    danger: { type: String, default: '', },
    text: { type: Boolean, default: false, },
    itemValue: { type: String, default: 'value', },
    input: { type: Function, default: (value) => {}, },
    'update:value': { type: Function, default: (value) => {}, },
  },
  computed: {
    checked() {
      if (isObject(this.val)) {
        return get(this.val, this.itemValue) === get(this.value, this.itemValue, Math.random())
      } else if (isBoolean(this.value)) {
        return this.value
      }
      return this.val === this.value
    },
  },
  methods: {
    click() {
      const val = this.val === null ? !this.value : this.val
      this.$emit('input', val)
      this.input(val)
      this.$emit('update:value', val)
      this['update:value'](val)
    },
  },
}
</script>

<style lang="scss" scoped>
.n-checkbox {
  cursor: pointer;

  label {
    padding: 2px 0;
    opacity: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  input[type='checkbox'] {
    width: 1.4em;
    height: 1.4em;
    color: var(--primary);
    cursor: pointer;
  }
  .title {
    display: inline-block;
    padding-left: 7px;
    font-size: .9em;
    line-height: 1;
  }
  .danger-style{
    color: red;
  }
}
</style>
