<template>
  <c-page-sidebar class="layout-settings">
    <template #sidebar>
      <n-link v-for="item in menu" :key="item.title" :to="item.route" class="sidebar-link">
        <div class="menu-icon"><n-icon :icon="item.icon" /></div> {{ item.title }}
      </n-link>
    </template>
    <template #content>
      <router-view />
    </template>
  </c-page-sidebar>
</template>

<script>
export default {
  name: 'LayoutSettings',
  data() {
    return {
      menu: [
        { route: 'settings.users.index', title: 'Пользователи', icon: 'users', },
        { route: 'projects.list', title: 'Уведомления', icon: 'bell', },
        { route: 'projects.list', title: 'Журнал действий', icon: 'file', },
        { route: 'projects.list', title: 'Файловая система', icon: 'folder', },
        { route: 'settings.tasks.templates', title: 'Шаблоны задач', icon: 'tasks', },
        { route: 'settings.tasks.statuses', title: 'Статусы задач', icon: 'tasks', },
        { route: 'settings.projects.templates', title: 'Шаблоны проектов', icon: 'project-diagram', },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.layout-settings {
  .sidebar-link {
    &.link-active, &.link-exact-active {

    }
  }
  .menu-icon {
    display: inline-flex;
    width: 20px;
    justify-content: center;
  }
}
</style>
