<template>
  <n-modal class="modal-column" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Сортировка" :data="columns" v-bind="$form.input('column', 'select')" />
        <n-select title="Направление" :data="Object.values(ui._general.orders)" v-bind="$form.input('uiOrder', 'select')" />
        <n-button type="submit" color="success" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
import { getBuilder, } from '../../utils'

export default {
  name: 'ModalSort',
  props: {
    sort: { type: Object, required: true, },
    params: { type: Object, required: true, },
    columns: { type: Array, required: true, },
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    'update:params': { type: Function, default: (params) => {}, },
  },
  computed: {
    isNew() {
      return !this.sort.name
    },
  },
  created() {
    if (this.isNew) {
      this.$form.init({
        column: null,
        uiOrder: this.ui._general.orders['asc'],
      })
    } else {
      this.$form.init({
        column: this.sort.column,
        uiOrder: this.sort.uiOrder,
      })
    }
    this.$form.rules({
      column: [ 'required', ],
      uiOrder: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        const builder = getBuilder(this.params)
        builder.sort(this.$form.get('column.name'), this.$form.get('uiOrder.id'))

        this.$emit('update:params', builder.params())
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-column {
}
</style>
