<template>
  <div class="page-reports-index">
    <c-report-card :report="report" :data="data" :editable="editable" :loading="$var('load')" @reload="reload" />
  </div>
</template>

<script>
export default {
  name: 'PageReportsIndex',
  data() {
    return {
      report: {},
      data: [],
    }
  },
  computed: {
    reportId() {
      return this.$route.params.id
    },
    editable() {
      return this.report.authorId === $app.auth.user().id
    },
  },
  watch: {
    reportId() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.reports.data(this.reportId).then((response) => {
        this.report = response.data.report
        this.data = response.data.content
        $app.router.set({ title: this.report.title, })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    reload() {
      this.load()
      this.$emit('reload')
    },
  },
}
</script>

<style lang="scss" scoped>
.page-reports-index {
  .c-report-card {
    max-width: 1200px;
  }
}
</style>
