<template>
  <div class="c-sorts">
    <div v-if="title" class="block-title">{{ title }}</div>

    <div v-for="value in parsedSorts" :key="value.name" class="query-item">
      <span class="query-item-content" @click="selected = value">
        <span class="title">{{ value.column.title }}</span>
        <span class="method">{{ value.uiOrder.title }}</span>
      </span>
      <span class="remove-button" @click="remove(value.name)">удалить</span>
    </div>

    <div v-if="!parsedSorts.length" class="empty-message">Сортировок нет</div>

    <div class="add-button" @click="selected = {}"><n-icon icon="plus" /> добавить</div>
    
    <modal-sort v-if="selected" :sort="selected" :params="params" :columns="columns" :ui="ui" :model="model"
                @update:params="(v) => $emit('update:params', v)" @close="selected = false" />
  </div>
</template>

<script>
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import ModalSort from './ModalSort'

export default {
  name: 'CSorts',
  components: { ModalSort, },
  props: {
    params: { type: Object, required: true, },
    columns: { type: Array, required: true, },
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    title: { type: String, default: '', },
  },
  data() {
    return {
      selected: false,
    }
  },
  computed: {
    columnsByName() {
      return reduce(this.columns, (result, item) => {
        result[item.name] = item
        return result
      }, {})
    },
    parsedSorts() {
      return map(this.params.sort, (order, name) => {
        return {
          name,
          order,
          column: this.columnsByName[name],
          uiOrder: this.ui._general.orders[order],
        }
      })
    },
  },
  methods: {
    add(name, value) {
      this.builder.sort(name, value)
    },
    remove(name) {
      this.builder.remove('sort', name)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-sorts {
  width: 100%;
  .block-title {
    opacity: .5;
    font-size: .8em;
  }

  .query-item {
    margin: 4px 0 8px;
    line-height: 1;
  }
  .query-item-content {
    border-bottom: 1px dotted #000;
    cursor: pointer;
    .title {}
    .method {
      display: inline-block;
      margin-left: 10px;
      opacity: .6;
      text-transform: lowercase;
    }
    .values {
      margin-left: 10px;
      font-style: italic;
      .value {
        color: var(--primary);
      }
      .or {
        font-size: .8em;
      }
    }
  }

  .add-button {
    display: inline-block;
    color: var(--primary);
    font-size: .8em;
    opacity: .6;
    cursor: pointer;
    transition: opacity .2s;
    &:hover {
      opacity: 1;
    }
  }
  .remove-button {
    display: inline-block;
    margin-left: 10px;
    font-size: .8em;
    opacity: .2;
    cursor: pointer;
    &:hover {
      opacity: 1;
      color: var(--danger);
    }
  }

  .empty-message {
    padding: 6px 0;
    font-style: italic;
    opacity: .7;
    font-size: .8em;
  }
}
</style>
