import Vue from 'vue'
import NastCore from 'nast'
import settings from './_config'
import libs from './_libs'
import ui from './_ui'
import Api from './miscs/api-plug'
import components from './components'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'

Vue.use(Api, { timeout: 500, })
Vue.use(VueToast)

Vue.use(ui)
Vue.mixin({
  components,
})
NastCore.createApp(settings, libs)
