<template>
  <div class="c-filters">
    <div v-if="title" class="block-title">{{ title }}</div>

    <div v-for="value in parsedFilters" :key="value.name" class="query-item">
      <span class="query-item-content" @click="selected = value">
        <span class="title">{{ value.uiFilter.title }}</span>
        <span class="method">{{ value.uiMethod.title }}</span>
        <span class="values">
          <span v-for="(v, index) in value.values" :key="v">
            <span class="value">{{ v }}</span>
            <span v-if="index < value.values.length-1" class="or"> или </span>
          </span>
        </span>
      </span>
      <span class="remove-button" @click="remove(value.name)">удалить</span>
    </div>

    <div v-if="!parsedFilters.length" class="empty-message">Фильтров нет</div>

    <div class="add-button" @click="selected = {}"><n-icon icon="plus" /> добавить</div>

    <modal-filter v-if="selected" :filter="selected" :params="params" :ui="ui" :model="model"
                  @update:params="(v) => $emit('update:params', v)" @close="selected = null" />
  </div>
</template>

<script>
import map from 'lodash/map'
import { getBuilder, } from '../../utils'
import ModalFilter from './ModalFilter'

export default {
  name: 'CFilters',
  components: { ModalFilter, },
  props: {
    params: { type: Object, required: true, }, // RequestBuilder.params()
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    title: { type: String, default: '', },
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    parsedFilters() {
      return map(this.params.filter, (value, name) => {
        const parts = value.split(':')
        const filterId = name.split(':')[0]

        return {
          name,
          value,
          values: parts[1].split(','),
          uiFilter: this.ui[this.model].filters[filterId],
          uiMethod: this.ui._general.methods[parts[0]],
        }
      })
    },
  },
  methods: {
    remove(name) {
      const builder = getBuilder(this.params)
      builder.remove('filter', name)
      this.$emit('update:params', builder.params())
    },
    // load(dataKey, id) {
    //   const selectData = [ ...this.selectData, ]
    //   if (dataKey) {
    //     this.$var('load', true)
    //     $api.reports.select(this.model, dataKey, id).then((response) => {
    //       selectData[dataKey+'.'+id] = response.data.content
    //     }).finally(() => {
    //       this.$var('load', false)
    //     })
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped>
.c-filters {
  width: 100%;
  .block-title {
    opacity: .5;
    font-size: .8em;
  }

  .query-item {
    margin: 4px 0 8px;
    line-height: 1;
  }
  .query-item-content {
    border-bottom: 1px dotted #000;
    cursor: pointer;

    .title {}
    .method {
      display: inline-block;
      margin-left: 10px;
      opacity: .6;
      text-transform: lowercase;
    }
    .values {
      margin-left: 10px;
      font-style: italic;
      .value {
        color: var(--primary);
      }
      .or {
        font-size: .8em;
      }
    }
  }

  .add-button {
    display: inline-block;
    color: var(--primary);
    font-size: .8em;
    opacity: .6;
    cursor: pointer;
    transition: opacity .2s;
    &:hover {
      opacity: 1;
    }
  }
  .remove-button {
    display: inline-block;
    margin-left: 10px;
    font-size: .8em;
    opacity: .2;
    cursor: pointer;
    &:hover {
      opacity: 1;
      color: var(--danger);
    }
  }

  .empty-message {
    padding: 6px 0;
    font-style: italic;
    opacity: .7;
    font-size: .8em;
  }
}
</style>
