<template>
  <div class="n-chart-table">
    <n-table :data="data" :columns="columns">
      <template v-for="column in columns" :slot="column.name" slot-scope="{item}">
        <template v-if="column.type === 'datetime'">
          {{ $app.date.format(item[column.name]) }}
        </template>
        <template v-else-if="column.type === 'boolean'">
          {{ item[column.name] ? 'Да' : 'Нет' }}
        </template>
        <template v-else>
          {{ item[column.name] }}
        </template>
      </template>
    </n-table>
  </div>
</template>

<script>
export default {
  name: 'NChartTable',
  props: {
    data: { type: Array, required: true, },
    columns: { type: Array, required: true, }, // [ { name: '', title: '', }, ]
  },
  data() {
    return {

    }
  },
  computed: {
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.n-chart-table {

}
</style>
