<template>
  <div class="c-page-sidebar">
    <div class="sidebar">
      <slot name="sidebar" />
    </div>
    <div class="content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPageSidebar',
}
</script>

<style lang="scss" scoped>
.c-page-sidebar {
  padding: 0;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;

  .sidebar {
    background: rgba(26, 144, 208, .04);
    padding: 20px;
    position: relative;
    border-right: 1px solid rgba(127, 127, 127, .1);
  }

  .content {
    padding: 20px;
  }

  &::v-deep {
    .main-button {
      color: var(--success);
      font-size: .9em;
    }
    .block-title {
      margin: 20px 0 7px;
      opacity: .6;
      font-size: .7em;
      font-weight: bold;
      text-transform: uppercase;
      &:first-child {
        margin-top: 0;
      }
    }
    .sidebar {
      .empty-message {
        font-style: italic;
        opacity: .8;
        font-size: .8em;
        padding-left: 15px;
        margin: 3px 0;
      }
      .sidebar-link {
        color: inherit;
        display: block;
        border: 1px solid transparent;
        padding: 3px 15px;
        margin: 3px 0;
        text-decoration: none;

        .n-icon {
          opacity: .5;
          font-size: .9em;
          margin-right: 7px;
        }

        &.active, &.link-active {
          border: 1px solid var(--primary-t-10);
          border-radius: 5px;
          background: var(--primary);
          color: var(--primary-text)
        }
      }
    }
  }
}
</style>
