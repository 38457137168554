<template>
  <div class="page-notifies">
    <n-loader :loading="$var('load')" />

    <div class="notifies">
      <div v-for="item in notifies" :key="item.id" class="notify" :class="[ item.type, ]" @click="push(item)">
        <div class="date">{{ $app.date.format(item.createdAt) }}</div>
        <div class="content">{{ item.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      notifies: [],
      models: {
        task: (model) => ({ name: 'tasks.list', params: { projectId: model.projectId, }, query: { task: model.id, }, }),
      },
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.fs.notifies.get().with('model').sort('createdAt', 'desc').then((response) => {
        this.notifies = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    push(item) {
      this.$router.push(this.models[item.modelType](item.model))
    },
  },
}
</script>

<style lang="scss" scoped>
.page-notifies {
  .notifies {
    margin-top: 20px;
    .notify {
      padding: 5px 10px;
      margin: 5px 0;
      cursor: pointer;
      border: 1px solid #eee;
      background: #fff;

      &.critical {
        background: #fce6e6;
      }

      .date {
        display: inline-block;
        font-size: 0.8em;
        opacity: .9;
        margin-right: 20px;
      }
      .content {
        font-size: .9em;
        display: inline-block;
      }
    }
  }
}
</style>
