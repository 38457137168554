<template>
  <div class="n-stepper">
    <div v-for="(item, index) in data" :key="item.name" class="item" :class="[ { 'n-active': item.name === s_active, }, ]" @click="change(item.name)">
      <div class="icon">
        <n-icon v-if="item.icon" :icon="item.icon" />
        <div>{{ index + 1 }}</div>
      </div>
      <div class="title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NStepper',
  props: {
    data: { type: Array, required: true, }, // { title: '', name: '', icon: '', }
    active: { type: String, default: undefined, },
    name: { type: String, default: 'step', },
    state: { type: Boolean, default: false, },
  },
  data() {
    return {
      s_active: undefined,
    }
  },
  computed: {
    query: {
      get() {
        return this.$route.query[this.name]
      },
      set(value) {
        if (this.state && this.$route.query[this.name] !== value) {
          this.$router.push({ query: { ...this.$route.query, [this.name]: value, }, })
        }
      },
    },
  },
  watch: {
    active(value) {
      this.s_active = value
    },
    query(value) {
      if (this.state && value && value !== this.s_active) {
        this.change(value)
      }
    },
  },
  created() {
    this.change(this.active === undefined ? (this.query || this.data[0].name) : this.active)
  },
  methods: {
    change(name) {
      if (this.active === undefined) {
        this.s_active = name
      } else if (this.active !== name) {
        this.$emit('update:active', name)
      }
      if (this.state) {
        this.query = name
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.n-stepper {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;

  .item {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    &:after, &:before {
      content: "";
      position: absolute;
      background: #ccc;
      height: 1px;
      right: 0;
      left: 0;
      top: 14px;
    }
    &:after {
      left: calc(50% + 30px);
    }
    &:before {
      right: calc(50% + 30px);
    }
    &:first-child:before, &:last-child:after {
      background: transparent;
    }

    .icon {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: #999;
      color: #eee;
      display: flex;
      align-items: center;
      text-align: center;
      &>* {
        font-size: .9em;
        line-height: 1;
        width: 100%;
      }
    }
    .title {
      margin-top: 3px;
      font-size: .9em;
    }

    &.n-active {
      .icon {
        background: var(--primary);
        color: var(--primary-text);
      }
      .title {
        color: var(--primary);
      }
    }
  }
}
</style>
