<template>
  <div class="n-datepicker" :class="[ { 'n-inline': inline, }, ]">
    <input ref="input" />
    <n-input v-bind="bind" v-on="$listeners" @click="s_click" />
  </div>
</template>

<script>
import datepicker from 'flatpickr'
import { Russian, } from 'flatpickr/dist/l10n/ru.js'
import props from 'nast-ui/components/Input/props'

export default {
  name: 'NDatePicker',
  mixins: [ props, ],
  props: {
    mask: { type: Object, default: undefined, },
    maskStatus: {
      type: Function, default: () => {
      },
    },
  },
  data: () => ({
    picker: null,
  }),
  computed: {
    bind() {
      return { ...this.props, ...this.events, mask: this.mask, maskStatus: this.maskStatus, }
    },
  },
  watch: {
    value() {
      this.picker.setDate(this.value)
    },
  },
  mounted() {
    this.picker = datepicker(this.$refs.input, {
      'locale': Russian,
      dateFormat: 'd.m.Y',
      onChange: (selectedDates, dateStr, instance) => {
        this.$emit('input', dateStr)
        this['input'](dateStr)
        this.$emit('update:value', dateStr)
        this['update:value'](dateStr)
      },
    })
    this.picker.setDate(this.value)
  },
  methods: {
    s_click() {
      this.picker.open()
    },
  },
}
</script>

<style lang="css" src="flatpickr/dist/flatpickr.min.css"></style>
<style lang="scss">
  .flatpickr-calendar {
    .flatpickr-months {
      padding: 8px 0;
      background: #EAEDF5;
      
      .flatpickr-prev-month, .flatpickr-next-month {
        top: 4px;
      }
      
      .flatpickr-monthDropdown-months {
        margin-right: 10px;
      }
      
      .flatpickr-monthDropdown-months, .numInput.cur-year {
        color: var(--primary);
        font-weight: 600;
        font-size: 14px;
      }
      
      .flatpickr-current-month {
        left: 16.5%;
      }
      
      svg {
        padding: 3px;
        width: 20px;
        height: 20px;
        background: var(--primary);
        border-radius: 100%;
        fill: white;
      }
      
      span:hover svg {
        fill: white;
      }
    }
    
    .flatpickr-weekdays {
      padding: 10px 0 5px;
    }
    
    .flatpickr-weekday {
      font-size: 14px;
      color: var(--primary);
      font-weight: 600;
    }
    
    .flatpickr-day {
      font-size: 14px;
      font-weight: 600;
      border-radius: 5px;
      height: 28px;
      margin: 5px 0;
      line-height: 26px;
      
      &.selected {
        color: inherit !important;
        background: #00AAFF !important;
      }
      
      &.today {
        background: #f0f6ff;
        border: none;
        
        &:hover, &:focus {
          color: inherit !important;
        }
      }
    }
  }
  
  .n-datepicker {
    display: block;
    width: 100%;
    &.n-inline {
      display: inline-block;
      width: auto;
    }
    
    & > input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
    
  }
</style>
