<template>
  <div class="fs-tools">
    <div v-for="item in contextMenu" :key="item.name">
      <n-button :icon="item.icon" flat @click="$emit('action', item.name, selected)">{{ item.title }}</n-button>
    </div>
    <n-dropdown ref="contextMenu" :data="contextMenu" @select="(item) => $emit('action', item.name, selected)">
      <span ref="contextMenuSpan"></span>
    </n-dropdown>
  </div>
</template>

<script>
export default {
  name: 'FsTools',
  props: {
    opened: { type: Object, default: null, },
    selected: { type: Object, default: null, },
    category: { type: String, default: null, },
    contextEvent: { type: PointerEvent, default: null, },
  },
  data() {
    return {
      availableMenu: {
        take: { name: 'take', title: 'Взять файл', icon: 'hand', },
        retrieve: { name: 'retrieve', title: 'Вернуть файл', icon: 'file-arrow-up', },
        return: { name: 'return', title: 'Разблокировать файл', icon: 'unlock', },
        renameDocument: { name: 'renameDocument', title: 'Переименовать', icon: 'edit', },
        renameFolder: { name: 'renameFolder', title: 'Переименовать', icon: 'edit', },
        revision: { name: 'revision', title: 'Создать ревизию', icon: 'copy', },
        // attributes: { name: 'attributes', title: 'Свойства', icon: 'gears', },
        // versions: { name: 'versions', title: 'Версии', icon: 'code-compare', },
        // permits: { name: 'permits', title: 'Доступы', icon: 'lock', },
        // history: { name: 'history', title: 'История', icon: 'timeline', },
        // tasks: { name: 'tasks', title: 'Задания', icon: 'tasks', },
        delete: { name: 'delete', title: 'Удалить', icon: 'trash', },
        addFolder: { name: 'addFolder', title: 'Создать папку', icon: 'folder', },
        addDocument: { name: 'addDocument', title: 'Загрузить документ', icon: 'file', },
      },
    }
  },
  computed: {
    contextMenu() {
      const result = []
      if (!this.opened || !this.selected) {
        return result
      }

      if (this.opened.id === this.selected.id) {
        if ($app.auth.can('folder_write', this.opened.id)) {
          result.push(this.availableMenu.addFolder)
        }
        if ($app.auth.can('folder_write', this.opened.id)) {
          result.push(this.availableMenu.addDocument)
        }
      } else {
        if (this.selected?.src) {
          if (this.category === 'projects' || this.category === 'manager') {
            if (!this.selected?.takerId &&
                $app.auth.can('folder_write', this.opened.id) &&
                ![ 'wait', 'done', ].includes(this.selected.status) &&
                [ 'edit', 'manage', ].includes(this.selected.access)) {
              result.push(this.availableMenu.take)
            } else if (this.selected?.takerId === $app.auth.user().id) {
              result.push(this.availableMenu.retrieve)
            }
            if ($app.auth.can('project_manager') && this.selected?.takerId) {
              result.push(this.availableMenu.return)
            }
          }

          if ($app.auth.can('folder_write', this.opened.id) && [ 'edit', 'manage', ].includes(this.selected.access)) {
            result.push(this.availableMenu.renameDocument)
          }
        } else {
          if ($app.auth.can('folder_write', this.opened.id)) {
            result.push(this.availableMenu.renameFolder)
          }
          if (this.category === 'projects' && $app.auth.can('folder_revision', this.selected.id)) {
            result.push(this.availableMenu.revision)
          }
        }

        // result.push(this.availableMenu.attributes)
        if (this.selected?.src) {
          // result.push(this.availableMenu.versions)
        }
        // result.push(this.availableMenu.permits)
        // result.push(this.availableMenu.history)
        // result.push(this.availableMenu.tasks)
        if ($app.auth.can('folder_write', this.selected.id)) {
          result.push(this.availableMenu.delete)
        }
      }

      return result
    },
  },
  watch: {
    contextEvent() {
      if (this.contextEvent) {
        this.$refs.contextMenuSpan.click()
        this.$refs.contextMenu.$el.style.position = 'fixed'
        this.$refs.contextMenu.$el.style.top = this.contextEvent.clientY + 'px'
        this.$refs.contextMenu.$el.style.left = this.contextEvent.clientX + 'px'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-tools {
  padding: 4px 15px 4px 5px;
  background: #fff;
  border: 1px solid var(--fs-border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: .8em;
  height: 40px;

  .n-dropdown {
    z-index: 99999;
  }
  &>div {
    margin-right: 10px;
  }
}
</style>
