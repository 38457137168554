<template>
  <n-modal :loading="$var('load')" class="modal-edit-template" @close="$emit('close')">
    <div v-if="!isNew" class="remove-block">
      <n-button round icon="trash" @click="remove" />
    </div>

    <h3>Редактирование шаблона</h3>

    <n-form @submit="submit">
      <n-items>
        <n-input title="Заголовок" v-bind="$form.input('title')" />
        <n-checkbox :value="subprocess !== null" @update:value="toggleSubprocess">создать подзадачи для отделов</n-checkbox>
      </n-items>

      <div class="processes">
        <c-process :process.sync="process" :variants="variants" title="Процесс задачи" />
        <c-process v-if="subprocess" :process.sync="subprocess" :variants="variants" title="Процесс подзадач" />
      </div>

      <n-divide>
        <n-button>Отмена</n-button>
        <n-button color="success" type="submit">Сохранить</n-button>
      </n-divide>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalEditTemplate',
  props: {
    item: { type: [ Object, Boolean, ], required: true, }, // { title: '', process: {}, subprocess: {}, }
  },
  data() {
    return {
      process: {},
      subprocess: null,
      variants: {
        actors: [],
        actions: [],
        events: [],
      },
    }
  },
  computed: {
    isNew() {
      return this.item === true
    },
  },
  created() {
    this.load()
    if (this.isNew) {
      this.$form.init({
        title: '',
      })
      this.process = this.initProcess()
    } else {
      this.process = this.item.process
      this.subprocess = this.item.subprocess
      this.$form.init({
        title: this.item.title,
      })
    }
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.process.variants('task').then((response) => {
        this.variants = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    toggleSubprocess() {
      if (this.subprocess === null) {
        this.subprocess = this.initProcess()
      } else {
        this.subprocess = null
      }
    },
    initProcess() {
      return {
        actors: {
          author: { id: 'author', title: 'Инициатор задачи', values: [], disabled: true, },
          system: { id: 'system', title: 'Автоматически', values: [], disabled: true, },
        },
        actions: {},
      }
    },
    submit() {
      this.$var('load', true)
      const data = {
        process: this.process,
        subprocess: this.subprocess,
        title: this.$form.get('title'),
      }
      const api = this.isNew ? $api.process.create(data) : $api.process.edit(this.item.id, data)
      api.then((result) => {
        this.$emit('submit')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
    remove() {
      this.$var('load', true)
      $api.process.remove(this.item.id).then(() => {
        this.$emit('submit')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.modal-edit-template {
  --n-modal-width: 800px;
  .processes {
    margin: 40px 0;
    &>*:nth-child(2) {
      margin-top: 40px;
    }
  }

  .remove-block {
    float: right;
  }
}
</style>
