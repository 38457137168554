<template>
  <div class="block-task-comments">
    <h3>Комментарии</h3>

    <n-loader :loading="$var('load')" />

    <div v-for="item in comments" :key="item.id" class="comment">
      <div class="author">{{ item.author.fullName }}</div>
      <div class="date">{{ $app.date.format(item.createdAt) }}</div>
      <div v-if="item.users" class="users">
        <span v-for="user in item.users.split(',')" :key="user">@{{ user }}</span>
      </div>
      <div class="content" v-html="$app.secure.clean(item.content)" />
    </div>
    <div v-if="!$n.size(comments) && !$var('load')" class="empty-message">Комментариев нет</div>

    <div class="add-comment">
      <n-form @submit="submit">
        <div>Добавить комментарий</div>
        <n-select title="Отметить пользователей" :data="users" v-bind="$form.input('users', 'select')"
                  option-title="fullName" selected-title="fullName" item-value="id" />
        <n-items>
          <n-textarea v-bind="$form.input('content')" />
          <n-button type="submit">Отправить</n-button>
        </n-items>
      </n-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockTaskComments',
  props: {
    task: { type: Object, required: true, },
  },
  data() {
    return {
      comments: [],
      users: [],
    }
  },
  watch: {
    task() {
      this.load()
      this.loadUsers()
    },
  },
  created() {
    this.$form.init({
      content: '',
      users: [],
    })
    this.$form.rules({
      content: [ 'required', ],
    })
    this.load()
    this.loadUsers()
  },
  methods: {
    load() {
      if (!this.task.id) return

      this.$var('load', true)
      const api = [
        $api.fs.tasks.comments.get(this.task.id).with('author'),
        $api.fs.projects.users.get(this.task.projectId),
      ]
      Promise.all(api).then((response) => {
        this.comments = response[0].data.content
        this.users = response[1].data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadUsers() {
      if (!this.task.id) return

      // this.$var('load', true)
      $api.fs.projects.users.get(this.task.projectId).then((response) => {
        this.users = response.data.content
      }).finally(() => {
        // this.$var('load', false)
      })
    },
    submit() {
      setTimeout(() => {
        if (this.$form.check()) {
          this.$var('load', true)
          const data = {
            content: this.$form.get('content'),
            users: $n.map(this.$form.get('users'), (i) => i.username),
          }
          $api.fs.tasks.comments.create(this.task.id, data).then((response) => {
            this.load()
            this.$form.reset()
          }).catch(() => {
            this.$var('load', false)
          })
        }
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
.block-task-comments {
  position: relative;
  padding: 20px 0;

  .comment {
    padding: 5px 0;
    .author {
      font-size: .9em;
      opacity: .5;
      font-weight: 500;
      display: inline-block;
    }
    .date {
      display: inline-block;
      font-size: .8em;
      opacity: .7;
      margin-left: 10px;
    }
    .content {
    }
    .users {
      span {
        font-size: .8em;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .add-comment {
    margin-top: 50px;
  }
}
</style>
