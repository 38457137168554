<template>
  <div class="page-home">
    <n-loader :loading="$var('load')" />
    <n-divide class="buttons">
      <div>
        <n-button @click="$var('widget', {})">Добавить виджет</n-button>
      </div>
      <div></div>
    </n-divide>

    <div class="widgets">
      <widget-card v-for="w in widgets" :key="w.id" :widget="w" @reload="load" />
    </div>

    <modal-edit-widget v-if="$var('widget')" :widget="$var('widget')" @submit="load" @close="$var('widget', false)" />
  </div>
</template>

<script>
import ModalEditWidget from './ModalEditWidget'
import WidgetCard from './WidgetCard'

export default {
  name: 'Index',
  components: { WidgetCard, ModalEditWidget, },
  data() {
    return {
      widgets: [],
      reportsData: {},
    }
  },
  created() {
    this.$var('load', true)
    this.load()
  },
  methods: {
    load() {
      $api.profile.widgets.get().with('report').then((response) => {
        this.widgets = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-home {
  padding-top: 20px;
  position: relative;

  .buttons {
    padding: 0 20px;
  }
  .widgets {
  }
}
</style>
