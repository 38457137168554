<template>
  <n-card class="page-reports-start" :loading="$var('load')">
    <h3>Создать новый отчет</h3>
    <div class="diagrams">
      <n-link v-for="type in $n.reportType()" :key="type.name" :to="{ name: 'reports.create', params: { type: type.name }, }">
        <n-icon :icon="type.icon" /> <span>{{ type.title }}</span>
      </n-link>
    </div>
  </n-card>
</template>

<script>
export default {
  name: 'PageReportsCreate',
  methods: {
    save(data) {
      this.$var('load', true)
      $api.reports.post(data).then((response) => {
        this.$emit('reload')
        this.$router.push({ name: 'reports.index', params: { id: response.data.content.id, }, })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-reports-start {
  position: relative;
  max-width: 900px;

  .diagrams {
    margin-top: 20px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
    gap: 15px;
    .n-link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 200px;
      border: 1px solid #eee;
      border-radius: 5px;
      transition: .2s background-color;
      text-decoration: none;
      color: inherit;
      &:hover {
        background: #f5f5f5;
      }
      &:last-child {
        margin-bottom: 0;
      }

      .n-icon {
        display: block;
        font-size: 3em;
        opacity: .5;
        margin-bottom: 15px;
      }
      span {

      }
    }
  }
}
</style>
