<template>
  <n-modal class="modal-status" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Статус" :data="statusesData" item-value="name" v-bind="$form.input('status', 'select')" />
        <n-button color="primary" type="submit">Сменить статус</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalStatus',
  props: {
    taskId: { type: Number, required: true, },
    statuses: { type: Array, default: () => [], },
  },
  data() {
    return {
      statusesData: [],
    }
  },
  computed: {
    statusesModels() {
      return $n.map(this.statuses, (v) => $n.taskStatus(v))
    },
  },
  created() {
    this.load()
    this.$form.init({
      status: null,
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.process.statuses.get().key('id').then((response) => {
        this.statusesData = $n.map(this.statuses, (v) => response.data.content[v])
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      this.$var('load', true)
      $api.fs.tasks.status(this.taskId, this.$form.get('status').id).then((response) => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-status {

}
</style>
