<template>
  <c-page-sidebar class="page-tasks-list">
    <template #sidebar>
      <div class="main-button">
        <n-button wide outline icon="plus" color="success" @click="openCreate = true">Создать задачу</n-button>
      </div>
      <div class="block-title">Проекты</div>
      <template v-for="item in projects">
        <n-link :key="item.id" :to="{ name: 'tasks.list', params: { projectId: item.id }, }"
                :class="[ 'sidebar-link', {active: item.id === projectId}]">
          <span class="item">{{ item.title }}</span>
        </n-link>
      </template>
    </template>
    <template #content>
      <n-loader :loading="$var('loadTasks') || $var('loadProjects')" />

      <div class="page-content">
        <div class="gpr" :class="[ { full: !$route.query.task, }, ]">
          <div class="gpr-header">
            <div class="item">
              <div class="title">Название</div>
              <div class="status">Статус</div>
              <div v-if="!$route.query.task" class="date">Даты</div>
              <div v-if="!$route.query.task" class="days">Дней</div>
            </div>
            <div v-if="!$route.query.task" class="months">
              <div v-for="(month, i) in months" :key="i" class="month" :style="{ width: `${month.width}px`, }">{{ month.title }}</div>
            </div>
          </div>
          <n-list :data="tasks" :opened="true">
            <template #item="{ item, }">
              <div class="row" :class="[{root: !item.deep}, { active: $route.query.task*1 === item.id, }]" @click.stop="$router.push(taskLink(item.id))">
                <div class="item">
                  <div class="title" :style="{ paddingLeft: `${20*item.deep}px`, }">
                    <div class="type">
                      [{{ $n.taskType(item.type).title }}]
                    </div>
                    <div class="text">{{ item.title }}</div>
                  </div>
                </div>
                <div class="status">
                  <div :class="[ 'created'+'-text', ]">{{ item.status.title }}</div>
                </div>
                <div v-if="!$route.query.task" class="start">
                  {{ $app.date.format(item.startedAt, 'date') }} - {{ $app.date.format(item.endedAt, 'date') }}
                </div>
                <div v-if="!$route.query.task" class="days">{{ getDiff(item.endedAt, item.startedAt) }}</div>
                <div v-if="!$route.query.task" class="figure" :style="{ width: `${days*k}px`}">
                  <div class="months">
                    <div v-for="(month, i) in months" :key="i" class="month" :style="{ width: `${month.width}px`, }"></div>
                  </div>
                  <div class="progress" @mouseenter="progressHover(item)">
                    <n-progress class="plan" :value="item.done / 100" :style="getRowStyle(item)" />
                    <div class="progress-hover" :style="getRowStyle(item)">
                      <n-loader :loading="$var('loadProgress')" />
                      <template v-if="!$var('loadProgress')">
                        Процент выполнения: {{ item.done }}%
                        <template v-if="$n.size(progressFiles)">
                          <div class="title">Документы</div>
                          <div v-for="file in progressFiles" :key="file.id" class="file">
                            {{ file.title }}.{{ file.ext }} - {{ file.done }}%
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #tools>
              <span style="padding: 0"></span>
            </template>
          </n-list>
          <template v-if="!$n.size(tasks) && !$var('loadingTasks')">
            Структура проекта пуста
          </template>
        </div>
        <div v-if="$route.query.task" class="task-card">
          <modal-task-card @close="$router.push(taskLink())" @reload="loadTasks" />
        </div>
      </div>
      <modal-create-task v-if="openCreate" :project="project" @close="openCreate = false" @reload="loadTasks" />
    </template>
  </c-page-sidebar>
</template>

<script>
import ModalCreateTask from './../create/Index'
import ModalTaskCard from './../card/Index'

export default {
  name: 'PageTasksList',
  components: { ModalTaskCard, ModalCreateTask, },
  data() {
    return {
      projects: [],
      statuses: [],
      tasks: [],
      progressFiles: [],
      k: 3,
      monthTitles: [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
      ],
    }
  },
  computed: {
    openCreate: {
      get() {
        return this.$route.query.new !== undefined
      },
      set(value) {
        this.$router.push({
          name: 'tasks.list',
          params: { projectId: this.projectId, },
          query: {
            new: value ? null : undefined,
          },
        })
      },
    },
    projectId: {
      get() {
        let result = this.$route.params.projectId
        if (result) {
          result *= 1
        }
        return result
      },
      set(value) {
        const route = { ...this.$route, params: { projectId: value, }, }
        if (!this.projectId) {
          this.$router.replace(route)
        } else {
          this.$router.push(route)
        }
      },
    },
    project() {
      return $n.find(this.projects, [ 'id', this.projectId, ]) || {}
    },
    days() {
      return $app.date.now(this.project.createdAt).add(6, 'months').diff($app.date.now(this.project.createdAt), 'days')
    },
    months() {
      if (!this.project.id) {
        return []
      }
      const start = $app.date.now(this.project.createdAt)
      const end = $app.date.now(this.project.createdAt).add(6, 'months')

      const result = [
        { x: 0, width: 0, date: start, title: this.monthTitles[start.month()], },
      ]

      const setMonth = (prev, end) => {
        const nextDate = $app.date.now({ year: prev.date.year(), month: prev.date.month(), day: 1, }).add(1, 'months')
        const width = nextDate.diff(prev.date, 'days') * this.k
        result[result.length - 1].width = width
        const item = { x: prev.x + width, width: width, date: nextDate, title: this.monthTitles[nextDate.month()], }
        result.push(item)

        if (end.diff(nextDate) > 0) {
          setMonth(item, end)
        } else {
          result[result.length - 1].width = 30 * this.k
        }
      }
      setMonth(result[0], end)

      return result
    },
  },
  watch: {
    projectId() {
      this.setDefaultProject()
      this.loadTasks()
    },
  },
  created() {
    this.loadProjects()
    this.loadGrants()
    this.loadTasks()
  },
  methods: {
    progressHover(task) {
      this.$var('loadProgress', true)
      this.progressFiles = []
      $api.fs.tasks.files.get(task.id).then((response) => {
        this.progressFiles = response.data.content
      }).finally(() => {
        this.$var('loadProgress', false)
      })
    },
    taskLink(id = undefined) {
      return { ...this.$route, query: { ...this.$route.query, task: id, }, }
    },
    setDefaultProject() {
      if (!this.projectId && this.projects.length) {
        this.projectId = $n.get(this.projects, '0.id')
      }
    },
    loadProjects() {
      this.$var('loadProjects', true)
      $api.fs.projects.get().view('all').then((response) => {
        this.projects = response.data.content
        this.setDefaultProject()
      }).finally(() => {
        this.$var('loadProjects', false)
      })
    },
    loadGrants() {
      $api.auth.info().then((response) => {
        $app.auth.grants(response.data.content.grants)
      }).finally(() => {
      })
    },
    loadTasks() {
      if (this.projectId) {
        this.$var('loadTasks', true)
        $api.fs.tasks.get().filter('projectId', this.projectId).sort('createdAt').with('status').view('tree').then((response) => {
          this.tasks = response.data.content
        }).finally(() => {
          this.$var('loadTasks', false)
        })
      }
    },
    getDiff(start, end) {
      return $app.date.now(start).diff($app.date.now(end), 'days')
    },
    getRowStyle(item) {
      if (!item.startedAt || !item.endedAt) {
        return {
          width: 0,
        }
      }
      let length = this.getDiff(item.endedAt, item.startedAt)
      let margin = this.getDiff(item.startedAt, this.project.createdAt)

      if (length > this.days) length = this.days
      if (length === 0) length = 1
      if (margin < 0) margin = 0

      return {
        width: `${length*this.k}px`,
        marginLeft: `${margin*this.k}px`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-tasks-list {
  &::v-deep {
    .content {
      padding-bottom: 0 !important;
    }
  }
  .page-content {
    display: flex;
    align-items: stretch;
    width: 100%;

    .task-card {
      width: 100%;
      max-width: 1000px;
    }
  }


  .progress:hover {
    .progress-hover {
      opacity: 1;
      pointer-events: auto;
      display: block;
    }
  }
  .progress-hover {
    opacity: 0;
    display: none;
    pointer-events: none;
    transition: opacity .3s;
    position: absolute;
    padding: 10px;
    background: #fff;
    border: 1px solid var(--border-color);
    z-index: 10;
    width: 300px !important;
    font-size: .8em;
    min-height: 30px;
    .title {
      margin-top: 10px;
      font-weight: bold;
    }
  }

  .gpr {
    overflow-x: auto;
    overflow-y: auto;
    padding-bottom: 50px;
    min-width: 530px;
    height: calc(100vh - 55px - 20px);
    &.full {
      width: 100%;
    }
    &:not(.full) {
      overflow-x: hidden;
    }
    .gpr-header {
      white-space: nowrap;
      &>* { display: inline-block; }
      .item {
        font-weight: bold;
        margin-left: 38px;
        &>* {
          display: inline-block;
          font-size: .8em;
          overflow: hidden;
          padding-left: 10px;
        }
        .title { width: 349px;}
        .status { width: 140px;}
        .date { width: 181px; border-left: 1px solid #eee;  }
        .days { width: 50px; }
      }
      .months {
        .month {
          display: inline-block;
          border-left: 1px solid #eee;
          text-align: center;
          font-size: .8em;
          overflow: hidden;
        }
      }
    }

    .n-list {
      font-size: 1em;
      width: 100%;
      overflow: visible;
      &::v-deep {
        .n-list-item {
          .n-list-group {
            padding-left: 0;
          }
          .n-content {
            margin: 0;
          }
        }
        .n-title {
          border-bottom: 1px solid #eee;
        }
      }
      .row {
        white-space: nowrap;
        display: flex;
        align-items: stretch;
        &.active {
          background: var(--primary-t-10);
          cursor: initial;
        }
        &>* {
          padding: 10px 0;
        }
        &.root {
          .title {
            font-weight: 600;
          }
        }
        &:hover:not(.active) {
          background: #efefef;
          cursor: pointer;
        }
      }

      .item {
        white-space: normal;
        width: 355px;
        border-right: 1px solid #eee;
        position: relative;
        .title {
          padding: 0 40px 0 10px;
          white-space: normal;
          .type {
            line-height: 1;
            font-weight: bold;
            font-size: .6em;
            opacity: .3;
          }
          .text {
            font-size: .9em;
          }
        }
        .show-button {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 2;
          font-size: .8em;
        }
      }
      .status {
        width: 140px;
        border-right: 1px solid #eee;
        &>div {
          font-weight: bold;
          display: inline-block;
          margin: 0 10px;
          //padding: 5px 8px;
          line-height: 1;
          font-size: .7em;
        }
      }
      .days, .start {
        font-size: .8em;
        width: 180px;
        display: inline-flex;
        align-items: center;
        padding-left: 10px;
        //justify-content: center;
        .n-icon {
          margin-left: 5px;
          font-size: .8em;
          opacity: .2;
          cursor: pointer;
        }
      }
      .days { width: 50px; }
      .figure {
        display: flex;
        align-items: center;
        position: relative;
        .months {
          position: absolute;
          top: 0;
          bottom: 0;
          overflow: hidden;
          &>* { display: inline-block; }
          .month {
            border-left: 1px solid #eee;
            height: 100px;
          }
        }
        .plan {
          background-color: #b4cbf5;
          border-radius: var(--border-radius);
        }
        .fact {

        }
      }
    }
  }
}
</style>
