<template>
  <c-page-sidebar class="layout-reports">
    <template #sidebar>
      <n-loader :loading="$var('load')" />

      <n-link class="main-button" :to="{ name: 'reports', }">
        <n-button color="success" wide outline icon="plus">Новый отчет</n-button>
      </n-link>

      <div class="block-title">Мои отчеты</div>
      <div v-if="$n.size(reports)" class="reports">
        <n-link v-for="report in reports" :key="report.id"
                :class="[ 'sidebar-link', { active: report.id === reportId, }, ]" :to="{ name: 'reports.index', params: { id: report.id, }, }">
          <n-icon :icon="$n.reportType(report.type).icon" />{{ report.title }}
        </n-link>
      </div>
      <div v-else-if="!$var('load')" class="empty-message">Отчетов нет</div>

      <div class="block-title">Общие отчеты</div>
      <div v-if="$n.size(reportsShared)" class="reports">
        <n-link v-for="report in reportsShared" :key="report.id"
                :class="[ 'sidebar-link', { active: report.id === reportId, }, ]" :to="{ name: 'reports.index', params: { id: report.id, }, }">
          <n-icon :icon="$n.reportType(report.type).icon" />{{ report.title }}
        </n-link>
      </div>
      <div v-else-if="!$var('load')" class="empty-message">Отчетов нет</div>
    </template>
    <template #content>
      <router-view @reload="load" />
    </template>
  </c-page-sidebar>
</template>

<script>
export default {
  name: 'LayoutReports',
  data() {
    return {
      reports: [],
      reportsShared: [],
    }
  },
  computed: {
    reportId() {
      return this.$route.params.id
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      const apis = [
        $api.reports.get().filter('isShared', 'false').filter('authorId', $app.auth.user().id),
        $api.reports.get().filter('isShared'),
      ]
      Promise.all(apis).then((response) => {
        this.reports = response[0].data.content
        this.reportsShared = response[1].data.content
        // if ($app.router.current().name === 'reports') {
        //   if (this.reports.length || this.reportsShared.length) {
        //     this.$router.push({ name: 'reports.index', params: { id: this.reports[0]?.id || this.reportsShared[0]?.id, }, })
        //   } else {
        //     this.$router.push({ name: 'reports.create', params: { type: 'table', }, })
        //   }
        // }
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-reports {
}
</style>
