import columns from 'miscs/files-table-columns'
import types from 'miscs/files-table-types'
import statuses from 'miscs/files-table-statuses'

function getActiveColumns() {
  let result = localStorage.getItem('files-table-active-columns')
  if (result) {
    result = result.split(',').map((item) => parseInt(item))
  } else {
    result = columns.map((item) => item.id)
  }
  return result
}

export default {
  namespaced: true,
  state: {
    columns: Object.freeze(columns),
    types: Object.freeze(types),
    statuses: Object.freeze(statuses),

    activeColumns: getActiveColumns(),
    dragedItem: null,
  },

  mutations: {
    SET_ACTIVE_COLUMNS(state, payload) {
      localStorage.setItem('files-table-active-columns', payload.join(','))
      state.activeColumns = payload
    },
    SET_DRAGED_ITEM(state, payload) {
      state.dragedItem = payload
    },
  },

  getters: {
    getFilteredColumns: (state) =>
      state.columns.filter((item) => state.activeColumns.includes(item.id)),

    getType: (state) => (type) => {
      if (!type) return state.types.file
      return state.types[type]
    },
  },
}
