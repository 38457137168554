<template>
  <n-modal class="modal-filter" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Фильтр" :data="Object.values(ui[model].filters)" v-bind="$form.input('uiFilter', 'select')" :update:value="selectType" />
        <n-select title="Тип" :data="Object.values(ui._general.methods)" v-bind="$form.input('uiMethod', 'select')" />
        <n-select v-if="$n.size(selectData)" title="Значение" :data="selectData" v-bind="$form.input('value', 'select')" />
        <n-input v-else-if="$form.get('uiMethod.id') !== 'between'" title="Значение" v-bind="$form.input('value')" />
        <template v-else>
          <n-input title="От" v-bind="$form.input('min')" />
          <n-input title="До" v-bind="$form.input('max')" />
        </template>
        <n-button type="submit" color="success" wide>Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
import { getBuilder, } from '../../utils'

export default {
  name: 'ModalFilter',
  props: {
    filter: { type: Object, required: true, }, // { name: '',  value: '', uiFilter: {}, uiMethod: {}, }
    params: { type: Object, required: true, },
    ui: { type: Object, required: true, },
    model: { type: String, required: true, },
    'update:params': { type: Function, default: (params) => {}, },
  },
  data() {
    return {
      selectData: [],
    }
  },
  computed: {
    isNew() {
      return !this.filter.name
    },
  },
  created() {
    if (this.isNew) {
      this.$form.init({
        uiFilter: null,
        uiMethod: null,
        value: '',
        min: '',
        max: '',
      })
    } else {
      const parts = this.filter.value.split(':')
      const values = parts[1].split(',') || []
      this.$form.init({
        uiFilter: this.filter.uiFilter,
        uiMethod: this.filter.uiMethod,
        value: parts[1],
        min: parts[0] === 'between' ? values[0] : '',
        max: parts[0] === 'between' ? values[1] : '',
      })
      if (this.filter.uiFilter.data) {
        this.load(this.filter.uiFilter.data)
      }
    }
    this.$form.rules({
      uiFilter: [ 'required', ],
      uiMethod: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        const builder = getBuilder(this.params)
        const uiFilter = this.$form.get('uiFilter')
        let name = uiFilter.id + ':' + new Date().getTime()
        if (uiFilter.id === this.filter.uiFilter?.id) {
          name = this.filter.name
        }
        let value = ''
        if (this.$form.get('uiMethod.id') === 'between') {
          value = this.$form.get('min') + ',' + this.$form.get('max')
        } else {
          value = this.$form.get('uiFilter.data') ? this.$form.get('value.id') : this.$form.get('value')
        }
        const fullValue = this.$form.get('uiMethod.id') + ':' + value

        builder.filter(name, fullValue)

        if (uiFilter.type === 'count') {
          builder.fields(name)
        }
      
        this.$emit('update:params', builder.params())
        this.$emit('close')
      }
    },
    load(dataKey) {
      this.selectData = []
      if (dataKey) {
        this.$var('load', true)
        $api.reports.select(this.model, dataKey).then((response) => {
          this.selectData = response.data.content
          const v = this.$form.get('value')
          if (v) {
            this.$form.set('value', $n.find(this.selectData, [ 'id', isNaN(v*1) ? v : v*1, ]))
          }
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    selectType(v) {
      this.$form.set('uiFilter', v)
      this.load(v.data)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-filter {
  --n-modal-width: 500px;
}
</style>
