<template>
  <div class="modal-detail-versions">
    <n-table :data="history" :columns="columns" :loading="$var('loading')">
      <template #version="{item}">
        {{ $app.date.format(item.date) }}
      </template>
      <template #size="{item}">
        {{ item.message }}
      </template>
    </n-table>
  </div>
</template>

<script>
export default {
  name: 'ModalDetailHistory',
  props: {
    id: { type: [ Number, String, ], required: true, },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      columns: [
        { name: 'version', title: 'Дата', },
        { name: 'size', title: 'Действие', },
      ],
      model: {},
      history: [],
    }
  },
  watch: {
    id() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('loading', true)
      if (this.$route.params.type === 'folder') {
        setTimeout(() => {
          this.history = [
            { message: 'Директория создана', date: $app.date.now(), },
          ]
          this.$var('loading', false)
        }, 100)
      } else {
        $api.fs.documents.get(this.id).with('versions').then((response) => {
          this.model = response.data.content
          this.history = [
            { message: 'Файл создан', date: this.model.createdAt, },
            ...this.model.versions.map((i) => ({
              message: 'Файл изменен', date: i.createdAt,
            })),
          ]
        }).finally(() => {
          this.$var('loading', false)
        })
      }
    },
    getFileSize(size) {
      const i = Math.floor( Math.log(size) / Math.log(1024) )
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + [ 'B', 'kB', 'MB', 'GB', 'TB', ][i]
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-detail-versions {

}
</style>
