<template>
  <div class="fs-tabs">
    <n-tabs :data="tabsWithIndex" :active="active" @update:active="select">
      <template #tab="{ tab, }">
        <div class="tab">
          <n-icon v-if="tab.name === 'add'" class="add-button" icon="plus" />
          <template v-else>
            <div class="title">{{ tab.title }}</div>
            <div v-if="$n.size(tabs) > 1" class="close-button" @click.stop="close(tab.name)"><n-icon icon="times" /></div>
          </template>
        </div>
      </template>
    </n-tabs>
  </div>
</template>

<script>
export default {
  name: 'FsTabs',
  props: {
    tabs: { type: Array, required: true, },
    active: { type: Number, required: true, },
  },
  computed: {
    tabsWithIndex() {
      const newTabs = $n.map(this.tabs, (item, name) => ({ ...item, name, }))
      newTabs.push({ name: 'add', })

      return newTabs
    },
  },
  methods: {
    select(index) {
      if (index === 'add') {
        const tabs = $n.clone(this.tabs)
        const tab = $n.clone(this.tabs[this.active])
        tabs.push(tab)
        this.$emit('update:tabs', tabs)
        this.$emit('update:active', $n.size(this.tabs))
      } else {
        this.$emit('update:active', index)
      }
    },
    close(index) {
      const tabs = $n.clone(this.tabs)
      tabs.splice(index, 1)
      const active = this.active > 0 ? this.active - 1 : 0
      this.$emit('update:tabs', tabs)
      this.$emit('update:active', active)
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-tabs {
  &::v-deep .n-tabs>.n-tab {
    border: 1px solid #e5e5e5;
    border-bottom: none;
    position: relative;
    padding: 5px 15px 5px 15px;
    display: flex;
    font-size: .9em;
    &.active {
      color: inherit;
      border-color: var(--primary-t-8);
      transition: background-color, .3s;
      background: var(--primary-t-10);
    }
  }
  .tab {
    display: flex;
    align-items: center;

    .close-button {
      margin-left: 7px;
      margin-right: -5px;
      font-size: .7em;
      padding: 3px 7px;
      cursor: pointer;
      &:hover {
        transition: background-color, .3s;
        background: rgba(127, 127, 127, .3);
      }
    }
    .add-button {
      font-size: .8em;
      padding: 5px;
    }
  }
}
</style>
