<template>
  <n-modal class="add-role-modal" :loading="$var('load')" @close="$emit('close')">
    <h3>Добавить роль</h3>
    <n-form @submit="submit">
      <n-items>
        <n-select title="Роль" :data="roles" v-bind="$form.input('role', 'select')" item-value="name" />
        <n-datepicker title="Ограничение по времени" v-bind="$form.input('endedAt',)" />
        <n-button type="submit">Добавить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'AddRoleModal',
  props: {
    modelId: { type: [ Number, String, ], default: undefined, },
    type: { type: String, default: undefined, }, // role or permit
    ownerId: { type: [ Number, String, ], required: true, },
    owner: { type: String, required: true, }, // group or user
  },
  data() {
    return {
      roles: [],
    }
  },
  created() {
    this.load()
    this.$form.init({
      role: null,
      endedAt: null,
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.auth.accesses.models.roles(this.modelId, this.type, this.ownerId, this.owner, true).then((response) => {
        this.roles = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      this.$var('load', true)
      const data = {
        name: this.$form.get('role').name,
        type: 'role',
        modelId: this.modelId,
        modelType: this.modelType,
        groupId: this.owner === 'group' ? this.ownerId : undefined,
        userId: this.owner === 'user' ? this.ownerId : undefined,
        endedAt: this.$form.get('endedAt'),
      }
      $api.auth.accesses.create(data).then(() => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.add-role-modal {

}
</style>
