<template>
  <n-modal class="modal-retrieve-document" :loading="$var('load')" @close="$emit('close')">
    <h3>Вернуть файл</h3>
    <n-form @submit="submit">
      <n-items>
        <div>
          <n-upload title="Файл" v-bind="$form.input('file')" />
        </div>
        <n-button type="submit">Загрузить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalRetrieveDocument',
  props: {
    document: { type: Object, required: true, },
  },
  created() {
    this.$form.init({
      file: null,
    })
    this.$form.rules({
      file: [ 'required', ],
    })
  },
  methods: {
    submit() {
      // if (this.$form.check()) {
      this.$var('load', true)
      const data = {
        file: this.$form.get('file'),
      }
      $api.fs.documents.editFile(this.document.id, data).then(() => {
        this.$emit('close')
        this.$emit('reload')
      }).finally(() => {
        this.$var('load', false)
      })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-retrieve-document {
  .n-items>div {
    width: 100%;
  }
}
</style>
