<template>
  <div class="block-departments">
    <div class="block-table">
      <div class="title-block row">
        <div class="divide">
          <div>Отделы</div>
          <div v-if="departments.length > 1" class="tools">
            <n-checkbox :value="hasSub" @update:value="(v) => $emit('update:hasSub', v)">создать подзадачи для отделов</n-checkbox>
          </div>
        </div>
      </div>

      <div v-for="dep in departments" :key="dep.id" class="row item">
        <div class="content">{{ dep.title }}</div>
        <div class="tools"><n-button icon="minus" round @click="remove(dep.id)" /></div>
      </div>

      <div class="add-button row" @click="$var('add', true)">
        <n-icon icon="plus" /> отдел
      </div>
    </div>

    <modal-add-department v-if="$var('add')" :departments="departments" @submit="add" @close="$var('add', false)" />
  </div>
</template>

<script>
import ModalAddDepartment from './ModalAddDepartment'

export default {
  name: 'BlockDepartments',
  components: { ModalAddDepartment, },
  props: {
    departments: { type: Array, required: true, },
    hasSub: { type: Boolean, required: true, },
    'update:departments': { type: Function, default: (v) => {}, },
    'update:hasSub': { type: Function, default: (v) => {}, },
  },
  methods: {
    add(department) {
      if (!$n.find(this.departments, [ 'id', department.id, ])) {
        this.$emit('update:departments', [
          ...this.departments,
          department,
        ])
      }
    },
    remove(id) {
      this.$emit('update:departments', $n.filter(this.departments, (item) => item.id !== id))
    },
  },
}
</script>

<style lang="scss" scoped>
.block-departments {

}
</style>
