<template>
  <n-form-item class="n-textarea" :title="title">
    <textarea v-if="simple && !text" :value="value" :placeholder="placeholder" @input="(e) => input(e.target.value)" />
    <ckeditor v-else-if="!text" :value="value" :editor="editor" :config="editorConfig" @input="input" />
    <div v-else v-html="value" />
    <span v-if="danger" class="danger">{{ danger }}</span>
  </n-form-item>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import UploadAdapter from './UploadAdapter'

export default {
  name: 'NTextarea',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    title: { type: String, default: '', },
    value: { type: String, default: '', },
    input: {
      type: Function, default: (value) => {
      },
    },
    placeholder: { type: String, default: '', },
    text: { type: Boolean, default: false, },
    simple: { type: Boolean, default: false, },
    danger: { type: [ String, Boolean, ], default: false, },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // toolbar: {
        //   items: [
        //     'heading', '|',
        //     'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
        //     'insertTable', '|',
        //     'imageUpload', 'mediaEmbed', '|',
        //     '-',
        //     // 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
        //     'undo', 'redo',
        //   ],
        //   shouldNotGroupWhenFull: true,
        // },
        // table: {
        //   toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells', ],
        // },
        // extraPlugins: [ UploadAdapter.uploader, ],
        // language: 'ru',
      },
    }
  },
  methods: {
    store() {
      // Some code
    },

  },
}
</script>

<style lang="scss" scoped>
.n-textarea {
  &::v-deep .ck-editor__editable {
    min-height: 120px;
  }
  textarea {
    width: 100%;
    border: solid var(--border-color);
    border-width: 0 0 1px;
    min-height: 70px;
    outline: none;
    padding: 0;
    &::placeholder {
      color: inherit;
      opacity: .3;
    }
    &:focus {
      border-color: var(--primary);
    }
  }
  .danger {
    color: var(--danger);
  }
}
</style>
